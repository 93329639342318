<template>
  <q-card
    v-if="task"
    class="row task-timer task-card-content"
    style="flex-wrap:nowrap"
    :id="'card-task-' + cardIndex"
    :class="
      isMenuOpen ||
      labelMenuFlag ||
      datePickerFlag ||
      inviteTaskMemberFlag ||
      showMenu
        ? 'task-card-content-active'
        : ''
    "
  >
    <div class="side-label relative-position" v-if="task.labels">
      <template v-for="item in task.labels.slice(0, 4)">
        <q-btn
          :key="item.id"
          rounded
          :style="{ 'background-color': item.colorcode, 'z-index': '10' }"
          @click="removeLabel(item, task)"
        >
          <q-tooltip :content-style="{ 'background-color': item.colorcode }">
            {{ item.name }}
          </q-tooltip>
        </q-btn>
      </template>
    </div>
    <div class="group-hover relative-position task-card-body">
      <img
        @click="edit(cardTask, 'taskComment')"
        v-if="coverImage"
        :src="$resizeMedia(coverImage, 400, 400)"
        class="task-cover-image cursor-pointer q-mb-sm"
      />
      <div>
        <!-- ROW 1 -->
        <div class="row z-index-1 items-baseline q-pb-sm">
          <q-badge
            v-if="hasNotifications.length"
            color="transparent"
            floating
            transparent
            class="q-mt-xs q-mr-xs"
          >
            <q-icon
              size="14px"
              :name="$icons.matFiberManualRecord"
              color="pink"
            />
          </q-badge>
          <!-- Assigned Task Members -->
          <!-- Remaining Title -->
          <div class="flex-1 relative-position row flex-no-wrap">
            <div class="row items-center absolute task-card-members">
              <div
                class="cursor-pointer q-mr-xs"
                @click="removeTaskBookmark(cardTask)"
              >
                <q-icon
                  v-if="cardTask.isbookmarked"
                  size="xs"
                  color="pink"
                  :name="$icons.matBookmark"
                >
                  <q-tooltip>
                    Remove from Saved items
                  </q-tooltip>
                </q-icon>
              </div>
              <div v-if="first2TaskMembers">
                <q-avatar
                  v-for="member in first2TaskMembers"
                  :key="`ontask-userseq-${member.id}`"
                  size="22px"
                  class="q-mr-xs task-card-member"
                >
                  <img
                    :src="member.pic"
                    :alt="member.first"
                    v-if="member.pic"
                  />
                  <!-- <span v-else>
                    {{ member.first | firstChar }}{{ member.last | firstChar }}
                  </span> -->
                  <avatar :customStyle="{'font-size':'12px'}" v-else :size="22" :username="member.first+' '+member.last"></avatar>
                  <q-tooltip>
                    {{ member.first | capitalize }}
                    {{ member.last | capitalize }}
                  </q-tooltip>
                </q-avatar>
                <q-avatar
                  class="inborader q-mr-xs"
                  size="22px"
                  v-if="taskMembers.length > 2"
                >
                  <span
                    class="text-avatar-style"
                    style="font-size:10px"
                    @click.stop="showMenu = !showMenu"
                    >+{{ taskMembers.length - first2TaskMembers.length }}</span
                  >
                </q-avatar>
                <q-menu
                  content-class="overflow-auto assign-member-menu"
                  auto-close
                  v-model="showMenu"
                >
                  <div class="sticky-header">
                    <div
                      class="close-btn cursor-pointer"
                      @click="showMenu = false"
                    >
                      <q-icon :name="$icons.matClose" class="close-icon" />
                    </div>
                    <div class="assign-to q-pt-sm">
                      Assigned to
                    </div>
                  </div>
                  <q-list class="q-py-sm member-list">
                    <q-item
                      class="q-px-none q-py-xs member-item items-center"
                      v-for="member in taskMembers"
                      v-bind:key="`member-list-${member.id}`"
                    >
                      <q-item-section class="member-avatar" avatar>
                        <q-avatar
                          v-if="member.pic"
                          @click="showUserInfo(member)"
                          size="32px"
                        >
                          <img :src="member.pic" :alt="member.first" />
                        </q-avatar>
                        <!-- <q-avatar
                          size="32px"
                          color="grey-4"
                          text-color="grey-10"
                          @click="showUserInfo(member)"
                          v-else
                        >
                          {{ member.first | firstChar
                          }}{{ member.last | firstChar }}
                        </q-avatar> -->
                        <avatar v-else :size="32" @click="showUserInfo(member)" :customStyle="{'font-size':'14px', 'font-weight':'600'}" :username="member.first+' '+member.last"></avatar>
                      </q-item-section>
                      <q-item-section
                        class="member-name"
                        @click="showUserInfo(member)"
                      >
                        {{ member.first | capitalize }}
                      </q-item-section>
                      <q-item-section avatar>
                        <q-btn
                          flat
                          round
                          dense
                          color="grey-7"
                          :icon="$icons.matClose"
                          size="sm"
                          @click="confirmBoxHandler(true, member.id)"
                          v-if="
                            isVisible([1, 2]) ||
                              isWorkspaceOwner ||
                              task.owner.id === user.id
                          "
                        />
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </div>
            </div>
            <div
              @click="edit(cardTask, 'taskComment')"
              class="flex-1 cursor-pointer task-card-title "
            >
            <q-menu
                  auto-close
                  content-class="menu-hover overflow-auto q-pa-sm"
                  v-model="isMenuOpen"
                  context-menu
                >
                  <q-list>
                    <q-item
                      @click="
                        $copyTextToClipboard(copyTaskLink);
                        isTaskCopy = true;
                      "
                      clickable
                    >
                      <q-item-section>Copy task link</q-item-section>
                    </q-item>
                    <!-- <q-item @click="edit(cardTask)" clickable>
                        <q-item-section>Edit task</q-item-section>
                      </q-item> -->
                    <q-item
                      clickable
                      v-if="isVisible([1, 2, 3]) && ((task.owner.id === user.id && task.is_lock) || !task.is_lock)"
                      @click="moveTask.flag = true"
                    >
                      <q-item-section>Move task</q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      v-if="isVisible([1, 2, 3])"
                      @click="checkDuplicateTaskAccess"
                    >
                      <q-item-section>Duplicate task</q-item-section>
                    </q-item>
                    <q-separator
                      v-if="isVisible([1, 2, 3])"
                      class="q-my-sm"
                      style="background:#E3E8ED;"
                    />
                    <q-item
                      @click="changeCompleteStatus"
                      v-if="place === 'board' && isVisible([1, 2, 3])  && ((task.owner.id === user.id && task.is_lock) || !task.is_lock)"
                      clickable
                    >
                      <q-item-section v-if="!cardTask.completed"
                        >Complete task</q-item-section
                      >
                      <q-item-section v-else>Mark as incomplete</q-item-section>
                    </q-item>
                    <q-item @click="openTimelogsHendler" clickable v-if="isVisible([1, 2, 3])">
                      <q-item-section
                        v-if="
                          taskdata &&
                            taskdata.timelogs &&
                            taskdata.timelogs.length
                        "
                        >View time report</q-item-section
                      >
                      <q-item-section v-else>Add time report</q-item-section>
                    </q-item>
                    <!-- <q-item
                        v-if="
                          taskdata &&
                            taskdata.timelogs &&
                            taskdata.timelogs.length
                        "
                        @click="exportTimeLogCsv(taskdata.id)"
                        clickable
                      >
                        <q-item-section>Export Time Report</q-item-section>
                      </q-item> -->
                    <q-item
                      @click="changeTaskLock(true)"
                      clickable
                      v-if="task.owner.id === user.id && !taskdata.is_lock"
                    >
                      <q-item-section>Lock task</q-item-section>
                    </q-item>
                    <q-item
                      @click="changeTaskLock(false)"
                      clickable
                      v-if="task.owner.id === user.id && taskdata.is_lock"
                    >
                      <q-item-section>Unlock task</q-item-section>
                    </q-item>
                    <q-item
                      @click="changeTaskVisible(true)"
                      clickable
                      v-if="task.owner.id === user.id && !taskdata.is_invisible"
                    >
                      <q-item-section>Make task invisible</q-item-section>
                    </q-item>
                    <q-item
                      @click="changeTaskVisible(false)"
                      clickable
                      v-if="task.owner.id === user.id && taskdata.is_invisible"
                    >
                      <q-item-section>Make task visible</q-item-section>
                    </q-item>
                    <q-item
                      class="text-red"
                      @click="$emit('delete', cardTask)"
                      clickable
                      v-if="isVisible([1, 2, 3]) && ((task.owner.id === user.id && task.is_lock) || !task.is_lock)"
                    >
                      <q-item-section>Delete task</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              <div class="task-ticket-number" style="display: flex;align-items: center;line-height: 16px;">
                T{{ workspace.title.substring(0, 2).toUpperCase() }}-{{
                  task.id
                }}
                <q-btn
                  flat
                  dense
                  size="8px"
                  round
                  padding="0"
                  style="color:#66AFFF;padding: 2px;background-color: #F2F8FF;"
                  :icon="$icons.matLock"
                  class="q-ml-xs"
                  v-if="task.is_lock"
                >
                  <q-tooltip>
                    Task locked by {{ taskdata.owner.first | capitalize }} {{ taskdata.owner.last | capitalize }}
                  </q-tooltip>
                </q-btn>
                <q-btn
                  flat
                  dense
                  size="8px"
                  round
                  padding="0"
                  style="color:#66AFFF;padding: 2px;background-color: #F2F8FF;"
                  :icon="$icons.matVisibilityOff"
                  v-if="task.is_invisible"
                  class="q-ml-xs"
                >
                  <q-tooltip>
                    Task invisible by {{ taskdata.owner.first | capitalize }} {{ taskdata.owner.last | capitalize }}
                  </q-tooltip>
                </q-btn>
              </div>
              <div class="task-ticket-number">
                
              </div>
              <div class="dont-break-out" style="hyphens:none">
                {{ cardTask.title }}
                <q-tooltip>
                  Created by {{ taskdata.owner.first | capitalize }}
                  {{ taskdata.owner.last | capitalize }}
                </q-tooltip>
              </div>
            </div>
            <div class="task-menu-option thread-icons q-px-xs group-hover-item">
              <q-btn
                flat
                :icon="$icons.matLink"
                size="9px"
                rounded
                dense
                @click="
                  $copyTextToClipboard(copyTaskLink);
                  isTaskCopy = true;
                "
                class="thread-icon"
              >
                <q-tooltip>
                  Copy task Link
                </q-tooltip>
              </q-btn>
              <q-btn
                v-if="!cardTask.isbookmarked"
                @click="addTaskBookmark(cardTask)"
                size="9px"
                class=" thread-icon"
                :icon="$icons.matBookmarkBorder"
                dense
                rounded
                flat
              >
                <q-tooltip>
                  Add to Saved items
                </q-tooltip>
              </q-btn>
              <invite-task-member
                v-if="isVisible([1, 2]) && ((task.owner.id === user.id && task.is_lock) || !task.is_lock)"
                :task="taskdata"
                :place="place"
                :className="['z-index-2']"
                :boardId="boardId"
                :workspace="workspace"
                :workspaceMembers="workspaceMembers"
                :currentCompany="currentCompany"
                @inviteTaskMemberChanged="
                  (data) => (inviteTaskMemberFlag = data)
                "
                size="9px"
              ></invite-task-member>
            </div>
          </div>
        </div>
        <!-- ROW 2 -->
        <div class="row items-center justify-between">
          <div
            v-if="!activeTimelog && !totalWorkTime"
            class="q-mr-sm cursor-pointer card-options"
          >
            <q-icon
              size="20px"
              style="color:#B1BCC8;"
              :name="$icons.matPlayCircleFilled"
              @click="showTooltip = false"
            />
            <q-tooltip v-if="showTooltip">
              Track time
            </q-tooltip>
            <q-menu
              max-height="auto"
              content-class="overflow-auto"
              auto-close
              v-model="trackTimeMenu"
              @before-hide="showTooltip = true"
            >
              <q-list>
                <q-item @click="checkTimelogAccess" clickable>
                  <q-item-section>Track time</q-item-section>
                </q-item>
                <q-item @click="openTimelogsHendler" clickable>
                  <q-item-section>Add time</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </div>
          <div v-if="totalWorkTime && !activeTimelog">
            <div class="relative-position">
              <div
                class="total-time row flex-no-wrap cursor-pointer"
              >
                <q-tooltip v-if="showTooltip">
                  Track time
                </q-tooltip>
                <q-icon :name="$icons.mdiPlay" size="20px" @click="showTooltip = false" />
                <q-menu
                  max-height="auto"
                  content-class="overflow-auto"
                  auto-close
                  v-model="trackTimeMenu"
                  @before-hide="showTooltip = true"
                >
                  <q-list>
                    <q-item @click="checkTimelogAccess" clickable>
                      <q-item-section>Track time</q-item-section>
                    </q-item>
                    <q-item @click="openTimelogsHendler" clickable>
                      <q-item-section>Add time</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
                <div
                  class="q-ml-sm time-text row items-center"
                  style="margin-top:1px;"
                  v-if="totalWorkTime"
                >
                  {{ totalWorkTime }}
                </div>
                <div class="time-log-edit-delete row items-center">
                  <div
                    class="cursor-pointer edit-icon row items-center"
                    @click.stop="openTimelogsHendler"
                  >
                    <q-icon
                      size="16px"
                      class="q-ml-xs"
                      :name="$icons.matEdit"
                    />
                    <q-tooltip>Edit Time Report</q-tooltip>
                  </div>
                  <div
                    class="delete-icon row items-center"
                    clickable
                    @click.stop="deleteTimer = true"
                  >
                    <q-icon
                      size="16px"
                      class="q-mr-xs "
                      :name="$icons.matDelete"
                    />
                    <q-tooltip>Delete Time Report</q-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <timer
            :startTime="activeTimelog.start_date"
            v-if="activeTimelog && user.id === activeTimelog.owner.id"
            :stopTimer="stopTimerHandler"
            :cardTask="cardTask"
            :deleteTaskTimeLogs="deleteTaskTimeLogs"
            @openTimelogDialog="openTimelogsHendler"
            :hideEditButton="true"
          />
          <div>
            <q-btn
              flat
              dense
              size="10px"
              style="color:#B1BCC8;"
              padding="0"
              :icon="$icons.fasCheck"
              @click="edit(cardTask, 'openSubtask')"
              :class="
                !cardTask.subtasks.length
                  ? 'card-options'
                  : 'card-options-active'
              "
            >
              <span
                v-if="cardTask.subtasks.length"
                class="an-13 medium-text q-ml-xs"
                >{{ completedSubtasks }}/{{ cardTask.subtasks.length }}</span
              >
              <q-tooltip>
                Subtasks
              </q-tooltip>
            </q-btn>
          </div>
          <div>
            <task-date-picker
              :taskdates="{
                from: cardTask.start_date,
                to: cardTask.end_date,
              }"
              :className="['z-index-2']"
              @save="dateUpdateHandler"
              @datePickerChanged="(data) => (datePickerFlag = data)"
              ref="taskDatePicker"
              v-if="(task.owner.id === user.id && task.is_lock) || !task.is_lock"
            />
          </div>
          <div>
            <task-label-menu
              :task="taskdata"
              :labels="labels"
              :boardId="boardId"
              :place="place"
              @labelMenuChanged="(data) => (labelMenuFlag = data)"
              ref="labelMenu"
              v-if="(task.owner.id === user.id && task.is_lock) || !task.is_lock"
            />
          </div>
        </div>
        <!-- Custom Fields -->
        <div>
          <div class="row items-center q-mt-xs">
            <template v-for="customField in customFields">
              <CF-task-card
                :key="customField.id"
                :customField="customField"
                :task="taskdata"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
    <profile-dialog
      v-model="profileDialog.show"
      v-if="profileDialog.show"
      :recepient="userProfileDialog"
      :user="profileDialog.user"
      :currentWorkspaceId="workspace.id"
      @close="clearUserInfoDialog"
    ></profile-dialog>
    <confirm-dialog
      v-if="confirmRemoveMember.flag"
      v-model="confirmRemoveMember.flag"
      title="Remove Member?"
      question="Are you sure you want to remove this member?"
      cancleText="Cancel"
      successText="Remove"
      :cancleMethod="() => confirmBoxHandler(false)"
      :successMethod="deleteTaskMember"
      :loading="false"
    />
    <confirm-dialog
      v-if="deleteTimer"
      v-model="deleteTimer"
      title="Delete time entry?"
      question="Are you sure you want to delete this time entry?"
      cancleText="No"
      successText="Yes"
      :cancleMethod="cancleDeleteTimingDialog"
      :successMethod="deleteTaskTimeLogs"
      :loading="false"
    />
    <duplicate-task-dialog
      v-if="cloneTask.flag"
      v-model="cloneTask.flag"
      :task="taskdata"
      :closeDialog="closeCloneTaskDialog"
    />
    <move-task-dialog
      v-if="moveTask.flag"
      v-model="moveTask.flag"
      :task="taskdata"
      :board="board"
      :closeDialog="closeMoveTaskDialog"
    />
    <stop-timer-confirmation-dialog
      v-if="stopTimerConfirmation.flag"
      v-model="stopTimerConfirmation.flag"
      :timelogId="stopTimerConfirmation.id"
      :stopTimer="stopTimer"
      @closeDialog="
        stopTimerConfirmation.flag = false;
        stopTimerConfirmation.id = null;
      "
    />
    <start-new-timer-dialog
      v-if="startNewTimer.flag"
      v-model="startNewTimer.flag"
      :activeTaskId="startNewTimer.activeTaskId"
      :createTimer="createTimer"
      :currentCompany="currentCompany"
      @closeDialog="
        startNewTimer.flag = false;
        startNewTimer.activeTaskId = null;
      "
    />
  </q-card>
</template>
<script>
import { mapGetters } from "vuex";
import get from "lodash/get";
import { fromUnixTime } from "date-fns";
import { getUnixTime } from "date-fns";
const soundFile = require("@/assets/completeTaskSound.wav");
import { exportTimeLogs } from "@/services/auth";

import TaskDatePicker from "@/views/BoardView/TaskDatePicker";
import InviteTaskMember from "@/components/InviteTaskMember";
import TaskLabelMenu from "@/components/TaskLabelMenu";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import Timer from "@/components/Timer";
import StopTimerConfirmationDialog from "@/components/Dialogs/StopTimerConfirmationDialog.vue";
import StartNewTimerDialog from "@/components/Dialogs/StartNewTimerDialog.vue";
import ProfileDialog from "@/components/ProfileDialog";
import DuplicateTaskDialog from "@/components/Dialogs/DuplicateTaskDialog.vue";
import MoveTaskDialog from "@/components/Dialogs/MoveTaskDialog.vue";
import CFTaskCard from "@/views/BoardView/CustomField/CFTaskCard.vue";
import UserScope from "@/mixins/UserScope";
import Avatar from 'vue-avatar';

import {
  UpdateTaskMutation,
  AddTaskBookmarkMutation,
  RemoveTaskBookmarkMutation,
  DeleteTaskMemberMutation,
  DetachTaskLabelMutation,
  CreateTaskTimeLogMutation,
  UpdateTaskTimeLogMutation,
  DeleteTaskTimeLogsMutation,
  UpdateCompanySettingsMutation,
} from "@/gql";
import mixpanel from "@/mixpanel";

export default {
  name: "CardTask",
  mixins: [UserScope],
  props: [
    "taskdata",
    "labels",
    "board",
    "boardId",
    "workspace",
    "workspaceMembers",
    "place",
    "editTaskDialogHandler",
    "onUpdateTaskMembersHandler",
    "cardIndex",
    "boardIndex",
  ],
  components: {
    InviteTaskMember,
    TaskDatePicker,
    TaskLabelMenu,
    ConfirmDialog,
    DuplicateTaskDialog,
    MoveTaskDialog,
    Timer,
    ProfileDialog,
    StopTimerConfirmationDialog,
    StartNewTimerDialog,
    CFTaskCard,
    Avatar,
  },
  api: {
    customFields: {
      cacheKey() {
        return `CustomFieldQuery:${this.workspace.id}`;
      },
      defaultValue: null,
    },
  },
  mounted() {
    this.sound = new Audio(soundFile);
    this.task = this.taskdata;
    if (this.taskdata.activetimelog) {
      const companyEntity = this.$api.getEntity(
        "company",
        this.currentCompany.companyId
      );
      companyEntity.start_task_id = this.task.id;
      companyEntity.start_time_log_id = this.taskdata.activetimelog.id;
    }
  },
  data() {
    return {
      sound: null,
      task: null,
      showMenu: false,
      cloneTask: {
        flag: false,
      },
      moveTask: {
        flag: false,
      },
      confirmRemoveMember: {
        flag: false,
        id: null,
      },
      stopTimerConfirmation: {
        flag: false,
        id: null,
      },
      startNewTimer: {
        flag: false,
        activeTaskId: null,
      },
      profileDialog: {
        show: false,
        user: null,
      },
      deleteTimer: false,
      isTaskCopy: false,
      isMenuOpen: false,
      labelMenuFlag: false,
      datePickerFlag: false,
      inviteTaskMemberFlag: false,
      showTrackTime: false,
      planeDescription: null,
      trackTimeMenu: false,
      showTooltip: true,
    };
  },
  methods: {
    checkTimelogAccess() {
      let eligible = this.$checkEligiblity("time_tracking");
      if (eligible) {
        this.createTimer();
      }
    },
    checkDuplicateTaskAccess() {
      let eligible = this.$checkEligiblity("copy_duplicate_tasks");
      if (eligible) {
        this.openDuplicateTask();
      }
    },
    cancleDeleteTimingDialog() {
      this.deleteTimer = false;
    },
    async deleteTaskTimeLogs() {
      const variables = {
        workspace_id: this.workspace.id,
        task_id: this.taskdata.id,
        user_id: this.user.id,
      };
      const taskEntity = this.$api.getEntity("task", this.taskdata.id);
      this.deleteTimer = false;

      taskEntity.timelogs = [];
      await this.$api.mutate({
        mutation: DeleteTaskTimeLogsMutation,
        variables,
      });
    },
    showUserInfo(user) {
      this.profileDialog.show = true;
      this.profileDialog.user = user;
    },
    clearUserInfoDialog() {
      this.profileDialog.show = false;
      this.profileDialog.user = null;
    },
    closeCloneTaskDialog() {
      this.cloneTask.flag = false;
    },
    closeMoveTaskDialog() {
      this.moveTask.flag = false;
    },
    closePlanExpiredDialog() {
      this.showTrackTime = false;
    },
    openDuplicateTask() {
      if (
        this.currentCompany.status &&
        this.currentCompany.status.subscriptionOver
      ) {
        this.planeDescription =
          "Duplicate tasks across workspaces including task-details and sub-tasks.";
        this.showTrackTime = true;
      } else {
        this.cloneTask.flag = true;
      }
    },
    openTimelogsHendler() {
      if (
        this.currentCompany.status &&
        this.currentCompany.status.subscriptionOver
      ) {
        this.planeDescription =
          "Track your time and your teams. Export time reports and more.";
        this.showTrackTime = true;
      } else {
        this.$eventBus.$emit("openTimelogs", true, this.taskdata.timelogs, true, this.taskdata);
      }
    },
    async createTimer() {
      if (
        this.currentCompany.status &&
        this.currentCompany.status.subscriptionOver
      ) {
        this.planeDescription =
          "Track your time and your teams. Export time reports and more.";
        this.showTrackTime = true;
      } else {
        const companyEntity = this.$api.getEntity(
          "company",
          this.currentCompany.companyId
        );
        if (!companyEntity.start_task_id && !companyEntity.start_time_log_id) {
          const variables = {
            start_date: getUnixTime(new Date()),
            end_date: getUnixTime(new Date()),
            task_id: this.task.id,
            workspace_id: this.workspace.id,
            is_start: true,
          };
          let newTaskTimeLog = await this.$api.mutate({
            mutation: CreateTaskTimeLogMutation,
            variables,
          });

          const companyEntity = this.$api.getEntity(
            "company",
            this.currentCompany.companyId
          );

          const taskEntity = this.$api.getEntity("task", this.task.id);
          companyEntity.start_task_id = this.task.id;
          companyEntity.start_time_log_id =
            newTaskTimeLog.data.createTaskTimeLog.id;
          taskEntity.timelogs.push(newTaskTimeLog.data.createTaskTimeLog);
          this.workspace.timelogs.push(newTaskTimeLog.data.createTaskTimeLog);
          taskEntity.activetimelog = newTaskTimeLog.data.createTaskTimeLog;
          const companyVariables = {
            name: companyEntity.name,
            start_task_id: this.task.id,
            start_time_log_id: newTaskTimeLog.data.createTaskTimeLog.id,
          };
          await this.$api.mutate({
            mutation: UpdateCompanySettingsMutation,
            variables: companyVariables,
          });
          this.$mixpanelEvent("track-time", companyVariables);
        } else {
          this.startNewTimer.flag = true;
          this.startNewTimer.activeTaskId = companyEntity.start_task_id;
        }
      }
    },
    stopTimerHandler(data) {
      this.stopTimerConfirmation.flag = true;
      this.stopTimerConfirmation.id = data.id;
    },
    async stopTimer(timelogId) {
      const variables = {
        end_date: getUnixTime(new Date()),
        id: timelogId,
        is_start: false,
      };
      const newTaskTimeLog = await this.$api.mutate({
        mutation: UpdateTaskTimeLogMutation,
        variables,
      });
      const taskEntity = this.$api.getEntity("task", this.task.id);
      taskEntity.activetimelog = null;

      let objIndex = taskEntity.timelogs.findIndex(
        (obj) => obj.id == timelogId
      );
      taskEntity.timelogs[objIndex] = newTaskTimeLog.data.updateTaskTimeLog;

      const companyEntity = this.$api.getEntity(
        "company",
        this.currentCompany.companyId
      );
      companyEntity.start_task_id = null;
      companyEntity.start_time_log_id = null;
      const companyVariables = {
        name: companyEntity.name,
        start_task_id: null,
        start_time_log_id: null,
      };
      await this.$api.mutate({
        mutation: UpdateCompanySettingsMutation,
        variables: companyVariables,
      });
    },
    async removeLabel(label, task) {
      const variables = {
        task_id: task.id,
        label_id: label.id,
      };
      await this.$api.mutate({
        mutation: DetachTaskLabelMutation,
        variables,
      });
      mixpanel.track("Task Label Remove");
    },
    async addTaskBookmark() {
      if (
        this.currentCompany.status &&
        this.currentCompany.status.subscriptionOver
      ) {
        this.planeDescription =
          "Save tasks, messages and files across workspaces in your private saved items tab.";
        this.showTrackTime = true;
      } else {
        try {
          const variables = {
            task_id: this.task.id,
          };

          this.task.isbookmarked = !this.task.isbookmarked;
          const workspaceEntity = this.$api.getEntity(
            "workspace",
            this.workspace.id
          );
          workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
          await this.$api.mutate({
            mutation: AddTaskBookmarkMutation,
            variables,
            skipUpdates: true,
          });
          this.$mixpanelEvent("save-task", {});
          mixpanel.track("Task Bookmark Add");
        } catch (error) {
          const workspaceEntity = this.$api.getEntity(
            "workspace",
            this.workspace.id
          );
          workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
          throw new Error(error);
        }
      }
    },
    async removeTaskBookmark() {
      try {
        const variables = {
          task_id: this.task.id,
        };

        this.task.isbookmarked = !this.task.isbookmarked;
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.workspace.id
        );
        workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
        await this.$api.mutate({
          mutation: RemoveTaskBookmarkMutation,
          variables,
          skipUpdates: true,
        });
        mixpanel.track("Task Bookmark Remove");
      } catch (error) {
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.workspace.id
        );
        workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
        throw new Error(error);
      }
    },
    dateUpdateHandler(dates) {
      this.updateTask({
        id: this.taskdata.id,
        start_date: dates ? dates.from : null,
        end_date: dates ? dates.to : null,
      });
    },
    async changeCompleteStatus() {
      this.sound.currentTime = 0;
      this.sound.play();
      const variables = {
        id: this.task.id,
        completed: !this.task.completed,
      };
      const boardQuery = this.$api.getQuery(`BoardQuery:${this.board.id}`);
      boardQuery.data.board.tasks = boardQuery.data.board.tasks.map((task) => {
        if (task.id === this.task.id) {
          task.completed = task.completed ? false : true;
        }
        return task;
      });
      const task = this.$api.getEntity("task", this.task.id);
      task.completed = !task.completed;
      try {
        await this.$api.mutate({
          mutation: UpdateTaskMutation,
          variables,
        });
        this.$mixpanelEvent("task-completed", {});
        mixpanel.track("task-completed");
      } catch (error) {
        throw new Error(error);
      }
    },
    async changeTaskLock(flag) {
      const variables = {
        id: this.task.id,
        is_lock: flag,
      };
      const boardQuery = this.$api.getQuery(`BoardQuery:${this.board.id}`);
      boardQuery.data.board.tasks = boardQuery.data.board.tasks.map((task) => {
        if (task.id === this.task.id) {
          task.is_lock = flag;
        }
        return task;
      });
      const task = this.$api.getEntity("task", this.task.id);
      task.is_lock = flag;
      try {
        await this.$api.mutate({
          mutation: UpdateTaskMutation,
          variables,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async changeTaskVisible(flag) {
      const variables = {
        id: this.task.id,
        is_invisible: flag,
      };
      const boardQuery = this.$api.getQuery(`BoardQuery:${this.board.id}`);
      boardQuery.data.board.tasks = boardQuery.data.board.tasks.map((task) => {
        if (task.id === this.task.id) {
          task.is_invisible = flag;
        }
        return task;
      });
      const task = this.$api.getEntity("task", this.task.id);
      task.is_invisible = flag;
      if(!flag && this.task.card_id) { 
        this.$api.updateEntity("card", this.task.card_id, {is_invisible:false});
      }
      try {
        await this.$api.mutate({
          mutation: UpdateTaskMutation,
          variables,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateTask(args) {
      const variables = {
        ...args,
      };
      await this.$api.mutate({
        mutation: UpdateTaskMutation,
        variables,
      });
      this.task.start_date = args.start_date;
      this.task.end_date = args.end_date;
      mixpanel.track("Task Update");
    },
    edit(task, isOpen = null) {
      const obj = {
        task,
        isOpen,
      };
      this.editTaskDialogHandler(obj);
    },
    confirmBoxHandler(flag = true, id = null) {
      this.confirmRemoveMember = {
        flag,
        id,
      };
    },
    async deleteTaskMember() {
      const variables = {
        task_id: this.task.id,
        user_id: this.confirmRemoveMember.id,
      };

      this.task.members = this.task.members.filter(
        (member) => member.id !== this.confirmRemoveMember.id
      );

      this.confirmRemoveMember.flag = false;

      const response = await this.$api.mutate({
        mutation: DeleteTaskMemberMutation,
        variables,
      });
      mixpanel.track("Task Member Remove");
      if (response) {
        this.onUpdateTaskMembersHandler({
          id: this.task.id,
        });
      }
    },
    async exportTimeLogCsv(taskId) {
      const res = await exportTimeLogs(
        this.workspace.id,
        this.currentCompany.accessToken,
        taskId
      );
      if (res && res.status_code === 200) {
        window.location = res.data;
      }
    },
    timeCaclulate(startDate, endDate) {
      let dateFuture = fromUnixTime(endDate);
      let dateNow = fromUnixTime(startDate);

      return Math.floor((dateFuture - dateNow) / 1000);
    },
    convertIntoTime(seconds) {
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);

      hours = hours - days * 24;
      minutes = minutes - days * 24 * 60 - hours * 60;
      //seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

      return days * 24 + hours + "h " + minutes + "m";
    },
  },
  computed: {
    isWorkspaceOwner() {
      if (this.workspace && this.user) {
        return this.user.id === this.workspace.owner.id;
      }
      return false;
    },
    totalWorkTime() {
      let taskTotalTime;
      if (this.cardTask.timelogs?.length) {
        let timeInSecond = 0;
        this.cardTask.timelogs.forEach((timelog) => {
          if (this.user && timelog.owner && this.user.id === timelog.owner.id) {
            timeInSecond += this.timeCaclulate(
              timelog.start_date,
              timelog.end_date
            );
          }
        });
        if (timeInSecond) {
          taskTotalTime = this.convertIntoTime(timeInSecond);
        }
        return taskTotalTime;
      }
      return null;
    },
    activeTimelog() {
      return this.cardTask.activetimelog;
    },
    userProfileDialog() {
      return this.profileDialog.user;
    },
    coverImage() {
      const media = this.$api.getEntity("media", this.cardTask.cover_image);
      if (media) {
        return media.file;
      }
      return null;
    },
    cardTask() {
      const task = this.$api.getEntity("task", this.task.id);
      return task;
    },
    taskMembers() {
      return this.taskdata.members;
    },
    first2TaskMembers() {
      return this.taskMembers !== undefined && this.taskMembers.length > 2
        ? this.taskMembers.slice(0, 2)
        : this.taskMembers;
    },
    completedSubtasks() {
      if (this.taskdata) {
        return this.taskdata.subtasks.filter((todo) => todo.completed).length;
      } else {
        return 0;
      }
    },
    hasNotifications() {
      const query = this.$api.getQuery("NotificationsQuery");
      if (query.data && this.task) {
        const notifications = query.data.notifications
          .filter((n) => !n.read)
          .filter(
            (n) =>
              (n.action.type === "task-comment-created" &&
                n.action.object &&
                n.action.object.task_id === this.task.id) ||
              (n.action.type === "subtask-member-attached" &&
                n.action.object &&
                n.action.object.task_id === this.task.id) ||
              (n.action.type === "task-member-attached" &&
                n.action.object &&
                n.action.object_id === this.task.id) ||
              (n.action.type === "task-comment-reaction-added" &&
                n.action.object &&
                n.action.object.task_id === this.task.id)
          );
        return notifications;
      }
      return [];
    },
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
	copyTaskLink() {
		const taskLink = `${process.env.VUE_APP_APPBASE_URL}/companies/${this.activeCompany}/workspaces/${this.workspace.id}/board?task=${this.taskdata.id}&workspace=${this.workspace.id}&workspaceName=${this.workspace.title}`;
		return taskLink;
	},
  },
};
</script>
