<template>
  <div>
    <div
      v-if="taskdates.to"
      @click="menu = !menu"
      :class="[
        'task-due-date cursor-pointer',
        isEndDateDue,
      ]"
      class="custom-task-tag relative-position"
    >
      <div class="close" @click.stop="save(null)">
        <q-icon :name="$icons.matClose" />
      </div>
      {{ taskdates.to | dateMonthDateDefault }}
      <q-tooltip>
        Due Date
      </q-tooltip>
    </div>
    <div
      v-else-if="taskdates.from"
      @click="menu = !menu"
      :class="[
        'task-due-date cursor-pointer',
        isStartDateDue,
      ]"
      class="custom-task-tag relative-position"
    >
      <div class="close" @click.stop="save(null)">
        <q-icon :name="$icons.matClose" />
      </div>
      {{ taskdates.from | dateMonthDateDefault }}
      <q-tooltip>
        Due Date
      </q-tooltip>
    </div>
    <q-btn
      v-else
      flat
      dense
      size="12px"
      round
      padding="0"
      style="color:#B1BCC8"
      :icon="$icons.matDateRange"
      @click="menu = !menu"
      class="card-options"
    >
      <q-tooltip>
        Due Date
      </q-tooltip>
    </q-btn>
    <div>
      <q-menu
        :value="menu"
        max-height="auto"
        anchor="top right"
        self="top left"
        content-class="overflow-auto task-date-picker date-picker-calendar"
      >
        <q-date
          mask="YYYY-MM-DD"
          :value="dates"
          today-btn
          :options="optionsFn"
          range
          @input="updatedDate"
          color="blue-13"
        >
          <div
            class="date-time-block q-px-md q-py-sm column no-flex-wrap justify-between"
          >
            <!-- Start Date and Time row -->
            <div class="row justify-between items-center flex-no-wrap">
              <div class="date-label">
                <span class="demi-bold">
                  Starts:
                </span>
                <span class="an-14 demi-bold-text">
                  {{ startDate ? startDate.substring(0, 10) : "None" }}
                </span>
              </div>
              <div class="time-input">
                <q-input
                  :value="startTime"
                  @input="(val) => (startTime = val)"
                  @blur="checkTimeValidation"
                  dense
                  type="time"
                  bg-color="white"
                >
                  <div
                    @click="startTimeMenu = !startTimeMenu"
                    class="arrow-icon row items-center no-flex-wrap"
                  >
                    <q-icon
                      v-if="startTimeMenu"
                      :name="$icons.matKeyboardArrowUp"
                    />
                    <q-icon v-else :name="$icons.matKeyboardArrowDown" />
                  </div>
                  <q-menu
                    no-parent-event
                    content-class="time-option-wrapper overflow-auto"
                    max-height="180px"
                    v-model="startTimeMenu"
                  >
                    <q-list class="time-list">
                      <template
                        v-for="(time, index) in startTimeDropdownOptions"
                      >
                        <q-item
                          :key="index"
                          @click.stop="setStartTime(time)"
                          clickable
                        >
                          <q-item-section>
                            {{ time.label }}
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-list>
                  </q-menu>
                </q-input>
              </div>
            </div>
            <!-- End Date and Time row -->
            <div class="row justify-between items-center flex-no-wrap">
              <div class="date-label">
                <span class="demi-bold">
                  Ends:
                </span>
                <span class="an-14 demi-bold-text">
                  {{ endDate ? endDate.substring(0, 10) : "None" }}
                </span>
              </div>
              <div class="time-input">
                <q-input
                  :value="endTime"
                  @input="(val) => (endTime = val)"
                  @blur="checkTimeValidation"
                  dense
                  type="time"
                  bg-color="white"
                >
                  <div
                    @click="endTimeMenu = !endTimeMenu"
                    class="arrow-icon row items-center no-flex-wrap"
                  >
                    <q-icon
                      v-if="endTimeMenu"
                      :name="$icons.matKeyboardArrowUp"
                    />
                    <q-icon v-else :name="$icons.matKeyboardArrowDown" />
                  </div>
                  <q-menu
                    no-parent-event
                    content-class="time-option-wrapper overflow-auto"
                    max-height="180px"
                    v-model="endTimeMenu"
                  >
                    <q-list class="time-list">
                      <template v-for="(time, index) in endTimeDropdownOptions">
                        <q-item
                          :key="index"
                          @click.stop="setEndTime(time)"
                          clickable
                        >
                          <q-item-section>
                            {{ time.label }}
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-list>
                  </q-menu>
                </q-input>
              </div>
            </div>
          </div>
          <div class="row items-center justify-between btn-section">
            <q-btn
              flat
              class="btn-style clear"
              @click="save(null)"
              label="Clear"
              no-caps
            />
            <q-btn
              flat
              color="black"
              class="btn-style"
              @click="menu = false"
              label="Cancel"
              no-caps
            />
            <q-btn
              flat
              padding="xs md"
              class="bg-primary text-white btn-style"
              @click="handleOk({ from: startDate, to: endDate })"
              label="OK"
            />
          </div>
        </q-date>
      </q-menu>
    </div>
  </div>
</template>

<script>
import { isPast } from "date-fns";
import { parseISO } from "date-fns";
import { format } from "date-fns";
import { timeList } from "@/utils/Contents.js";
import { date } from "quasar";
export default {
  name: "TaskDatePicker",
  props: ["taskdates", "className"],
  mounted() {
    if (this.taskdates) {
      if (
        this.taskdates.to &&
        this.taskdates.from &&
        this.taskdates.to.slice(0, 10) === this.taskdates.from.slice(0, 10)
      ) {
        this.dates = this.taskdates.to.slice(0, 10);
      } else if (this.taskdates.to && this.taskdates.from) {
        this.dates = this.taskdates;
      } else {
        this.dates = format(new Date(), "yyyy-MM-dd");
      }
    }
    if (this.startDate && this.startDate.split(" ")[1]) {
      this.startTime = this.startDate.split(" ")[1];
    }
    if (this.endDate && this.endDate.split(" ")[1]) {
      this.endTime = this.endDate.split(" ")[1];
    }
  },
  data() {
    return {
      dates: null,
      menu: false,
      startTimeMenu: false,
      endTimeMenu: false,
      startTime: "09:00",
      endTime: "17:00",
    };
  },
  methods: {
    optionsFn(d) {
      return d >= date.formatDate(Date.now(), "YYYY/MM/DD");
    },
    setStartTime(time) {
      this.startTime = time.value;
      this.startTimeMenu = false;
    },
    setEndTime(time) {
      this.endTime = time.value;
      this.endTimeMenu = false;
    },
    checkTimeValidation() {
      if (
        Date.parse(this.startDate + " " + this.startTime) >
        Date.parse(this.endDate + " " + this.endTime)
      ) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Invalid time value",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.startTime = "09:00";
        this.endTime = "17:00";
      }
      this.isDisabled = false;
    },
    updatedDate(date) {
      if (date && date.type && date.type === "input") {
        date.preventDefault();
        return false;
      }
      if (date && date.from) {
        this.dates = date;
      } else {
        this.dates = date;
      }
    },
    handleOk(date) {
      if (date.from && date.to) {
        this.save(date);
      }
      this.menu = false;
    },
    save(dates) {
      const days = {};
      if (dates) {
        days.from = this.startDate.split(" ")[0] + " " + this.startTime;
        days.to = this.endDate.split(" ")[0] + " " + this.endTime;
      } else {
        this.dates = format(new Date(), "yyyy-MM-dd");
        days.from = null;
        days.to = null;
        this.startTime = "09:00";
        this.endTime = "17:00";
      }
      if (Date.parse(days.from) > Date.parse(days.to)) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Invalid time value",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        return;
      }
      this.$mixpanelEvent("task-due-date");
      this.$emit("save", days);
    },
  },
  computed: {
    startTimeDropdownOptions() {
      if (this.startDate === this.endDate) {
        const startTimeIndex = timeList.findIndex(
          (t) => t.value === this.endTime
        );
        return timeList.slice(0, startTimeIndex);
      }
      return timeList;
    },
    endTimeDropdownOptions() {
      if (this.startDate === this.endDate) {
        const endTimeIndex = timeList.findIndex(
          (t) => t.value === this.startTime
        );
        return timeList.slice(endTimeIndex + 1, timeList.length);
      }
      return timeList;
    },
    startDate() {
      if (this.dates) {
        return this.dates.hasOwnProperty("from") ? this.dates.from : this.dates;
      } else {
        return null;
      }
    },
    endDate() {
      return this.dates && this.dates.hasOwnProperty("to")
        ? this.dates.to
        : this.startDate;
    },
    isEndDateDue() {
      if(format(new Date(this.taskdates.to), "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd")) {
        return "due-date-today";
      }else if(isPast(parseISO(this.taskdates.to))) {
        return "overdue-date";
      }
      return "due-date";
    },
    isStartDateDue() {
      if(format(new Date(this.taskdates.from), "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd")) {
        return "due-date-today";
      }else if(isPast(parseISO(this.taskdates.from))) {
        return "overdue-date";
      }
      return "due-date";
    },
  },
  watch: {
    taskdates() {
      if (this.taskdates) {
        if (
          this.taskdates.to &&
          this.taskdates.from &&
          this.taskdates.to.slice(0, 10) === this.taskdates.from.slice(0, 10)
        ) {
          this.dates = this.taskdates.to.slice(0, 10);
        } else if (this.taskdates.to && this.taskdates.from) {
          this.dates = this.taskdates;
        } else {
          this.dates = format(new Date(), "yyyy-MM-dd");
        }
      }
    },
    menu(to) {
      this.$emit("datePickerChanged", to);
    },
  },
};
</script>
