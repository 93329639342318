<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    :value="value"
    persistent
  >
    <q-card style="width:320px;" class="create-folder">
      <div class="row title q-mb-md">
        <span class="q-mx-auto text-center">Congrats!</span>
      </div>
      <div class="text-dark text-center app-text-normal">Your account is now upgraded. Enjoy your upgraded work in Heycollab.</div>
      <div class="text-center q-mt-md">
        <q-btn
          color="primary"
          dense
          no-caps
          class="create-folder-btn app-text"
          label="Get started"
          @click.stop="successMethod()"
        />
      </div>
    </q-card>
  </q-dialog>
</template>
<script>
export default {
  name: "ConfirmTransactionDialog",
  props: [
    "value",
    "successMethod",
  ],
};
</script>
<style lang="scss" scoped>
.create-folder-btn {
  width: 120px;
  height: 36px;
  font-size: 14px;
}
</style>