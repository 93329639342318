<template>
  <div v-if="shouldShowOnTask" class="q-mr-sm q-mt-sm">
    <div v-if="customField.type === 'dropdown'">
      <q-btn
        class="app-text-normal"
        no-caps
        :style="[
          selectedOptionColor
            ? {
                'background-color': selectedOptionColor,
                color: 'white',
                'font-weight': '500',
              }
            : { 'font-weight': '400' },
          {
            'justify-content': 'space-between',
            width: '100%',
            'font-size': '12px',
          },
        ]"
      >
        <div class="text-truncate" v-tooltip>
          {{ customField.title + ": " + selectedOptionLabel }}
        </div>
        <q-tooltip v-if="isTruncated">
          {{ customField.title + ": " + selectedOptionLabel }}
        </q-tooltip>
      </q-btn>
    </div>
    <div v-else-if="customField.type === 'checkbox'">
      <div class="row items-center custom-field-font">
        <q-icon :name="$icons.matCheckCircle" style="color:#919ca8" size="xs" />
        <div class="q-ml-xs">
          {{ customField.title }}
        </div>
      </div>
    </div>
    <div v-else-if="customField.type === 'number'">
      <div class="row items-center custom-field-font text-truncate" v-tooltip>
        {{ customField.title }}: {{ numberWithCommas(CFNumberNoteTxt) }}
        <q-tooltip v-if="isTruncated">
          {{ customField.title }}: {{ numberWithCommas(CFNumberNoteTxt) }}
        </q-tooltip>
      </div>
    </div>
    <div v-else-if="customField.type === 'text'">
      <div
        @click="isEditNotesOpen = true"
        class="row items-center custom-field-font cursor-pointer text-truncate"
        v-tooltip
      >
        {{ customField.title }}: {{ CFNumberNoteTxt }}
        <q-tooltip  content-class="tooltip-class" v-if="isTruncated" max-width="350px">
          {{ customField.title }}: {{ CFNumberNoteTxt }}
        </q-tooltip>
      </div>
    </div>
    <div v-else-if="customField.type === 'date'">
      <div class="row items-center custom-field-font text-truncate">
        {{ customField.title }}: {{ CFDateTimeValue }}
      </div>
    </div>
    <notes-edit-dialog
      v-if="isEditNotesOpen"
      v-model="isEditNotesOpen"
      :description="CFNumberNoteTxt"
      :handleNoteInput="handleNoteInput"
      @closeDialog="isEditNotesOpen = false"
    />
  </div>
</template>
<script>
import fromUnixTime from "date-fns/fromUnixTime";
import format from "date-fns/format";
import getUnixTime from "date-fns/getUnixTime";
import NotesEditDialog from "@/components/Dialogs/NotesEditDialog";
import { CreateCustomFieldOptionSelectionMutation } from "@/gql";
export default {
  props: ["customField", "task"],
  data() {
    return {
      isEditNotesOpen: false,
      isTruncated: false,
    };
  },
  components: { NotesEditDialog },
  methods: {
    async handleNoteInput(value) {
      this.isEditNotesOpen = false;
      const variables = {
        task_id: this.task.id,
        c_f_id: this.customField.id,
        option_text: value,
      };
      this.task.customFieldOptionSelection = this.task.customFieldOptionSelection.filter(
        (cfs) => cfs.c_f_id !== this.customField.id
      );
      const newCFSelection = {
        __typename: "customFieldOptionSelection",
        id: getUnixTime(new Date()),
        ...variables,
      };
      this.task.customFieldOptionSelection = [
        ...this.task.customFieldOptionSelection,
        newCFSelection,
      ];
      await this.createCFSelection(variables);
    },
    createCFSelection(variables) {
      return this.$api.mutate({
        mutation: CreateCustomFieldOptionSelectionMutation,
        variables,
      });
    },
    numberWithCommas(number) {
      return "$" + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  computed: {
    selectedOptionColor() {
      const dropdpownCFSelection = this.task.customFieldOptionSelection.find(
        (cfs) => cfs.c_f_id === this.customField.id
      );
      const selectedOptionColor =
        dropdpownCFSelection &&
        this.customField.customFieldOption.find(
          (o) => o.id === dropdpownCFSelection.c_f_o_id
        );
      return selectedOptionColor && selectedOptionColor.colorcode;
    },
    selectedOptionLabel() {
      const dropdpownCFSelection = this.task.customFieldOptionSelection.find(
        (cfs) => cfs.c_f_id === this.customField.id
      );
      const selectedOptionLabel =
        dropdpownCFSelection &&
        this.customField.customFieldOption.find(
          (o) => o.id === dropdpownCFSelection.c_f_o_id
        );
      return selectedOptionLabel && selectedOptionLabel.label;
    },
    isChecked() {
      const checkboxCFSelection = this.task.customFieldOptionSelection.find(
        (cfs) => cfs.c_f_id === this.customField.id
      );
      return checkboxCFSelection && checkboxCFSelection.option_text
        ? checkboxCFSelection.option_text.toLowerCase() === "true"
        : false;
    },
    CFDateTimeValue() {
      if (this.customField.type === "date") {
        const checkboxCFSelection = this.task.customFieldOptionSelection.find(
          (cfs) => cfs.c_f_id === this.customField.id
        );
        const dateLabel =
          checkboxCFSelection &&
          checkboxCFSelection.option_text &&
          format(fromUnixTime(checkboxCFSelection.option_text), "MMM dd") +
            " at " +
            format(fromUnixTime(checkboxCFSelection.option_text), "hh:mm aaaa");
        return dateLabel;
      }
      return null;
    },
    CFNumberNoteTxt() {
      const checkboxCFSelection = this.task.customFieldOptionSelection.find(
        (cfs) => cfs.c_f_id === this.customField.id
      );
      return checkboxCFSelection && checkboxCFSelection.option_text;
    },
    shouldShowOnTask() {
      if (this.customField.is_active && this.customField.show_in_task) {
        if (this.customField.type === "dropdown" && this.selectedOptionLabel) {
          return true;
        } else if (this.customField.type === "checkbox" && this.isChecked) {
          return true;
        } else if (
          (this.customField.type === "number" ||
            this.customField.type === "text") &&
          this.CFNumberNoteTxt
        ) {
          return true;
        } else if (this.customField.type === "date" && this.CFDateTimeValue) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-truncate {
  text-overflow: ellipsis !important;
  max-width: 195px;
  white-space: nowrap !important;
  overflow: hidden !important;
  display: block;
}
.custom-field-font {
  font-size: 12px;
  font-weight: 500;
  color: #677484 !important;
}
</style>
