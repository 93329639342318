<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
  >
    <q-card class="q-px-lg q-py-md bg-white task-duplication">
      <div class="close-btn-bg">
        <q-btn
          :icon="$icons.matClose"
          text-color="blue-grey-8"
          round
          flat
          padding="0"
          dense
          @click="closeDialog"
          v-close-popup
        />
      </div>
      <div class="row title">
        <span class="q-mx-auto">Duplicate {{title}}</span>
      </div>
      <div class="text-bold q-mt-md q-mb-sm ">
        {{title | capitalize }} name
      </div>
      <q-input outlined dense v-model="cardData.title" />
      <div class="text-bold q-mt-sm q-mb-sm ">
        Workspace
      </div>
      <div class="q-mt-sm">
        <q-btn
          outline
          size="md"
          class="dropdown-set full-width"
          no-caps
          style="font-weight:400"
          :icon-right="$icons.matKeyboardArrowDown"
          :label="selectedWorkspace && selectedWorkspace.title"
        >
          <q-menu content-class="workspace-menu">
            <div class="search-member">
              <q-input
                autofocus
                ref="search"
                v-model="search"
                outlined
                placeholder="Filter workspaces"
                clearable
                dense
              >
                <template v-slot:prepend>
                  <q-icon :name="$icons.matSearch" />
                </template>
              </q-input>
            </div>
            <q-list style="min-width:140px; max-height: 250px; overflow: auto">
              <q-item
                @click="chooseWorkspace(workspace)"
                v-for="workspace in filteredWorkspaces"
                :key="workspace.id"
                clickable
                v-close-popup
              >
                <q-item-section>
                  {{ workspace.title }}
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
      <div class="text-bold q-mt-md q-mb-sm ">
        Include
      </div>
      <div>
        <div class="row q-pr-lg">
          <q-checkbox
            v-model="cardData.includeTask"
            @input="checkForTask"
            size="xs"
            label="Tasks"
            class="flex-1"
          />
          <q-checkbox
            size="xs"
            v-model="cardData.includeSubtasks"
            @input="checkForSubTask"
            label="Subtasks"
            class="flex-1"
          />
        </div>
        <div class="row q-pr-lg">
          <q-checkbox
            size="xs"
            v-model="cardData.includeAttachments"
            @input="checkForAttachment"
            label="Attachments"
          />
        </div>
      </div>
      <q-btn
        dense
        no-caps
        padding="5px 5px"
        class="create-task-btn q-mt-md"
        color="primary"
        size="md"
        :label="'Duplicate '+title"
        @click="createCardHandler"
        :loading="isLoading"
      />
    </q-card>
  </q-dialog>
</template>

<script>
import RandomPosition from "random-position";

import orderBy from "lodash/orderBy";
//import groupBy from "lodash/groupBy";
//import pick from "lodash/pick";
import last from "lodash/head";
//import map from "lodash/map";

//import getUnixTime from "date-fns/getUnixTime";

import { UpdateTaskMutation, CreateDuplicateCardMutation } from "@/gql";
import Fuse from "fuse.js";

export default {
  name: "DuplicateCardDialog",
  props: ["value", "card", "closeDialog", "userId", "title"],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
  },
  data() {
    return {
      selectedWorkspace: null,
      isLoading: false,
      cardData: {
        title: null,
        includeTask: true,
        includeSubtasks: true,
        includeAttachments: true,
      },
      search: null,
    };
  },
  mounted() {
    this.cardData.title = this.card.title;
    this.selectedWorkspace = this.filteredWorkspaces && this.filteredWorkspaces[0];
  },
  methods: {
    checkForTask(flag) {
      if(flag) {
        this.cardData.includeTask = this.cardData.includeSubtasks = this.cardData.includeAttachments = true;
      } else {
        this.cardData.includeTask = this.cardData.includeSubtasks = this.cardData.includeAttachments = false;
      }
    },
    checkForSubTask() {
      if(!this.cardData.includeTask) {
        this.cardData.includeSubtasks = false;
      }
    },
    checkForAttachment() {
      if(!this.cardData.includeTask) {
        this.cardData.includeAttachments = false;
      }
    },
    chooseWorkspace(workspace) {
      this.selectedWorkspace = workspace;
    },
    createCardHandler() {
      this.isLoading = true;
      const card = {
        card_id: this.card.id,
        title: this.cardData.title,
        workspace_id: this.selectedWorkspace.id,
        board_id: this.selectedWorkspace.boards[0].id,
        is_task: this.cardData.includeTask,
        is_subtask: this.cardData.includeSubtasks,
        is_attachment: this.cardData.includeAttachments,
      };
      this.addCard(card);
    },
    async addCard(card) {
      const sequence =
				this.selectedWorkspace.boards[0] && this.selectedWorkspace.boards[0].cards.length > 0
					? RandomPosition.between(
							RandomPosition.first(),
							last(this.selectedWorkspace.boards[0].cards).sequence
						)
					: RandomPosition.between(
							RandomPosition.first(),
							RandomPosition.last()
						);
      const variables = {
        ...card,
        sequence,
      };
      
      await this.$api
        .mutate({
          mutation: CreateDuplicateCardMutation,
          variables,
        })
        .then((response) => {
          // 1. get query
          const boardQuery = this.$api.getQuery(`BoardQuery:${this.selectedWorkspace.boards[0].id}`);
          if (boardQuery.data) {
            boardQuery.data.board.cards.unshift(response.data.moveCard);
          }

          // let oldBoardId = this.card.board_id;
          // const query = this.$api.getQuery(`BoardQuery:${oldBoardId}`);

          // query.data.board.cards = query.data.board.cards.filter(
          //   (card) => card.id !== this.card.id
          // );

          this.isLoading = false;
          this.$q.notify({
            classes: "success-notification",
            position: "top-right",
            message: "Card duplicated successfully.",
            icon: this.$icons.matAnnouncement,
          });
          this.closeDialog();
        });
    },
    async updateTask(args) {
      const variables = {
        ...args,
      };
      await this.$api.mutate({
        mutation: UpdateTaskMutation,
        variables,
      });
    },
    updateCoverImage(mediaId, taskId) {
      const task = this.$api.getEntity("task", taskId);
      task.cover_image = mediaId;
      this.updateTask({
        id: taskId,
        cover_image: mediaId,
      });
    },
  },
  computed: {
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    workspaceIds() {
      return this.workspaces.map((m) => m.id);
    },
    filteredWorkspacesFuse() {
      if (this.workspaces) {
        const workspaces = this.workspaces.filter((workspace) =>
          this.workspaceIds.includes(workspace.id)
        );
        const orderedWorkspaces = orderBy(workspaces, ["title"], ["asc"]);
        return new Fuse(orderedWorkspaces, {
          keys: ["title"],
          shouldSort: true,
        });
      }
      return this.workspaces.filter(
        (workspace) => !this.workspaceIds.includes(workspace.id)
      );
    },
    filteredWorkspaces() {
      return this.search
        ? this.filteredWorkspacesFuse.search(this.search)
        : this.filteredWorkspacesFuse.hasOwnProperty("list")
        ? this.filteredWorkspacesFuse.list
        : this.filteredWorkspacesFuse;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-member {
  padding: 15px 25px 0px 25px;
}
</style>
