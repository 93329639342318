<template>
  <div class="bg-white">
    <div
      v-if="!boardCards"
      style="flex-wrap: nowrap;height: calc(100vh - 84px);"
      class="column relative-position"
    >
      <bookmark-toolbar
        :pinPost="pinPosts"
        :bookmarks="bookmarks"
        :bookmarkFolders="bookmarkFolders"
        :labels="taskLabels"
      />

      <div class="col-grow drag-scroll overflow-auto">
        <div class="row items-start q-px-sm q-pb-xs float-left" style="flex-wrap: nowrap">
          <div class="container column q-pt-sm" style="flex-wrap:nowrap;background: transparent;padding-right:6px;padding-left:5px;max-width: 295px;max-height: calc(100vh - 184px);">
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
          </div> 
          <div class="container column q-pt-sm" style="flex-wrap:nowrap;background: transparent;padding-right:6px;padding-left:5px;max-width: 295px;max-height: calc(100vh - 184px);">
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
          </div> 
          <div class="container column q-pt-sm" style="flex-wrap:nowrap;background: transparent;padding-right:6px;padding-left:5px;max-width: 295px;max-height: calc(100vh - 184px);">
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
          </div> 
          <div class="container column q-pt-sm" style="flex-wrap:nowrap;background: transparent;padding-right:6px;padding-left:5px;max-width: 295px;max-height: calc(100vh - 184px);">
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
          </div> 
          <div class="container column q-pt-sm" style="flex-wrap:nowrap;background: transparent;padding-right:6px;padding-left:5px;max-width: 295px;max-height: calc(100vh - 184px);">
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
            <div class="task-card" style="min-height: 120px;">
              <div class="gradient element1">
              </div>
              <div class="gradient element2">
              </div>
              <div class="gradient element3">
              </div>
            </div>
          </div> 
        </div>  
      </div>
    </div>
    <div
      class="column relative-position"
      @click.stop="$emit('closeNotificaiton')"
      style="flex-wrap: nowrap; height: calc(100vh - 84px)"
      :style="
        backgroundImage && (taskView === 'board' || taskView === 'list')
          ? `background-image: url(${getEncoded(
              backgroundImage
            )}); background-repeat: round; background-size: cover;`
          : `background: ${gradient}`
      "
    >
      <bookmark-toolbar
        :pinPost="pinPosts"
        :bookmarks="bookmarks"
        :bookmarkFolders="bookmarkFolders"
        :labels="taskLabels"
      />
      <!-- <board-toolbar :toggleDrawerRight="toggleDrawerRight" /> -->
      <div
        v-if="isBoardBannerVisible && taskView === 'board'"
        class="empty-timeline"
        style="padding-top: 1px; margin-bottom: 0"
      >
        <div class="demo" style="background-color: white">
          <div class="tune-icon">
            <q-icon :name="$icons.fasCheck" size="15px" />
          </div>
          <div class="video">
            <div class="thumbnail" @click="videoPlayer">
              <q-icon color="primary" :name="$icons.mdiPlay" size="xl" />
            </div>
          </div>
          <div class="text-contents">
            <span class="text-bold" style="margin-left: 31px"
              >Organize your team’s work and time</span
            >
            <p>
              Create tasks and assign them to your team. Track your time and
              view work in board, list, calendar and timeline view.
            </p>
            <div
              style="width: 50px; margin-left: 31px; margin-top: 10px"
              class="d-flex"
            >
              <!-- <q-btn
                dense
                no-caps
                class="primary-button"
                color="#15d89a"
                size="md"
                label="Learn more"
                @click="$router.push({ name: 'LearnView' })"
              /> -->
              <q-btn
                dense
                no-caps
                style="
                  margin-left: 10px;
                  height: 32px;
                  border: solid 1px #677484;
                "
                size="md"
                label="Dismiss"
                @click="removeBanner"
              />
            </div>
          </div>
          <div class="close-icon" @click="removeBanner">
            <q-icon :name="$icons.matClose" size="22px" />
          </div>
        </div>

        <video-dialog
          v-if="showVideoModel"
          v-model="showVideoModel"
          :resetVideoDialog="resetVideoDialog"
          :videoMediaData="videoMediaData"
          :displayPlayer="displayPlayer"
        ></video-dialog>
      </div>
      <q-btn
        round
        :icon="$icons.matPalette"
        class="change-background-button"
        size="23px"
        padding="xs"
        v-if="taskView === 'board' || taskView === 'list'"
        @click="checkBackgroundImageAccess"
      >
        <q-tooltip>Change background</q-tooltip>
        <q-menu v-model="changeBackgroundPopup" v-if="changeBackgroundPopup">
          <div class="change-background-menu">
            <q-toolbar class="toolbar">
              <q-toolbar-title class="title">
                Background colors & images
              </q-toolbar-title>
              <q-space />
              <q-btn
                dense
                round
                :icon="$icons.matClose"
                @click="changeBackgroundPopup = false"
              />
            </q-toolbar>
            <div class="tiles-list flex">
              <div
                class="tile"
                v-for="(bgItem, i) in backgroundList"
                :key="`background-${i}`"
                :style="`background-color: ${bgItem.background}`"
                @click="changeBoardBackground(bgItem)"
              >
                <q-icon
                  v-if="gradient === bgItem.background"
                  :name="$icons.matCheckCircle"
                />
              </div>
              <div
                class="tile"
                v-for="(bgItem, i) in boardBackgrounds"
                :key="`image-${i}`"
                :style="`background-image: url(${getEncoded(
                        bgItem.file
                      )}); background-repeat: round`"
                @click="changeBoardBackground(bgItem, true)"
              >
                <q-icon
                  v-if="backgroundImage === bgItem.file"
                  :name="$icons.matCheckCircle"
                />
              </div>
            </div>
          </div>
        </q-menu>
      </q-btn>
      <button :class="['fab-btn']" v-if="isVisible([1, 2, 3])">
        <q-icon :name="$icons.mdiPlusThick" size="20px" color="white" />
        <q-menu
          v-model="fabMenu"
          auto-close
          :offset="[-20, 0]"
          content-style="width:150px;max-height:200px;box-shadow:0px 2px 10px 0px rgb(0 0 0 / 19%)"
        >
          <q-list class="q-py-sm">
            <q-item
              @click="openDocEditor(currentWorkspaceId)"
              :class="['items-center q-px-lg']"
              style="font-size: 15px"
              clickable
            >
              <q-icon
                :name="$icons.matDescription"
                size="18px"
                style="margin-right: 20px"
              />
              New doc
            </q-item>
            <q-item
              v-if="(taskView === 'board' || taskView === 'list') && canPostMessageFromWorkspace"
              :class="['items-center q-px-lg']"
              style="font-size: 15px"
              clickable
              @click="addCard(null)"
            >
              <q-icon
                :name="$icons.matViewList"
                size="18px"
                style="margin-right: 20px"
              />
              New list
            </q-item>
            <q-item
              @click="createNewTask"
              :class="['items-center q-px-lg']"
              style="font-size: 15px"
              clickable
            >
              <q-icon
                :name="$icons.fasCheck"
                size="18px"
                style="margin-right: 20px"
              />
              New task
            </q-item>
          </q-list>
        </q-menu>
      </button>
      <!-- <div class="create-new-task-doc row items-center">
        <div
          @click="createNewTask"
          class="btn task row items-center q-px-sm cursor-pointer q-mr-md"
        >
          <q-tooltip>
            Create new task
          </q-tooltip>
          <div class="btn-icon">
            <q-icon size="18px" :name="$icons.mdiPlusThick" />
          </div>
          <div class="btn-lbl flex-1 q-ml-sm">
            Task
          </div>
        </div>
        <div
          class="btn doc row items-center q-px-sm cursor-pointer bg-positive"
          @click="openDocEditor"
        >
          <q-tooltip>
            Create new doc
          </q-tooltip>
          <div class="btn-icon">
            <q-icon size="18px" :name="$icons.mdiPlusThick" />
          </div>
          <div class="btn-lbl flex-1 q-ml-sm">
            Doc
          </div>
        </div>
      </div> -->
      <q-page>
        <div
          class="col-grow drag-scroll overflow-auto"
          style="height: calc(100vh - 84px)"
          v-show="taskView === 'board'"
          v-drag-scroll
          ref="board_card_list"
        >
          <div
            class="row items-start q-px-sm q-pb-xs float-left"
            ref="card_list_item"
            style="flex-wrap: nowrap"
            v-sortable="{
              onStart: onDragStart,
              onEnd: onDragEnd,
              filter: '.notDraggable',
            }"
          >
            <board-card
              v-for="(card, index) in boardCards"
              :index="index"
              :key="card.id"
              :data-id="card.id"
              :card="card"
              :labels="taskLabels"
              :workspace="currentWorkspace"
              :workspaceMembers="workspaceMembers"
              :board="board"
              :dueDateFilterMode="dueDateFilterMode"
              :tasks="cardTasks[card.id]"
              :editTaskDialogHandler="editTask"
              :addTaskEventHandler="addTask"
              :onUpdateTaskMembersHandler="reactivityOnUpdateTaskMembers"
              :addNewColumnFromColumn="(card) => addCard(card)"
              @updateCard="updateCardHandler"
              @deletCard="deleteCardHandler"
              @deleteTask="deleteTaskConfirmBoxHandler"
              @clearCompletedTaskFromColumn="clearCompletedTaskFromColumn"
              @clearActiveTaskFromColumn="clearActiveTaskFromColumn"
              @createTask="(task) => createNewTask(task, card)"
            ></board-card>
            <!-- <div
              class="column no-wrap rounded-borders"
              style="white-space: nowrap; margin-top: 50px; margin-left: 12px"
              v-if="canPostMessageFromWorkspace"
            >
              <div class="relative-position">
                <create-card-editable
                  v-model="card_title"
                  @update="(e) => (card_title = e)"
                  class="rounded-borders overflow-auto"
                  @onEnter="addCard(null)"
                  placeholder="Column Name"
                ></create-card-editable>
                <q-btn
                  color="teal-14"
                  label="New Column"
                  size="16px"
                  no-caps
                  class="q-pa-sm"
                  @click="addCard(null)"
                  :disabled="card_title === null || card_title === ''"
                  ref="addcardbtn"
                />
              </div>
            </div> -->
          </div>
        </div>

        <div
          v-show="taskView === 'list'"
          class="task-list-view q-pl-xs q-py-md overflow-auto"
          style="max-height: calc(100vh - 160px)"
        >
          <div class="q-mb-sm"></div>
          <table class="q-pr-md" style="table-layout: fixed">
            <thead class="table-title flex-1 row flex-no-wrap">
              <tr
                class="flex-1 row flex-no-wrap q-pl-lg"
                style="min-width: 1005px"
              >
                <th
                  style="min-width: 215px; max-width: 350px; width: 100%"
                  class="flex-1"
                >
                  <div style="width: fit-content" class="d-flex">
                    <!-- <q-btn
                      size="sm"
                      no-caps
                      class="control-button q-mx-md"
                      @click="openCreateTaskDialog({})"
                      style="width: 90px;"
                      v-if="canPostMessageFromWorkspace"
                    >
                      <div class="icon" style="margin: 0">
                        <q-icon :name="$icons.matAdd" size="xs" />
                      </div>
                      <span style="margin-left: 3px; margin-top: 1.5px;"
                        >New task</span
                      >
                    </q-btn> -->
                    <q-btn
                      size="sm"
                      class="field-filter flex-no-wrap"
                      no-caps
                      style="font-weight: 400"
                      :icon-right="$icons.matKeyboardArrowDown"
                      label="Fields"
                      :color="isMenuOpen ? 'primary' : ''"
                      :text-color="isMenuOpen ? 'white' : 'primary'"
                    >
                      <q-menu
                        v-model="isMenuOpen"
                        max-height="250px"
                        anchor="top end"
                        self="top start"
                        content-class="overflow-auto"
                      >
                        <q-list>
                          <template v-for="column in columnFiledList">
                            <q-item :key="column.id" style="min-height: 24px">
                              <q-item-section side style="padding-right: 12px">
                                <span
                                  @click="toggleFieldVisibility(column)"
                                  class="filter-custom-checkbox"
                                >
                                  <div
                                    class="filter-selected-custom-checkbox"
                                    v-if="column.isvisible"
                                  ></div>
                                </span>
                              </q-item-section>
                              <q-item-section>
                                {{
                                  column.customField
                                    ? column.customField.title
                                    : column.title
                                }}
                              </q-item-section>
                            </q-item>
                          </template>
                        </q-list>
                      </q-menu>
                    </q-btn>
                  </div>
                </th>
                <template v-for="column in columnFiledList">
                  <div
                    :style="[
                      {
                        'min-width':
                          column.title === 'Due date'
                            ? '95px'
                            : column.title === 'Tracked time'
                            ? '140px'
                            : column.title === 'Subtasks'
                            ? '100px'
                            : column.title === 'Tags'
                            ? '150px'
                            : '130px',
                        'max-width': column.title === 'Tracked time' && '135px',
                      },
                    ]"
                    :key="column.id"
                    v-if="column.isvisible"
                    :class="['row items-center']"
                  >
                    <th style="font-weight: 500">
                      {{ column.title }}
                    </th>
                  </div>
                </template>
              </tr>
            </thead>
            <tbody>
              <div
                v-sortable="{
                  onStart: onDragStart,
                  onEnd: onDragEnd,
                }"
              >
                <card-list-item
                  v-for="card in boardCards"
                  :key="card.id"
                  :data-id="card.id"
                  :card="card"
                  :labels="taskLabels"
                  :workspace="currentWorkspace"
                  :workspaceMembers="workspaceMembers"
                  :board="board"
                  :dueDateFilterMode="dueDateFilterMode"
                  :tasks="cardTasks[card.id]"
                  :editTaskDialogHandler="editTask"
                  :addTaskEventHandler="addTask"
                  :onUpdateTaskMembersHandler="reactivityOnUpdateTaskMembers"
                  :addNewColumnFromColumn="(card) => addCard(card)"
                  :columnFiledList="columnFiledList"
                  @deleteTask="deleteTaskConfirmBoxHandler"
                  @updateCard="updateCardHandler"
                  @deletCard="deleteCardHandler"
                  @clearCompletedTaskFromColumn="clearCompletedTaskFromColumn"
                  @clearActiveTaskFromColumn="clearActiveTaskFromColumn"
                />
              </div>
            </tbody>
          </table>
        </div>
        <!-- Calendar View -->
        <div v-if="taskView === 'calendar'">
          <calendar-view
            :tasksList="tasksList"
            :editTask="editTask"
            :workspace="currentWorkspace"
            :board="board"
            @deleteTask="deleteTaskConfirmBoxHandler"
            @createTask="openCreateTaskDialog"
          />
        </div>
        <div v-if="taskView === 'timeline'" style="height: calc(100vh - 84px)">
          <gantt-chart-view
            ref="gantt_chart_view"
            :tasksList="tasksList"
            :editTask="editTask"
          />
        </div>
      </q-page>
      <q-dialog
        transition-hide="none"
        transition-show="none"
        v-model="taskDialog"
        @keydown.esc="closeTaskDialog"
        @before-hide="closeDialog"
        persistent
      >
        <task-dialog
          v-if="editTaskdata"
          place="board"
          :tasks="editTaskdata"
          :boardId="board.id"
          :taskLabels="taskLabels"
          :workspaceid="currentWorkspaceId"
          :workspace="currentWorkspace"
          :isWorkspaceOwner="isWorkspaceOwner"
          :currentUser="user"
          :authToken="authToken"
          :workspaceMembers="workspaceMembers"
          :currentCompany="currentCompany"
          :activeCompany="activeCompany"
          :openSubtask="openSubtask"
          :openTaskComment="openTaskComment"
          :reactions="reactions"
          :attachTaskMediaHandler="attachTaskMedia"
          :onUpdateTaskMembersHandler="reactivityOnUpdateTaskMembers"
          :deleteTaskCommentHandler="deleteTaskComment"
          :detachTaskAttachmentHandler="detachTaskAttachment"
          :fromMessage="isNewTask"
          :cardList="cardList"
          :defaultCard="selectedCard"
          :shouldDeleteTask="shouldDeleteTask"
          :taskDialog="taskDialog"
          :board="board"
          @reactOnPost="reactOnPost"
          @updateMediaCache="updateMediaCache"
          @createSubTask="createSubTask"
          @close="closeTaskDialog"
          @updateTimeline="updateTimelineChart"
          @success="taskDialogSuccessHandler"
          @clearSubtasks="clearSubtasks"
          @createComment="createComment"
          @clickonmoreoption="clickonmoreoption"
          @deleteCommentPoint="deleteCommentPoint"
          @updatepositions="updatepositions"
          @closeMediaModel="closeMediaModel"
          @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
          @updateComment="updateComment"
          @sendComment="sendComment"
          @deleteMediaComment="deleteMediaComment"
          @addTaskAttchmentToMedia="addTaskAttchmentToMedia"
          @updateMediaCacheWithReactivity="updateMediaCacheWithReactivity"
          :openSubtaskDialog="openSubtaskDialog"
        ></task-dialog>
      </q-dialog>
      <q-dialog
        transition-hide="none"
        transition-show="none"
        persistent
        v-model="subTaskDialog"
        @before-hide="closeSubTaskDialog"
      >
        <sub-task-dialog
          v-if="currentSubTask"
          :tasks="currentTask"
          :boardId="board.id"
          :cardList="cardList"
          :defaultCard="selectedCard"
          :shouldDeleteTask="shouldDeleteTask"
          :isWorkspaceOwner="isWorkspaceOwner"
          :detachTaskAttachmentHandler="detachTaskAttachment"
          :taskLabels="taskLabels"
          :subTask="currentSubTask"
          :workspace="currentWorkspace"
          :workspaceid="currentWorkspaceId"
          :workspaceMembers="workspaceMembers"
          :currentCompany="currentCompany"
          :activeCompany="activeCompany"
          :attachTaskMediaHandler="attachTaskMedia"
          :onUpdateTaskMembersHandler="reactivityOnUpdateTaskMembers"
          :openSubtask="openSubtask"
          :authToken="authToken"
          :reactions="reactions"
          @createComment="createComment"
          :openTaskComment="openTaskComment"
          :deleteTaskCommentHandler="deleteTaskComment"
          @deleteCommentPoint="deleteCommentPoint"
          @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
          @updateComment="updateComment"
          @sendComment="sendComment"
          @deleteMediaComment="deleteMediaComment"
          @clearSubtasks="clearChildSubtasks"
          :backToTask="backToTask"
          @createSubTask="createSubTask"
          :currentUser="user"
          @reactOnPost="reactOnPost"
          @updateMediaCache="updateMediaCache"
          @clickonmoreoption="clickonmoreoption"
          @updatepositions="updatepositions"
          @closeMediaModel="closeMediaModel"
          @addTaskAttchmentToMedia="addTaskAttchmentToMedia"
          @updateMediaCacheWithReactivity="updateMediaCacheWithReactivity"
        ></sub-task-dialog>
      </q-dialog>
      <div v-if="confirmBox.taskRemove.id && confirmBox.taskRemove.flag">
        <confirm-dialog
          v-model="confirmBox.taskRemove.flag"
          title="Delete task?"
          question="Deleting this task will also delete all the sub-tasks and comments in it."
          cancleText="Cancel"
          successText="Delete"
          :cancleMethod="resetTaskRemoveConfirmbox"
          :successMethod="deleteTask"
          :loading="confirmBox.taskRemove.loader"
        />
      </div>
      <div v-if="isTaskFound">
        <confirm-dialog
          v-model="isTaskFound"
          title="No task found"
          cancleText="Cancel"
          :cancleMethod="closeNoTaskDialoge"
        />
      </div>
    </div>
    <confirm-transaction-dialog
      v-model="confirmTransaction.flag"
      v-if="confirmTransaction.flag"
      :successMethod="boardRedirect"
    />
    <new-task-dialog
      v-if="addTaskSection"
      v-model="addTaskSection"
      :closeDialog="closeNewTaskDialog"
      :workspace="currentWorkspace"
      :card="currentCard"
      :taskLabels="taskLabels"
      :userId="user.id"
      :showWorkspaceSection="false"
      :sequencePosition="sequencePosition"
    />
    <document-editor-dialog
      v-if="documentDialog"
      v-model="documentDialog"
      :closeDocEditor="closeDocEditor"
      :openDocEditor="openDocEditor"
      :documentProp="createdDoc"
      :setActiveDocument="setActiveDocument"
      :activeDocumentId="activeDocumentId"
    />
    <time-log-dialog
      v-if="timelogs.flag"
      v-model="timelogs.flag"
      :timelogs="timelogs.data"
      :taskObj="timelogs.task"
      :board="board"
      :workspace="currentWorkspace"
      :closeDialog="closeTimeLogDialog"
      :isAddTaskTime="timelogs.is_add_time"
    />
  </div>
</template>
<script>
import RandomPosition from "random-position";

import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import groupBy from "lodash/groupBy";
import last from "lodash/last";
import head from "lodash/head";
import pick from "lodash/pick";
import getUnixTime from "date-fns/getUnixTime";
import findLastIndex from "lodash/findLastIndex";
import isEmpty from "lodash/isEmpty";
import BoardsMethodMixin from "@/mixins/BoardsMethodMixin";
import MediaMethodMixin from "@/mixins/MediaMethodMixin";
import UserScope from "@/mixins/UserScope";
import DocumentEditorMixin from "@/mixins/DocumentEditorMixin";

// import BoardToolbar from "@/views/WorkspaceView/BoardToolbar";
import BoardCard from "@/views/BoardView/BoardCard";
import TaskDialog from "@/views/BoardView/TaskDialog";
import CardListItem from "@/views/BoardView/CardListItem.vue";
// import CreateCardEditable from "@/views/BoardView/CreateCardEditable";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import ConfirmTransactionDialog from "@/components/Dialogs/ConfirmTransactionDialog";
import CalendarView from "@/views/CalendarView.vue";
import NewTaskDialog from "@/views/MyWorkView/NewTaskDialog";
import BookmarkToolbar from "@/views/WorkspaceView/BookmarkToolbar";
import DocumentEditorDialog from "@/components/Dialogs/DocumentEditorDialog";
import SubTaskDialog from "@/views/BoardView/SubTaskDialog";

import TimeLogDialog from "@/components/Dialogs/TimeLogDialog";

import mixpanel from "@/mixpanel";
import {
  CreateTaskMutation,
  CreateCardMutation,
  UpdateCardMutation,
  DeleteCardMutation,
  DeleteTaskMutation,
  BoardQuery,
  NotificationsQuery,
  CompanyQuery,
  PlanQuery,
  AttachWorkspaceFieldMutation,
  DetachWorkspaceFieldMutation,
  TaskQuery,
  SubtaskQuery,
  CreateBoardBackgroundMutation,
  UpdateBoardBackgroundMutation,
} from "@/gql";
import GanttChartView from "@/views/GanttChartView";
import VideoDialog from "@/components/VuePlayer/VideoDialog";

import { mapGetters } from "vuex";
export default {
  name: "BoardView",
  props: [
    "toggleDrawerRight",
    "boardFilterObj",
    "drawerRight",
    "taskView",
    "boardSearchOptions",
  ],
  mixins: [BoardsMethodMixin, MediaMethodMixin, UserScope, DocumentEditorMixin],
  components: {
    // CreateCardEditable,
    BoardCard,
    TaskDialog,
    ConfirmDialog,
    CardListItem,
    CalendarView,
    GanttChartView,
    ConfirmTransactionDialog,
    VideoDialog,
    NewTaskDialog,
    BookmarkToolbar,
    DocumentEditorDialog,
    SubTaskDialog,
    TimeLogDialog,
  },
  api: {
    company: {
      query: CompanyQuery,
      cacheKey: "CompanyQuery",
      defaultValue: null,
    },
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
    board: {
      defaultValue: null,
      cacheKey() {
        return `BoardQuery:${this.currentWorkspace.boards[0].id}`;
      },
    },
    taskLabels: {
      defaultValue: [],
      cacheKey: "TaskLabelsQuery",
    },
    workspaceMembers: {
      defaultValue: [],
      cacheKey() {
        return `WorkspaceMembersQuery:${this.currentWorkspaceId}`;
      },
    },
    reactions: {
      defaultValue: null,
      cacheKey: "ReactionsQuery",
    },
    companyBackgrounds: {
      defaultValue: [],
      cacheKey() {
        return "CompanyBackgroundsQuery";
      },
    },
    workspaceFields: {
      defaultValue: null,
      cacheKey() {
        return `WorkspaceFieldsQuery:${parseInt(this.currentWorkspaceId)}`;
      },
    },
    pinPosts: {
      defaultValue: [],
      cacheKey() {
        return `PinMessagesQuery:${this.currentWorkspaceId}`;
      },
    },
    bookmarks: {
      defaultValue: [],
      cacheKey() {
        return `BookmarksQuery:${this.currentWorkspaceId}`;
      },
    },
    bookmarkFolders: {
      defaultValue: [],
      cacheKey() {
        return `BookmarkFoldersQuery:${this.currentWorkspaceId}`;
      },
    },
  },
  data() {
    return {
      subTaskDialog: false,
      currentTask: null,
      currentSubTask: null,
      card_title: null,
      boardLoader: false,
      taskDialog: false,
      task: null,
      confirmBox: {
        taskRemove: {
          flag: false,
          loader: false,
          id: null,
        },
      },
      backgroundList: [
        { background: "#FFFFFF", isImage: false },
        { background: "#F6F7FB", isImage: false },
        { background: "#f8f8f8", isImage: false },
        { background: "#ffdee1", isImage: false },
        { background: "#ffeeda", isImage: false },
        { background: "#c7ffd2", isImage: false },
        { background: "#cfeaff", isImage: false },
        { background: "#e0def2", isImage: false },
        { background: "#f2e7de", isImage: false },
        { background: "#d8d8d8", isImage: false },
      ],
      changeBackgroundPopup: false,
      isNewTask: false,
      shouldDeleteTask: false,
      onLine: navigator.onLine,
      showBackOnline: false,
      selectedCard: null,
      showChangeBackground: false,
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
      isTaskFound: false,
      showBgMenu: false,
      confirmTransaction: {
        flag: false,
      },
      isMenuOpen: false,
      addTaskSection: false,
      currentCard: null,
      sequencePosition: null,
      fabMenu: false,
      timelogs:{
        flag:false,
        data:[],
        task:null,
        is_add_time:false,
      },
    };
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
  mounted() {
    if (this.$route.query.sub_task) {
      this.openSubtaskDialog(
        this.$route.query.task,
        this.$route.query.sub_task
      );
    } else if (this.$route.query.task) {
      this.openTask(this.$route.query.task);
    }

    if (this.$route.query.show_dailog) {
      if (!isEmpty(this.$route.query)) {
        this.$router.replace({ query: null });
      }

      this.confirmTransaction.flag = true;
    }
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    async backToTask(taskId) {
      await this.openTask(taskId);
      this.closeSubTaskDialog();
    },
    closeSubTaskDialog() {
      if (!isEmpty(this.$route.query)) {
        this.$router.replace({ query: null });
      }
      this.subTaskDialog = false;
      this.currentSubTask = null;
    },
    createNewTask(task, card) {
      this.sequencePosition = task.sequencePosition
        ? task.sequencePosition
        : "bottom";
      this.currentCard = card;
      this.addTaskSection = true;
    },
    closeNewTaskDialog() {
      this.currentCard = null;
      this.addTaskSection = false;
    },
    toggleFieldVisibility(field) {
      if (field.isvisible) {
        this.detachField(field);
      } else {
        this.attachField(field);
      }
      //const workspaceField = this.$api.getEntity("workspaceField", field.id);
      const workspaceField = this.workspaceFields.find(
        (c) => c.id === field.id
      );
      workspaceField.isvisible = !workspaceField.isvisible;
    },
    async attachField(field) {
      const variables = {
        w_f_id: field.id,
      };
      await this.$api.mutate({
        mutation: AttachWorkspaceFieldMutation,
        variables,
      });
    },
    async detachField(field) {
      const variables = {
        w_f_id: field.id,
      };
      await this.$api.mutate({
        mutation: DetachWorkspaceFieldMutation,
        variables,
      });
    },
    async boardRedirect() {
      const variables = {
        plan_id: this.company.subscription.plan_id || 1,
      };
      const response = await this.$api.query({
        query: PlanQuery,
        cacheKey: "PlanQuery",
        variables,
      });
      if (response.data && response.data.plan) {
        let planPrice = response.data.plan.planPricing.filter((o) => {
          return o.price_id === this.company.subscription.price_id;
        });
        let amout = planPrice.length
          ? planPrice[0].amount * planPrice[0].plan_filter_id
          : 0;
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: "transaction",
          ecommerce: {
            purchase: {
              actionField: {
                id: this.company.subscription.base_subscription_id, // Transaction ID. Required
                revenue: this.company.subscription.base_subscription_id, // Total transaction value (incl. tax and shipping)
              },
              products: [
                {
                  // List of productFieldObjects.
                  name: response.data.plan.name, // Name or ID is required.
                  id: response.data.plan.product_id,
                  price: amout,
                  //'category': 'Shirts',
                  //'variant': 'Gray',
                  quantity: 1,
                },
              ], //expand this array if more product exists
            },
          },
        });
      }

      this.$router.go();
    },
    checkBackgroundImageAccess() {
      let eligible = this.$checkEligiblity("board_backgrounds");
      if (eligible) {
        this.changeBackgroundPopup = true;
      }
    },
    closeNoTaskDialoge() {
      this.isTaskFound = false;
    },
    removeBanner() {
      this.$store.dispatch("toggleBoardBanner", false);
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    videoPlayer() {
      let media = {
        isVimeo: true,
        file: "https://player.vimeo.com/video/568790527",
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    closePlanExpiredDialog() {
      this.showChangeBackground = false;
      this.changeBackgroundPopup = true;
    },
    async updateBoard() {
      const variables = {
        id: this.currentWorkspace.boards[0].id,
      };

      await this.$api.query({
        query: BoardQuery,
        variables,
        cacheKey: `BoardQuery:${this.currentWorkspace.boards[0].id}`,
      });

      await this.$api.query({
        query: NotificationsQuery,
        cacheKey: "NotificationsQuery",
      });
    },
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },
    getEncoded(url) {
      return encodeURI(url);
    },
    async openTask(taskId) {
      const task = this.$api.getEntity("task", taskId);
      let taskData = null;
      if (task) {
        taskData = task;
      } else {
        let taskQueryData = await this.$api.query({
          query: TaskQuery,
          variables: {
            id: taskId,
          },
        });
        taskData = taskQueryData.data.task;
      }

      if (taskData) {
        let isOpen = null;
        let task = taskData;
        let taskDetails = {
          task,
          isOpen,
        };
        this.editTask(taskDetails);
        this.taskDialog = true;
      } else {
        this.isTaskFound = true;
        this.$router.replace({ query: null });
      }
    },
    async openSubtaskDialog(
      taskId,
      subtaskId,
      taskData = null,
      subtask = null
    ) {
      if (taskData) {
        this.currentTask = taskData;
      } else {
        let taskQueryData = await this.$api.query({
          query: TaskQuery,
          variables: {
            id: taskId,
          },
        });

        const taskEntity = taskQueryData.data.task;
        if (taskEntity) {
          this.currentTask = taskEntity;
        }
      }

      let subtaskEntity = null;
      if (subtask) {
        subtaskEntity = subtask;
      } else {
        let subtaskQueryData = await this.$api.query({
          query: SubtaskQuery,
          variables: {
            id: subtaskId,
          },
        });

        subtaskEntity = subtaskQueryData.data.subtask;
      }

      if (subtaskEntity) {
        this.closeTaskDialog();
        this.subTaskDialog = true;
        this.currentSubTask = subtaskEntity;
      } else {
        this.isTaskFound = true;
        this.$router.replace({ query: null });
      }
    },
    // async getImagesFromFolder() {
    //   const response = this.$api.getQuery("CompanyBackgroundsQuery");
    //   console.log("response", response);
    //   response.data.companyBackgrounds.map((background) => {
    //     background["isImage"] = true;
    //     this.backgroundList.push(background);
    //   });
    // },
    async closeDialog() {
      if (!isEmpty(this.$route.query)) {
        this.$router.replace({ query: null });
      }
      this.isNewTask = false;
      this.openSubtask = false;
      this.$eventBus.$emit("closeTaskDialog");
      if (this.shouldDeleteTask) {
        const variables = {
          id: this.task.id,
          post_id: this.selectedMessageId,
        };
        const privateTasksQuery = this.$api.getQuery(
          `PrivateTasksQuery:${this.board.id}`
        );
        privateTasksQuery.data.privateTasks =
          privateTasksQuery.data.privateTasks.filter(
            (t) => t.id !== this.task.id
          );
        const postEntity = this.$api.getEntity("post", this.selectedMessageId);
        postEntity.is_task = false;
        await this.$api.mutate({
          mutation: DeleteTaskMutation,
          variables,
        });
      }
      this.updateTasksList();
    },
    onDragStart() {
      document.body.classList.add("dragging");
    },
    onDragEnd(event) {
      document.body.classList.remove("dragging");

      const { item, oldIndex, newIndex } = event;
      const card = this.$api.getEntity("card", item.dataset.id);
      const tmp = [...this.boardCards];

      // move item from oldIndex to newIndex
      tmp.splice(newIndex, 0, tmp.splice(oldIndex, 1)[0]);

      card.sequence = RandomPosition.between(
        tmp[newIndex - 1] ? tmp[newIndex - 1].sequence : RandomPosition.first(),
        tmp[newIndex + 1] ? tmp[newIndex + 1].sequence : RandomPosition.last()
      );

      this.$api.mutate({
        mutation: UpdateCardMutation,
        skipUpdates: true,
        variables: {
          id: card.id,
          sequence: card.sequence,
        },
      });
      mixpanel.track("Card Move");
    },
    async addTask(task, cb) {
      const cardTasks = this.cardTasks[task.card ? task.card.id : task.card_id];
      const activeTasks = cardTasks
        ? cardTasks.filter((task) => !task.completed)
        : cardTasks;
      const activeTasksOrdered = activeTasks
        ? orderBy(activeTasks, ["sequence"], ["asc"])
        : activeTasks;
      let sequence;
      if (activeTasksOrdered === undefined) {
        if (task.sequencePosition === "top") {
          sequence = activeTasksOrdered
            ? RandomPosition.between(
                RandomPosition.first(),
                head(activeTasksOrdered).sequence
              )
            : RandomPosition.between(
                RandomPosition.first(),
                RandomPosition.last()
              );
        } else {
          sequence = activeTasksOrdered
            ? RandomPosition.between(
                last(activeTasksOrdered).sequence,
                RandomPosition.last()
              )
            : RandomPosition.between(
                RandomPosition.first(),
                RandomPosition.last()
              );
        }
      } else {
        if (task.sequencePosition === "top") {
          sequence =
            activeTasksOrdered.length > 0
              ? RandomPosition.between(
                  RandomPosition.first(),
                  head(activeTasksOrdered).sequence
                )
              : RandomPosition.between(
                  RandomPosition.first(),
                  RandomPosition.last()
                );
        } else {
          sequence =
            activeTasksOrdered.length > 0
              ? RandomPosition.between(
                  last(activeTasksOrdered).sequence,
                  RandomPosition.last()
                )
              : RandomPosition.between(
                  RandomPosition.first(),
                  RandomPosition.last()
                );
        }
      }
      const variables = {
        ...task,
        sequence,
        card_id: task.card_id,
        board_id: this.board.id,
      };
      const taskOwner = pick(this.user, [
        "id",
        "first",
        "last",
        "pic",
        "username",
      ]);
      setTimeout(() => {
        cb();
      }, 100);

      this.selectedCard = task.card;

      const fakeTask = {
        __typename: "task",
        id: getUnixTime(new Date()),
        title: task.title,
        isbookmarked: false,
        details: task.details,
        completed: false,
        end_date: null,
        sequence: sequence,
        card_id: task.card_id,
        last_label: "notstarted",
        members: [],
        rating: 0,
        owner: {
          ...taskOwner,
          __typename: "user",
        },
        labels: [],
        subtasks: [],
        activetimelog: null,
        timelogs: [],
      };

      // 1. get query
      // 2. add fake entity

      let boardQuery;
      if (this.isNewTask) {
        boardQuery = this.$api.getQuery(`PrivateTasksQuery:${this.board.id}`);
        boardQuery.data.privateTasks.push(fakeTask);
      } else {
        boardQuery = this.$api.getQuery(`BoardQuery:${this.board.id}`);
        boardQuery.data.board.tasks.push(fakeTask);
      }

      // 3. get real entity
      const response = await this.$api.mutate({
        mutation: CreateTaskMutation,
        variables,
      });

      // 4. replace fake entity
      if (this.isNewTask) {
        boardQuery.data.privateTasks = boardQuery.data.privateTasks.map(
          (task) => (task === fakeTask ? response.data.createTask : task)
        );
      } else {
        boardQuery.data.board.tasks = boardQuery.data.board.tasks.map((task) =>
          task === fakeTask ? response.data.createTask : task
        );
      }

      if (this.isNewTask) {
        let taskDetails = {
          task: response.data.createTask,
          isOpen: null,
        };
        this.editTask(taskDetails);
      }
      mixpanel.track("Task Create");
    },
    openCreateTaskDialog() {
      this.addTaskSection = true;
      // this.isNewTask = true;
      // this.addTask(task, () => {});
    },
    cardSequenceGenerator(index, futureIndex) {
      let newIndex;
      if (futureIndex === 0) {
        newIndex = RandomPosition.between(
          RandomPosition.first(),
          this.boardCards[0].sequence
        );
        return newIndex;
      } else if (futureIndex === findLastIndex(this.boardCards)) {
        newIndex = RandomPosition.between(
          last(this.boardCards).sequence,
          RandomPosition.last()
        );
        return newIndex;
      } else if (
        futureIndex < index &&
        futureIndex !== 0 &&
        futureIndex !== findLastIndex(this.boardCards)
      ) {
        let ref = futureIndex - 1;
        newIndex = RandomPosition.between(
          this.boardCards[ref].sequence,
          this.boardCards[futureIndex].sequence
        );
        return newIndex;
      } else if (
        futureIndex > index &&
        futureIndex !== 0 &&
        futureIndex !== findLastIndex(this.boardCards)
      ) {
        let ref = futureIndex + 1;
        newIndex = RandomPosition.between(
          this.boardCards[futureIndex].sequence,
          this.boardCards[ref].sequence
        );
        return newIndex;
      }
    },
    addCard(card) {
      if (card) {
        let newIndex;
        const currentIndex = this.boardCards.findIndex((a) => a.id === card.id);
        if (currentIndex === 0) {
          newIndex = this.cardSequenceGenerator(2, 1);
        } else {
          newIndex = this.cardSequenceGenerator(currentIndex - 1, currentIndex);
        }
        const cardData = {
          title: "New Column",
          board_id: this.board.id,
          workspace_id: this.currentWorkspaceId,
          sequence: newIndex,
        };
        this.createCard(cardData);
      } else {
        const sequence =
          this.boardCards && this.boardCards.length > 0
            ? RandomPosition.between(
                last(this.boardCards).sequence,
                RandomPosition.last()
              )
            : RandomPosition.between(
                RandomPosition.first(),
                RandomPosition.last()
              );
        const card = {
          title: this.card_title ? this.card_title : "New Column",
          board_id: this.board.id,
          workspace_id: this.currentWorkspaceId,
          sequence,
        };
        this.createCard(card);
        this.card_title = null;
      }
    },
    async createCard(card) {

      const cardOwner = pick(this.user, [
        "id",
        "first",
        "last",
        "pic",
        "username",
      ]);
      
      const fakeCard = {
        __typename: "card",
        id: Math.round(Math.random() * -1000000),
        title: card.title,
        board_id: card.board_id,
        user_id: this.user.id,
        sequence: card.sequence,
        is_lock: 0,
        is_invisible: 0,
        owner: {
          ...cardOwner,
          __typename: "user",
        },
      };

      // 1. get query
      const boardQuery = this.$api.getQuery(`BoardQuery:${this.board.id}`);

      // 2. add fake entity
      boardQuery.data.board.cards.unshift(fakeCard);

      // 3. get real entity
      const response = await this.$api.mutate({
        mutation: CreateCardMutation,
        variables: card,
      });

      // 4. replace fake entity
      boardQuery.data.board.cards = boardQuery.data.board.cards.map((card) =>
        card === fakeCard ? response.data.createCard : card
      );
      
      setTimeout(() => {
        const card = this.$refs.card_list_item;
        const board = this.$refs.board_card_list;
        if (board) {
          board.scrollTo({
            left: card.getBoundingClientRect().right,
            behavior: 'smooth'
          });
        }
      }, 500);

      mixpanel.track("Card Create");
    },
    async updateCardHandler(card) {
      const hasId = this.boardCards.find((c) => c.id === card.id);
      const variables = card;
      if (hasId) {
        this.$api.updateEntity("card", card.id, card);

        await this.$api.mutate({
          mutation: UpdateCardMutation,
          variables,
        });

        if (this.board.tasks) {
          this.board.tasks.forEach((element, index) => {
            if (element.card_id == card.id) {
              this.board.tasks[index].is_lock = card.is_lock;
              this.board.tasks[index].is_invisible = card.is_invisible;
            }
          });
        }
        mixpanel.track("Card Update");
      }
    },
    async deleteCardHandler(card) {
      const variables = {
        id: card.id,
      };
      const boardQuery = this.$api.getQuery(`BoardQuery:${this.board.id}`);

      boardQuery.data.board.cards = boardQuery.data.board.cards.filter(
        (c) => c.id !== card.id
      );

      await this.$api.mutate({
        mutation: DeleteCardMutation,
        variables,
      });
      mixpanel.track("Card Delete");
    },
    clearActiveTaskFromColumn(card_id) {
      const ids = this.board.tasks
        .filter((o) => o.card_id === card_id && !o.completed)
        .map((a) => a.id);
      if (ids && ids.length) {
        this.deleteTaskByIdHandler(ids);
      }
    },
    clearCompletedTaskFromColumn(card_id) {
      const ids = this.board.tasks
        .filter((o) => o.card_id === card_id && o.completed)
        .map((a) => a.id);
      if (ids && ids.length) {
        this.deleteTaskByIdHandler(ids);
      }
    },
    async changeBoardBackground(backgroundData, isImage=false) {
      
      const variables = {};
      this.showChangeBackground = false;
      console.log("backgroundData", backgroundData);
      if (isImage) {
        if (
          this.currentCompany.status &&
          this.currentCompany.status.subscriptionOver
        ) {
          this.changeBackgroundPopup = false;
          this.showChangeBackground = true;
          return true;
        }
        if (this.taskView === "board") {
          variables["gradient"] = null;
          variables["bgimg"] = backgroundData.file;
        } else {
          variables["gradient_list"] = null;
          variables["bgimg_list"] = backgroundData.file;
        }
      } else {
        if (this.taskView === "board") {
          variables["gradient"] = backgroundData.background;
          variables["bgimg"] = null;
        } else {
          variables["gradient_list"] = backgroundData.background;
          variables["bgimg_list"] = null;
        }
      }

      //this.$api.updateEntity("board", this.board.id, variables);
      if(this.board && this.board.background) {
        variables['id'] = this.board.background.id;

        const response = await this.$api.mutate({
          mutation: UpdateBoardBackgroundMutation,
          variables,
          skipUpdates: true,
        });
        this.$mixpanelEvent("background-change", {});
        if (response.data) {
          const boardEntity = this.$api.getEntity("board", this.board.id);
          boardEntity.background = response.data.updateBoardBackground;
        }
      } else {

        variables['board_id'] = this.board.id;
        variables['workspace_id'] = this.currentWorkspaceId;

        const response = await this.$api.mutate({
          mutation: CreateBoardBackgroundMutation,
          variables,
          skipUpdates: true,
        });
        this.$mixpanelEvent("background-change", {});
        if (response.data) {
          const boardEntity = this.$api.getEntity("board", this.board.id);
          boardEntity.background = response.data.createBoardBackground;
        }
      }
    },
    updateTasksList() {
      if (!this.task) {
        return false;
      }
      // remove task from PrivateTasksQuery and add it to BoardQuery
      const boardQuery = this.$api.getQuery(`BoardQuery:${this.board.id}`);
      const privateTasksQuery = this.$api.getQuery(
        `PrivateTasksQuery:${this.board.id}`
      );
      const taskEntity = this.$api.getEntity("task", this.task.id);
      const updatedTask = {
        ...taskEntity,
      };
      boardQuery.data.board.tasks.push(updatedTask);
      privateTasksQuery.data.privateTasks =
        privateTasksQuery.data.privateTasks.filter(
          (t) => t.id !== this.task.id
        );
      this.shouldDeleteTask = false;
      this.resetTaskDialog();
    },
    updateTimelineChart() {
      if (this.taskView === "timeline") {
        this.$refs.gantt_chart_view.$refs.gantt.refresh(
          this.$refs.gantt_chart_view.getTasksList
        );
        this.$refs.gantt_chart_view.scrollToPosition();
      }
    },
    async closeTaskDialog() {
      if (this.isNewTask) {
        const variables = {
          id: this.task.id,
        };
        const privateTasksQuery = this.$api.getQuery(
          `PrivateTasksQuery:${this.board.id}`
        );
        privateTasksQuery.data.privateTasks =
          privateTasksQuery.data.privateTasks.filter(
            (t) => t.id !== this.task.id
          );
        await this.$api.mutate({
          mutation: DeleteTaskMutation,
          variables,
        });
        const query = this.$api.getQuery(`BoardQuery:${this.board.id}`);
        query.data.board.tasks = query.data.board.tasks.filter(
          (task) => task.id !== this.task.id
        );
      }
      this.resetTaskDialog();
    },
  },
  computed: {
    ...mapGetters({
      isBoardBannerVisible: "isBoardBannerVisible",
    }),
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    isWorkspaceOwner() {
      if (this.currentWorkspace && this.user) {
        return this.user.id === this.currentWorkspace.owner.id;
      }
      return false;
    },
    dueDateFilterMode() {
      return this.boardFilterObj.duedate;
    },
    boardCards: {
      get() {
        if (this.board) {
          let cardLists = orderBy(uniqBy(this.board.cards.filter((c) => ((c.user_id === this.user.id && c.is_invisible) || !c.is_invisible)), "id"), ["sequence"], ["asc"]);
          return cardLists;
        }
        return null;
      },
    },
    tasksList() {
      // Filter
      let tasks;
      if (this.boardFilterObj.taskFliter.filter) {
        if (
          this.boardFilterObj.taskFliter.label &&
          this.boardFilterObj.taskFliter.label.length
        ) {
          const filterids = this.boardFilterObj.taskFliter.label.map(
            (a) => a.id
          );
          tasks = this.board.tasks.filter((o) => {
            for (let i = 0; i < filterids.length; i++) {
              const flag = o.labels.map((l) => l.id).includes(filterids[i]);
              if (flag) return flag;
            }
          });
          // tasks = this.board.tasks.filter((o) =>
          //   o.labels
          //     .map((l) => l.id)
          //     .includes(this.boardFilterObj.taskFliter.label.id)
          // );
        }
        if (this.boardFilterObj.taskFliter.bookmarkFlag) {
          tasks = this.board.tasks.filter((o) => o.isbookmarked === true);
        }
      } else if (
        this.boardFilterObj.filteredMember &&
        this.boardFilterObj.filteredMember.length > 0
      ) {
        const filterids = this.boardFilterObj.filteredMember.map((a) => a.id);
        tasks = this.board.tasks.filter((o) => {
          for (let i = 0; i < filterids.length; i++) {
            const flag = o.members.map((m) => m.id).includes(filterids[i]);
            if (flag) return flag;
          }
        });
      } else if (this.boardFilterObj.myTaskFilter) {
        // Member my task
        tasks = this.board.tasks.filter((o) => {
          return o.members.map((m) => m.id).includes(this.user.id);
        });
      } else if (this.boardFilterObj.searchText !== null) {
        if (
          this.boardSearchOptions.includeTaskName &&
          this.boardSearchOptions.includeCustomField
        ) {
          //both task name and custom field search filter
          const taskNameTasks = this.board.tasks.filter(
            (o) =>
              (o.title &&
                o.title
                  .toLowerCase()
                  .includes(this.boardFilterObj.searchText.toLowerCase())) ||
              o.id
                .toString()
                .toLowerCase()
                .includes(this.boardFilterObj.searchText.toLowerCase())
          );
          const customFieldTasks = this.board.tasks.filter((o) => {
            const flag = o.customFieldOptionSelection.find((m) =>
              m.option_text
                .toLowerCase()
                .includes(this.boardFilterObj.searchText.toLowerCase())
            );
            if (flag) return flag;
          });
          tasks = [...taskNameTasks, ...customFieldTasks];
        } else if (this.boardSearchOptions.includeTaskName) {
          //Only task name search filter
          tasks = this.board.tasks.filter(
            (o) =>
              (o.title &&
                o.title
                  .toLowerCase()
                  .includes(this.boardFilterObj.searchText.toLowerCase())) ||
              o.id
                .toString()
                .toLowerCase()
                .includes(this.boardFilterObj.searchText.toLowerCase())
          );
        } else {
          //Only custom field search filter
          tasks = this.board.tasks.filter((o) => {
            const flag = o.customFieldOptionSelection.find((m) =>
              m.option_text
                .toLowerCase()
                .includes(this.boardFilterObj.searchText.toLowerCase())
            );
            if (flag) return flag;
          });
        }
      } else {
        tasks = this.board && this.board.tasks;
      }
      tasks = tasks.filter((c) => ((c.owner.id === this.user.id && c.is_invisible) || !c.is_invisible || c.members.map((m) => m.id).includes(this.user.id)));
      return uniqBy(tasks, "id");
    },
    cardTasks: {
      get() {
        return groupBy(
          orderBy(
            this.tasksList.filter((t) => t.title !== ""),
            ["sequence"]
          ),
          "card_id"
        );
      },
    },
    boardBackgrounds: {
      get() {
        return this.companyBackgrounds;
      },
    },
    gradient: {
      get() {
        if(this.board && this.board.background) {
          switch (this.taskView) {
            case "board":
              return this.board.background.gradient;
            case "list":
              return this.board.background.gradient_list;
          }
        } else if (this.board && (this.board.gradient || this.board.gradient_list)) {
          switch (this.taskView) {
            case "board":
              return this.board.gradient;
            case "list":
              return this.board.gradient_list;
          }
        }
        return "#F9FAFC";
      },
      set(color) {
        this.board.gradient = color;
        return color;
      },
    },
    backgroundImage: {
      get() {
        if(this.board && this.board.background) {
          switch (this.taskView) {
            case "board":
              return this.board.background.bgimg;
            case "list":
              return this.board.background.bgimg_list;
          }
        } else if (this.board && (this.board.bgimg || this.board.bgimg_list)) {
          switch (this.taskView) {
            case "board":
              return this.board.bgimg;
            case "list":
              return this.board.bgimg_list;
          }
        }
        return this.board && this.board.bgimg;
      },
    },
    cardList() {
      return this.currentWorkspace.boards[0].cards;
    },
    columnFiledList() {
      return (
        this.workspaceFields &&
        this.workspaceFields.filter((field) => {
          if (!field.customField) {
            return field;
          } else if (field.customField.is_active) {
            return field;
          }
        })
      );
    },
  },
  watch: {
    board() {
      this.boardLoader = false;
    },
    $route(to, from) {
      if (to.params.workspace !== from.params.workspace) {
        this.boardLoader = true;
      }
    },
    onLine(v) {
      if (v) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.updateBoard();
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss">
.field-filter {
  background-color: #dbecff;
  color: #007aff;
  font-family: "Avenir Next Demi";
  font-weight: 500;
  /* height: 25px; */
}
.field-filter:hover {
  color: white !important;
  background-color: #007aff !important;
  font-family: "Avenir Next Demi";
}
.field-filter .q-btn__content {
  flex-wrap: nowrap;
}
.field-filter .q-btn__wrapper {
  padding: 0px 5px 0px 10px;
  min-height: 0;
  font-size: 12px;
}
.change-background-button {
  bottom: 20px;
  left: 15px;
  color: #5a728d;
  background: #ffffff;
  z-index: 999;
  position: absolute;
}
.change-background-button:hover {
  background-color: #cee6ff;
  color: #1f89fc;
}

.change-background-menu {
  height: 296px;
  width: 916px;
}
.change-background-menu .toolbar {
  height: 50px;
}
.change-background-menu .toolbar .title {
  font-family: "Avenir Next" !important;
  font-size: 16px !important;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 7px !important;
}
.tiles-list {
  width: 100%;
  height: 230px;
  overflow: auto;
  padding: 0 12px;
}
.tiles-list .tile {
  width: 95px;
  height: 61px;
  margin: 7px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-new-task-doc {
  position: absolute;
  bottom: 20px;
  right: 25px;
  .btn {
    height: 35px;
    width: 85px;
    color: white;
    border-radius: 20px;
    transition: 0.5s all ease-in-out;
    z-index: 100;
    &:hover {
      transform: scale(1.2);
      transition: 0.5s all ease-in-out;
    }
    &.task {
      background: #007aff;
    }
    &.doc {
      background: #15d89a;
    }
  }
  .btn-icon {
  }
  .btn-lbl {
    font-size: 14px;
    font-weight: bold;
  }
}
.fab-btn {
  position: absolute;
  bottom: 20px;
  right: 25px;
  width: 45px;
  height: 45px;
  background: #007aff;
  border-radius: 12px;
  // box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
  border: 3px solid #dbecff;
  z-index: 999;
}
.reload-dialog {
  padding: 20px 50px;
  text-align: center;
  background: #f4f5f8;
  border-radius: 5px;
  .title {
    span {
      font-size: 15px;
    }
  }
}

html {
  background-color: #FFF;
}

.container { 
 background-color: #F5F5F5;
 margin-left: 10px;
 -webkit-box-shadow: 0px 1px 32px 4px rgba(0,0,0,0.16);
 -moz-box-shadow: 0px 1px 32px 4px rgba(0,0,0,0.16);
 box-shadow: 0px 1px 32px 4px rgba(0,0,0,0.16);
  overflow-y: scroll;
  overflow-x: hidden;
}

.container::-webkit-scrollbar {
    width: 0;
}
 
.container::-webkit-scrollbar-thumb {
  background-color: #e4e4e4;
}


.container::-webkit-scrollbar-track {
    background-color: #F5F5F5;
}

.container::-webkit-scrollbar-corner {
  background-color: #F5F5F5;
  display: none;
}

.card1 {
  width: 328px;
  height: 208px;
  margin: 16px auto;
  background-color: #ffffff;
  border-radius: 2px;
  -webkit-box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.08);
  -moz-box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.08);
  box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.08);
}

.element1 {
    top: 10px;
    left: 25px;
    height: 20px;
    width: 220px;
}
.element2 {
    top: 16px;
    left: 50px;
    height: 50px;
    width: 160px;
}

.element3 {
    top: 25px;
    left: 10px;
    height: 14px;
    width: 250px;
}

.element4 {
    top: 68px;
    // left: 16px;
    height: 14px;
    width: 296px;
}

.gradient {
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
    background-size: 1000px 640px;
    
    position: relative;
    
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
</style>
