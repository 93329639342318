<template>
  <div class="hide-expansion-hover card-list-item-toggle-arrow q-mb-md">
    <q-expansion-item
      switch-toggle-side
      expand-icon-toggle
      :key="card.id"
      v-model="expansinoState"
    >
      <template v-slot:header>
        <div class="row items-center card-list-item-title flex-1 group-hover">
          <div class="row">
            <div
              :class="[
                editorFocused && 'flex-1',
                'row items-center flex-no-wrap',
              ]"
            >
              <div
                v-if="!editorFocused"
                @click.stop="editorFocused = true"
                class="q-ml-sm board-cardtitle-ce text-h6"
                :style="{
                  color:
                    card.color && card.color !== '#172b4d'
                      ? card.color
                      : '#172b4d',
                }"
              >
                {{
                  activeCount > 0 ? card.title + " " + activeCount : card.title
                }}
              </div>
              <q-input
                v-else
                dense
                padding="0"
                outlined
                :value="card.title"
                @change="updateCardTitle"
                class="board-cardtitle-ce text-h6 q-ml-sm"
                bg-color="white"
                input-class="text-h6"
                @blur="editorFocused = false"
                @keydown.enter.exact="updateCardTitle"
                @click.stop
                autofocus
              />
            </div>
          </div>
          <div
            class="q-ml-sm tasks-status row flex-no-wrap items-center group-hover-item"
          >
            <div
              :class="[visibility === 'active' ? 'text-primary' : 'text-black']"
              @click="visibility = 'active'"
              class="q-px-xs cursor-pointer active-task"
            >
              {{ activeCount }} Active
            </div>
            <div
              v-if="completedCount"
              :class="[
                visibility === 'completed' ? 'text-primary' : 'text-black',
              ]"
              @click="visibility = 'completed'"
              class="q-px-xs cursor-pointer completed-task"
            >
              {{ completedCount }} Done
            </div>
            <q-btn
              @click.stop
              flat
              :icon="$icons.matMoreHoriz"
              size="md"
              padding="0"
              round
              v-if="isVisible([1, 2, 3])"
            >
              <q-tooltip> More </q-tooltip>
              <q-menu content-class="overflow-auto">
                <q-list>
                  <q-item clickable>
                    <q-item-section> Change list color </q-item-section>
                    <q-item-section side>
                      <q-icon :name="$icons.matKeyboardArrowRight" />
                    </q-item-section>
                    <q-menu
                      anchor="top end"
                      self="center start"
                      max-height="auto"
                      content-class="list-color-swatch-container row q-py-md q-px-md"
                      auto-close
                    >
                      <div
                        v-for="data in swatchColors"
                        :key="'swathc-' + data.id"
                        class="row flex-no-wrap relative-position"
                        @click="updateListColor(data.colorcode)"
                      >
                        <div
                          :class="[
                            data.isWhite && 'white-box',
                            'swatch-box cursor-pointer',
                            isSwatchSelected(data.colorcode)
                              ? 'selected'
                              : 'not-selected',
                          ]"
                          :style="{
                            'background-color': data.colorcode,
                          }"
                        ></div>
                        <q-icon
                          v-if="isSwatchSelected(data.colorcode)"
                          :name="$icons.fasCheck"
                          color="white"
                          size="15px"
                          class="swatch-selected-check"
                        />
                      </div>
                    </q-menu>
                  </q-item>
                  <q-separator />
                  <q-item
                    clickable
                    v-if="isVisible([1, 2]) || isWorkspaceOwner"
                    @click="moveCard.flag = true"
                    v-close-popup
                  >
                    <q-item-section>Move column</q-item-section>
                  </q-item>
                  <q-item
                    clickable
                    v-if="isVisible([1, 2]) || isWorkspaceOwner"
                    @click="duplicateCard.flag = true"
                    v-close-popup
                  >
                    <q-item-section>Duplicate column</q-item-section>
                  </q-item>
                  <q-item
                    clickable
                    @click="clearCompletedTaskConfirmBoxHandler"
                    v-if="isVisible([1, 2]) || isWorkspaceOwner"
                    v-close-popup
                  >
                    <q-item-section> Clear completed Tasks </q-item-section>
                  </q-item>
                  <q-item
                    clickable
                    @click="clearActiveTaskConfirmBoxHandler"
                    v-if="isVisible([1, 2]) || isWorkspaceOwner"
                    v-close-popup
                  >
                    <q-item-section> Clear active Tasks </q-item-section>
                  </q-item>
                  <q-item
                    clickable
                    @click="deleteCardDeleteConfirmBoxHandler"
                    class="text-red"
                    v-if="isVisible([1, 2]) || isWorkspaceOwner"
                    v-close-popup
                  >
                    <q-item-section> Delete entire list </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </div>
        </div>
      </template>
      <div class="q-pl-md">
        <div
          v-sortable="{
            group: 'task',
            onStart: onDragStart,
            onEnd: onDragEnd,
            onAdd: onDragAddOrUpdate,
            onUpdate: onDragAddOrUpdate,
            emptyInsertThreshold: 100,
          }"
        >
          <div
            class="q-mb-xs"
            v-for="task in filteredTodos"
            :key="task.id"
            :data-id="task.id"
          >
            <tr
              v-if="task"
              style="min-width: 1020px"
              class="show-on-drag task-list-item row flex-no-wrap items-center group-hover relative-position q-pr-sm"
            >
              <td
                :class="[
                  'row task-column-item items-center q-pl-sm task-column-item-titel',
                ]"
                style="min-width: 335px; max-width: 335px; width: 100%"
              >
                <div style="z-index: 1" class="q-mr-sm">
                  <div v-if="task.completed" class="cursor-pointer">
                    <div class="row items-center flex-no-wrap">
                      <div class="task-icon relative-position">
                        <q-icon
                          class="absolute text-bold"
                          :name="$icons.fasCheck"
                          style="color: #15d89a"
                          size="15px"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="cursor-pointer notification-sidebar-outline-dot"
                  ></div>
                </div>
                <div
                  class="flex-1 cursor-pointer ellipses q-mr-xs"
                  :class="[task.completed && 'strike']"
                  :id="'listItem' + task.id"
                >
                  <span class="task-ticket-number q-mr-xs">
                    T{{ workspace.title.substring(0, 2).toUpperCase() }}-{{
                      task.id
                    }}
                  </span>
                  <span
                    class="dont-break-out an-15 medium-text"
                    :id="'listItem_title' + task.id"
                  >
                    {{ task.title }}
                  </span>
                </div>
              </td>
            </tr>
            <task-list-item
              :taskdata="task"
              :labels="labels"
              @delete="deleteTask"
              :board="board"
              :boardId="board.id"
              :workspace="workspace"
              :card="card"
              place="board"
              :workspaceMembers="workspaceMembers"
              :editTaskDialogHandler="editTaskDialogHandler"
              :onUpdateTaskMembersHandler="onUpdateTaskMembersHandler"
              :columnFiledList="columnFiledList"
            />
          </div>
        </div>
        <div>
          <new-task-singleline
            :card="card"
            :closeAddingTask="() => onBlurCloseAddingTask('addTaskSection')"
            ref="addInlineTaskSection"
            sequence-position="top"
            v-if="addTaskSection"
            @cancel="resetOnlineTaskHalder"
            :addTaskEventHandler="(data) => addTaskEventMyHandler(data)"
          ></new-task-singleline>
          <!-- <div
            @click="
              () => {
                addTaskSection = true;
              }
            "
            v-if="canPostMessageFromWorkspace"
            class="add-task-area text-center row justify-center items-center cursor-pointer q-mt-sm"
            style="height:42px"
          >
            <q-icon :name="$icons.matAdd" style="color:42526E" />
            <div class="q-ml-sm">Add task</div>
          </div> -->
        </div>
        <!-- <div
          @click="
            () => {
              addTaskSection = true;
            }
          "
          class="add-task-area text-center row justify-center items-center cursor-pointer"
          v-else-if="!filteredTodos"
        >
          <q-icon :name="$icons.matAdd" style="color:42526E" />
          <div class="q-ml-sm">Add task</div>
        </div> -->
      </div>
    </q-expansion-item>
    <confirm-dialog
      v-if="cardRemoveConfirm.flag"
      v-model="cardRemoveConfirm.flag"
      title="Delete Column?"
      question="Deleting this column will also delete all the tasks in it."
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="resetCardDeleteConfirmBox"
      :successMethod="deleteCard"
      :loading="false"
    />
    <!--Clear Complete Task Dialog -->
    <confirm-dialog
      v-if="clearCompletedTaskConfirm.flag"
      v-model="clearCompletedTaskConfirm.flag"
      title="Delete Completed Tasks?"
      question="Are you sure you want to delete all completed tasks?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="resetClearCompletedTaskConfirmBox"
      :successMethod="clearCompletedTaskFromColumn"
      :loading="false"
    />

    <!--Clear Active Task Dialog -->
    <confirm-dialog
      v-if="clearActiveTaskConfirm.flag"
      v-model="clearActiveTaskConfirm.flag"
      title="Delete Active Tasks?"
      question="Are you sure you want to delete all active tasks?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="resetClearActiveTaskConfirmBox"
      :successMethod="clearActiveTaskFromColumn"
      :loading="false"
    />

    <move-card-dialog
      v-if="moveCard.flag"
      v-model="moveCard.flag"
      :tasks="tasks"
      :card="card"
      :board="board"
      title="list"
      :closeDialog="closeMoveCardDialog"
    />
    <duplicate-card-dialog
      v-if="duplicateCard.flag"
      v-model="duplicateCard.flag"
      :card="card"
      :board="board"
      title="list"
      :closeDialog="closeDuplicateCardDialog"
    />
  </div>
</template>
<script>
import RandomPosition from "random-position";
import mixpanel from "@/mixpanel";
import UserScope from "@/mixins/UserScope";

import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";

import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import NewTaskSingleline from "@/views/BoardView/NewTaskSingleline";
import TaskListItem from "@/views/BoardView/TaskListItem.vue";
import { swatchColors } from "@/utils/Contents";
import MoveCardDialog from "@/components/Dialogs/MoveCardDialog.vue";
import DuplicateCardDialog from "@/components/Dialogs/DuplicateCardDialog.vue";

import { UpdateTaskMutation, UpdateCardColorMutation } from "@/gql";

export default {
  name: "CardListItem",
  mixins: [UserScope],
  props: [
    "card",
    "board",
    "labels",
    "workspaceMembers",
    "tasks",
    "workspace",
    "editTaskDialogHandler",
    "addTaskEventHandler",
    "onUpdateTaskMembersHandler",
    "addNewColumnFromColumn",
    "dueDateFilterMode",
    "columnFiledList",
  ],
  components: {
    ConfirmDialog,
    NewTaskSingleline,
    TaskListItem,
    MoveCardDialog,
    DuplicateCardDialog,
  },
  mounted() {
    this.expansinoState = this.showCardToggle;
  },
  created() {
    if (this.card) {
      const descFlag = this.$store.getters.showCard(this.card.id);
      if (!descFlag) {
        this.$store.dispatch("setCardToggleState", {
          cardId: this.card.id,
          flag: true,
        });
      }
    }
  },
  data() {
    return {
      expansinoState: false,
      cardRemoveConfirm: {
        flag: false,
        id: null,
        loader: false,
      },
      clearCompletedTaskConfirm: {
        flag: false,
        id: null,
        loader: false,
      },
      clearActiveTaskConfirm: {
        flag: false,
        id: null,
        loader: false,
      },
      addingTask: false,
      addTaskSection: false,
      editorFocused: false,
      visibility: "active",
      swatchColors,
      moveCard: {
        flag: false,
      },
      duplicateCard: {
        flag: false,
      },
    };
  },
  methods: {
    setCardToggleState() {
      this.$store.dispatch("setCardToggleState", {
        cardId: this.card.id,
        flag: !this.showCardToggle,
      });
    },
    closeMoveCardDialog() {
      this.moveCard.flag = false;
    },
    closeDuplicateCardDialog() {
      this.duplicateCard.flag = false;
    },
    async updateListColor(color) {
      this.card.color = color;
      await this.$api.mutate({
        mutation: UpdateCardColorMutation,
        variables: {
          card_id: this.card.id,
          color,
        },
      });
    },
    isSwatchSelected(color) {
      return (
        this.card.color && this.card.color.toLowerCase() === color.toLowerCase()
      );
    },
    active(todos) {
      if (todos) {
        return todos.filter((todo) => !todo.completed);
      } else {
        return [];
      }
    },
    completed(todos) {
      if (todos) {
        let complated = todos.filter((todo) => todo.completed);
        if (!complated.length) {
          this.visibility = "active";
        }
        return complated;
      } else {
        return [];
      }
    },
    onDragStart() {
      document.body.classList.add("dragging");
    },
    onDragEnd() {
      document.body.classList.remove("dragging");
    },
    onDragAddOrUpdate(event) {
      const { item, oldIndex, newIndex } = event;
      const task = this.$api.getEntity("task", item.dataset.id);
      const tmp = this.filteredTodos ? [...this.filteredTodos] : [];

      if (event.from === event.to) tmp.splice(oldIndex, 1);
      tmp.splice(newIndex, 0, task);
      task.card_id = this.card.id;
      task.sequence = RandomPosition.between(
        tmp[newIndex - 1] ? tmp[newIndex - 1].sequence : RandomPosition.first(),
        tmp[newIndex + 1] ? tmp[newIndex + 1].sequence : RandomPosition.last()
      );
      task.completed = false;

      this.$api.mutate({
        mutation: UpdateTaskMutation,
        skipUpdates: true,
        variables: {
          id: task.id,
          sequence: task.sequence,
          card_id: this.card.id,
        },
      });

      mixpanel.track("Task Move");
    },
    updateCardTitle(event) {
      this.editorFocused = false;
      const title = event.target.value.trim();
      this.$emit("updateCard", {
        id: this.card.id,
        title: title,
        sequence: this.card.sequence,
      });
    },
    resetCardDeleteConfirmBox() {
      this.cardRemoveConfirm = {
        flag: false,
        id: null,
        loader: false,
      };
    },
    deleteCardDeleteConfirmBoxHandler() {
      this.cardRemoveConfirm = {
        flag: true,
        id: this.card.id,
      };
    },
    async deleteCard() {
      await this.$emit("deletCard", this.card);
      this.resetCardDeleteConfirmBox();
    },
    resetClearCompletedTaskConfirmBox() {
      this.clearCompletedTaskConfirm = {
        flag: false,
        id: null,
        loader: false,
      };
    },
    clearCompletedTaskConfirmBoxHandler() {
      this.clearCompletedTaskConfirm = {
        flag: true,
        id: this.card.id,
      };
    },
    clearCompletedTaskFromColumn() {
      this.$emit(
        "clearCompletedTaskFromColumn",
        this.clearCompletedTaskConfirm.id
      );
      this.resetClearCompletedTaskConfirmBox();
    },
    resetClearActiveTaskConfirmBox() {
      this.clearActiveTaskConfirm = {
        flag: false,
        id: null,
        loader: false,
      };
    },
    clearActiveTaskConfirmBoxHandler() {
      this.clearActiveTaskConfirm = {
        flag: true,
        id: this.card.id,
      };
    },
    clearActiveTaskFromColumn() {
      this.$emit("clearActiveTaskFromColumn", this.clearActiveTaskConfirm.id);
      this.resetClearActiveTaskConfirmBox();
    },
    addTaskEventMyHandler(data) {
      this.addingTask = true;
      this.addTaskEventHandler(data, () => {});
      setTimeout(() => {
        this.addingTask = false;
      }, 300);
    },
    resetOnlineTaskHalder() {
      this.addTaskSection = false;
    },
    onBlurCloseAddingTask(value) {
      setTimeout(() => {
        if (!this.addingTask) {
          this[value] = false;
        }
      }, 200);
    },
    deleteTask(task) {
      this.$emit("deleteTask", task);
    },
  },
  computed: {
    showCardToggle() {
      return this.card
        ? this.$store.getters.showCard(this.card.id).showCard
        : false;
    },
    filteredTodos: {
      get() {
        const taskGroup = groupBy(this.tasks, "completed");
        let key;
        switch (this.visibility) {
          case "active":
            key = false;
            break;
          default:
            key = true;
            break;
        }
        if (this.dueDateFilterMode && taskGroup[key]) {
          const enddateAvailable = taskGroup[key].filter(
            (a) => a.end_date !== null
          );
          const enddateNotAvailable = taskGroup[key].filter(
            (a) => a.end_date === null
          );
          return [
            ...orderBy(enddateAvailable, ["end_date"], ["desc", "asc"]),
            ...enddateNotAvailable,
          ];
        }
        return taskGroup[key];
      },
    },
    activeCount() {
      return this.active(this.tasks).length;
    },
    completedCount() {
      return this.completed(this.tasks).length;
    },
    isWorkspaceOwner() {
      if (this.workspace && this.user) {
        return this.user.id === this.workspace.owner.id;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.task-icon {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d5f7ec;
}
</style>
