<template>
  <q-btn
    flat
    :size="size"
    rounded
    dense
    class="thread-icon card-options"
    v-if="isVisible || isVisibleMenu"
    @click.stop
    round
    :style="componentStyle"
    :icon="$icons.matAddCircleOutline"
  >
    <q-tooltip>
      Assign task
    </q-tooltip>
    <q-menu v-model="menu" content-class="overflow-auto assign-member-menu">
      <div class="sticky-header">
        <div
          class="close-btn cursor-pointer"
          @click="
            menu = false;
            search = null;
          "
        >
          <q-icon :name="$icons.matClose" class="close-icon" />
        </div>
        <div class="assign-to q-py-sm">
          Assign to
        </div>
        <div class="search-member">
          <q-input
            autofocus
            ref="search"
            v-model="search"
            outlined
            placeholder="Search Member"
            clearable
            dense
          >
            <template v-slot:prepend>
              <q-icon :name="$icons.matSearch" />
            </template>
          </q-input>
        </div>
      </div>
      <q-list class="q-py-sm member-list">
        <template v-for="user in taskAvailableUsers">
          <q-item
            class="q-px-none q-py-xs member-item items-center"
            :key="user.id"
            @click.stop="addMemberHandler(user)"
            clickable
            v-close-popup
          >
            <q-item-section class="member-avatar" avatar>
              <q-avatar size="32px" v-if="user.pic">
                <img :src="user.pic" :alt="user.first" />
              </q-avatar>
              <!-- <q-avatar size="32px" color="grey-4" text-color="grey-10" v-else>
                {{ user.first | firstChar }}{{ user.last | firstChar }}
              </q-avatar> -->
              <avatar v-else :size="32" :customStyle="{'font-size':'14px', 'font-weight':'600'}" :username="user.first+' '+user.last"></avatar>
            </q-item-section>
            <q-item-section class="member-name" v-tooltip>
              {{ user.first | capitalize }}
              {{ user.last | capitalize }}
              <q-tooltip v-if="isTruncated">
                {{ user.first | capitalize }}
                {{ user.last | capitalize }}
              </q-tooltip>
            </q-item-section>
          </q-item>
        </template>
      </q-list>
    </q-menu>
    <q-dialog
      v-if="moveToBoardSectionConfirm.flag"
      transition-hide="none"
      transition-show="none"
      persistent
      v-model="moveToBoardSectionConfirm.flag"
    >
      <q-card class="q-px-lg q-py-md bg-white move-to-board-confirmation">
        <div class="q-pt-md row title">
          <span class="q-mx-auto">Move to Board section?</span>
        </div>
        <div class="text-dark text-center q-py-md q-px-md question">
          To assign members, select column where task should go.
        </div>
        <div class="q-mb-md">
          <q-btn
            outline
            size="md"
            class="dropdown-set full-width"
            no-caps
            :icon-right="$icons.matKeyboardArrowDown"
            :label="selectedCard && selectedCard.title"
          >
            <q-menu content-class="overflow-auto" auto-close>
              <q-list style="min-width:140px">
                <q-item
                  @click="chooseCard(card)"
                  v-for="card in cardList"
                  :key="card.id"
                  clickable
                >
                  <q-item-section>
                    {{ card.title }}
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
        <div class="row justify-around">
          <q-btn
            label="Cancel"
            color="black"
            class="q-ma-none"
            size="14px"
            style="width:100px"
            flat
            no-caps
            @click.stop="cancelHandler()"
          />
          <q-btn
            class="q-ma-none"
            size="14px"
            color="primary"
            text-color="white"
            label="OK"
            style="width:100px"
            @click.stop="moveToBoardSection()"
            :loading="moveToBoardSectionConfirm.loader"
          />
        </div>
      </q-card>
    </q-dialog>
  </q-btn>
</template>

<script>
// import Fuse from "fuse.js";
import orderBy from "lodash/orderBy";
import { AddMemberToTaskMutation, UpdateTaskMutation } from "@/gql";

import Avatar from 'vue-avatar'

export default {
  name: "InviteTaskMember",
  props: [
    "task",
    "workspace",
    "workspaceMembers",
    "className",
    "isPrivateTask",
    "size",
    "componentStyle",
  ],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    board: {
      defaultValue: null,
      cacheKey() {
        return `BoardQuery:${this.workspace.boards[0].id}`;
      },
    },
  },
  components: {
    Avatar,
  },
  data() {
    return {
      isVisibleMenu: false,
      menu: false,
      search: null,
      moveToBoardSectionConfirm: {
        flag: false,
        loader: false,
      },
      selectedCard: null,
      selectedMember: null,
      isTruncated: false,
    };
  },
  mounted() {
    if (this.cardList) {
      this.selectedCard = this.cardList[0];
    }
  },
  methods: {
    addMemberHandler(member) {
      this.search = null;
      this.menu = false;
      this.setVisible(false);
      if (this.isPrivateTask) {
        this.menu = false;
        this.moveToBoardSectionConfirm = {
          flag: true,
          loader: false,
        };
        this.selectedMember = member;
      } else {
        this.addMember(member);
      }
    },
    moveToBoardSection() {
      this.moveToBoardSectionConfirm.loader = true;
      this.attachTaskToCard();
    },
    cancelHandler() {
      this.moveToBoardSectionConfirm = {
        flag: false,
        loader: false,
      };
    },
    async addMember(member) {
      if (this.$refs.search) this.$refs.search.focus();

      const variables = {
        workspace_id: this.workspace.id,
        task_id: this.task.id,
        user_id: member.id,
      };
      try {
        const taskEntity = this.$api.getEntity("task", this.task.id);
        const memberEntity = this.$api.getEntity("user", member.id);
        taskEntity.members.push(memberEntity);
        if (memberEntity.task) {
          memberEntity.task.push(taskEntity);
        }

        const response = await this.$api.mutate({
          mutation: AddMemberToTaskMutation,
          variables,
        });
        if (response) {
          this.$emit("setConvertedTask", this.task);
        }
      } catch (error) {
        if (error) {
          // Start from this
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: this.$t("message.coudlNotAssignTaskToMember"),
            icon: this.$icons.matReportProblem,
          });
        }
      }
    },
    chooseCard(card) {
      this.selectedCard = card;
    },
    async attachTaskToCard() {
      const variables = {
        id: this.task.id,
        card_id: this.selectedCard.id,
      };
      try {
        const boardQuey = this.$api.getQuery(`BoardQuery:${this.board.id}`);
        const task = {
          ...this.task,
          card_id: this.selectedCard.id,
        };
        boardQuey.data.board.tasks.push(task);
        const response = await this.$api.mutate({
          mutation: UpdateTaskMutation,
          variables,
        });
        if (response) {
          this.addMember(this.selectedMember);
        }
      } catch (error) {
        if (error) {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Something went wrong!",
            icon: this.$icons.matReportProblem,
          });
        }
      }
      this.selectedCard = null;
      this.selectedMember = null;
      this.cancelHandler();
    },
    setVisible(flag) {
      this.isVisibleMenu = flag;
    },
  },
  computed: {
    isVisible() {
      if (this.task.members.length === 0) return true;
      return this.taskAvailableUsers && this.taskAvailableUsers.length;
    },
    taskMemberIds() {
      return this.task.members.map((m) => m.id);
    },
    taskAvailableUsersFuse() {
      if (this.workspaceMembers) {
        const members = [this.user, ...this.workspaceMembers].filter(
          (member) => !this.taskMemberIds.includes(member.id)
        );

        const orderedMembers = orderBy(
          members,
          ["first", "last"],
          ["asc", "asc"]
        );

        return orderedMembers;
      }
      return [this.user].filter(
        (member) => !this.taskMemberIds.includes(member.id)
      );
    },
    taskAvailableUsers() {
      // return this.search ? this.taskAvailableUsersFuse.search(this.search) : this.taskAvailableUsersFuse.hasOwnProperty("list") ? this.taskAvailableUsersFuse.list : this.taskAvailableUsersFuse;
      if (this.search) {
        this.setVisible(true);
        return this.taskAvailableUsersFuse.filter((u) =>
          u.fullname.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        if (this.taskAvailableUsersFuse.hasOwnProperty("list")) {
          return this.taskAvailableUsersFuse.list;
        } else {
          return this.taskAvailableUsersFuse;
        }
      }
    },
    cardList() {
      if (this.board) {
        return this.board.cards;
      }
      return null;
    },
  },
  watch: {
    menu(to) {
      this.$emit("inviteTaskMemberChanged", to);
    },
  },
};
</script>
