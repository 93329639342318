<template>
  <div style="background: #ffffff">
    <q-toolbar class="toolbar">
      <div class="month-year">
        <div class="flex">
          <span class="month">{{ selectedMonth.long }}</span>
          <span class="year">{{ selectedYear }}</span>
          <span><q-icon :name="$icons.matArrowDropDown"/></span>
          <!-- <q-btn
            flat
            class="add-task-btn q-mr-md"
            dense
            no-caps
            @click.stop="addTask"
            v-if="canPostMessageFromWorkspace"
          >
            <q-icon size="16px" class="plus-icon" :name="$icons.matAdd" />
            <span style="font-size: 11px"> Add Task </span>
          </q-btn> -->
        </div>
        <q-menu>
          <div class="month-picker">
            <div class="title">
              <span style="cursor: pointer" @click="year--"
                ><q-icon :name="$icons.fasAngleLeft"></q-icon
              ></span>
              <span>{{ year }}</span>
              <span style="cursor: pointer" @click="year++"
                ><q-icon :name="$icons.fasAngleRight"></q-icon
              ></span>
            </div>
            <q-separator></q-separator>
            <div class="months-list">
              <q-btn
                v-for="(month, index) in months"
                :key="index"
                :class="
                  month === selectedMonth.short && year == selectedYear
                    ? 'selected-month'
                    : ''
                "
                :label="month.toUpperCase()"
                @click="selectMonth(month)"
              >
              </q-btn>
            </div>
          </div>
        </q-menu>
      </div>
      <div class="calendar-view-tabs flex justify-center">
        <q-btn
          @click="changeView('month')"
          class="flex"
          :class="calendarView == 'month' ? 'selected-tab' : ''"
          no-caps
          padding="0"
        >
          <span class="tab-text">Month</span>
        </q-btn>
        <q-btn
          @click="changeView('week')"
          class="flex"
          :class="calendarView == 'week' ? 'selected-tab' : ''"
          no-caps
          padding="0"
        >
          <span class="tab-text">Week</span>
        </q-btn>
      </div>
      <div class="calendar-navigation">
        <span
          style="cursor: pointer; margin-right: 5px"
          @click="calendarPrevious()"
          class="arrow"
        >
          <q-icon :name="$icons.fasAngleLeft"></q-icon>
          <q-tooltip>Previous {{ calendarView }}</q-tooltip>
        </span>
        <q-btn
          label="Today"
          no-caps
          size="10px"
          padding="0px 10px"
          @click="moveToToday()"
        />
        <span
          style="cursor: pointer; margin-left: 5px"
          @click="calendarNext()"
          class="arrow"
        >
          <q-icon :name="$icons.fasAngleRight"></q-icon>
          <q-tooltip>Next {{ calendarView }}</q-tooltip>
        </span>
      </div>
    </q-toolbar>
    <q-calendar
      ref="calendar"
      v-model="date"
      :view="calendarView"
      locale="en-us"
      :show-month-label="false"
      short-weekday-label
      style="height: calc(100vh - 80px); overflow: auto"
      class="calendar"
      :day-height="0"
      :interval-height="45"
    >
      <template #week="{ week, miniMode }" v-if="calendarView === 'month'">
        <template v-if="!miniMode">
          <template v-for="(computedEvent, index) in getMonthEvents(week)">
            <q-badge
              :key="index"
              class="q-row-event badge-content group-hover"
              :class="badgeClasses(computedEvent, 'day')"
              :style="badgeStyles(computedEvent, 'day', week.length)"
              @click="openTask(computedEvent.event)"
            >
              <template v-if="computedEvent.event">
                <div
                  class="flex"
                  style="align-items: center; position: relative"
                >
                  <div
                    class="badge-label"
                    :style="
                      getBadgeColorStyle(computedEvent.event, week, 'month')
                    "
                  ></div>
                  <span class="ellipsis" style="width: calc(100% - 25px)">{{
                    computedEvent.event.title
                  }}</span>
                  <div
                    class="thread-icons row items-center q-px-xs group-hover-item"
                    @click.stop
                    style="position: absolute; right: 0"
                  >
                    <div
                      v-if="!computedEvent.event.isbookmarked"
                      @click="addTaskBookmark(computedEvent.event)"
                      class="cursor-pointer"
                    >
                      <q-icon
                        size="xs"
                        color="black"
                        :name="$icons.matBookmarkBorder"
                        style="margin-top: 1px"
                      />
                    </div>
                    <div
                      v-if="computedEvent.event.isbookmarked"
                      @click="removeTaskBookmark(computedEvent.event)"
                      class="cursor-pointer"
                    >
                      <q-icon
                        size="xs"
                        color="pink"
                        :name="$icons.matBookmark"
                      />
                    </div>
                    <q-btn
                      flat
                      size="sm"
                      round
                      color="black"
                      dense
                      padding="0"
                      style="margin-top: -1px"
                      :icon="$icons.matMoreHoriz"
                    >
                      <q-menu
                        auto-close
                        content-class="menu-hover overflow-auto q-pa-sm"
                      >
                        <q-list>
                          <q-item
                            @click="
                              $copyTextToClipboard(copyTaskLink(computedEvent.event.id));
                              isTaskCopy = true;
                            "
                            clickable
                          >
                            <q-item-section>Copy task link</q-item-section>
                          </q-item>
                          <q-item
                            clickable
                            @click="moveTaskData(computedEvent.event)"
                            v-if="isVisible([1, 2, 3])  && ((computedEvent.event.owner.id === user.id && computedEvent.event.is_lock) || !computedEvent.event.is_lock)"
                          >
                            <q-item-section>Move task</q-item-section>
                          </q-item>
                          <!-- <q-item @click="edit(cardTask)" clickable>
                            <q-item-section>Edit task</q-item-section>
                          </q-item> -->
                          <q-item
                            clickable
                            @click="duplicateTask(computedEvent.event)"
                            v-if="isVisible([1, 2, 3])"
                          >
                            <q-item-section>Duplicate task</q-item-section>
                          </q-item>
                          <q-separator
                            class="q-my-sm"
                            style="background: #e3e8ed"
                            v-if="isVisible([1, 2, 3])"
                          />
                          <!-- <q-item
                            @click="changeCompleteStatus"
                            v-if="place === 'board'"
                            clickable
                          >
                            <q-item-section v-if="!cardTask.completed"
                              >Complete task</q-item-section
                            >
                            <q-item-section v-else
                              >Mark as incomplete</q-item-section
                            >
                          </q-item> -->
                          <q-item
                            @click="addTimeLogs(computedEvent.event)"
                            clickable
                            v-if="isVisible([1, 2, 3])"
                          >
                            <q-item-section
                              v-if="
                                computedEvent.event &&
                                  computedEvent.event.timelogs &&
                                  computedEvent.event.timelogs.length
                              "
                              >View time report</q-item-section
                            >
                            <q-item-section v-else
                              >Add time report</q-item-section
                            >
                          </q-item>
                          <q-item
                            @click="changeTaskLock(computedEvent.event, true)"
                            clickable

                            v-if="computedEvent.event.owner.id === user.id && !computedEvent.event.is_lock"
                          >
                            <q-item-section>Lock task</q-item-section>
                          </q-item>
                          <q-item
                            @click="changeTaskLock(computedEvent.event, false)"
                            clickable
                            v-if="computedEvent.event.owner.id === user.id && computedEvent.event.is_lock"
                          >
                            <q-item-section>Unlock task</q-item-section>
                          </q-item>
                          <q-item
                            @click="changeTaskVisible(computedEvent.event, true)"
                            clickable
                            v-if="computedEvent.event.owner.id === user.id && !computedEvent.event.is_invisible"
                          >
                            <q-item-section>Make task invisible</q-item-section>
                          </q-item>
                          <q-item
                            @click="changeTaskVisible(computedEvent.event, false)"
                            clickable
                            v-if="computedEvent.event.owner.id === user.id && computedEvent.event.is_invisible"
                          >
                            <q-item-section>Make task visible</q-item-section>
                          </q-item>
                          <q-item
                            class="text-red"
                            @click="$emit('deleteTask', computedEvent.event)"
                            clickable
                            v-if="isVisible([1, 2, 3]) && ((computedEvent.event.owner.id === user.id && computedEvent.event.is_lock) || !computedEvent.event.is_lock)"
                          >
                            <q-item-section>Delete task</q-item-section>
                          </q-item>
                        </q-list>
                      </q-menu>
                    </q-btn>
                  </div>
                </div>
              </template>
            </q-badge>
          </template>
        </template>
      </template>

      <template #day-header="{ timestamp }" v-if="calendarView === 'week'">
        <template v-for="(event, index) in eventsMap[timestamp.date]">
          <q-badge
            :key="index"
            style="width: 95%; cursor: pointer"
            :style="getDayHeaderBadgeStyle(event)"
            class="text-black"
            :id="`badge-${index}`"
          >
            <div
              class="flex week-badge-content group-hover"
              :style="getContentColorStyle(event)"
              @click="openTask(event)"
              style="align-items: center"
            >
              <div
                class="badge-label"
                :style="getBadgeColorStyle(event, null, 'week')"
              ></div>
              <div class="content flex">
                <span class="ellipsis">{{ event.title }}</span>
              </div>
              <div
                class="thread-icons q-px-xs group-hover-item"
                @click.stop
                style="position: absolute; right: 0"
              >
                <q-icon
                  v-if="!event.isbookmarked"
                  @click="addTaskBookmark(event)"
                  size="xs"
                  class="cursor-pointer"
                  color="black"
                  :name="$icons.matBookmarkBorder"
                  style="margin-top: 1px"
                />
                <q-icon
                  v-if="event.isbookmarked"
                  @click="removeTaskBookmark(event)"
                  size="xs"
                  class="cursor-pointer"
                  color="pink"
                  :name="$icons.matBookmark"
                />
                <q-btn
                  flat
                  size="sm"
                  round
                  color="black"
                  dense
                  padding="0"
                  style="margin-top: -1px"
                  :icon="$icons.matMoreHoriz"
                >
                  <q-menu
                    auto-close
                    content-class="menu-hover overflow-auto q-pa-sm"
                  >
                    <q-list>
                      <q-item
                        @click="
                          $copyTextToClipboard(copyTaskLink(event.id));
                          isTaskCopy = true;
                        "
                        clickable
                      >
                        <q-item-section>Copy task link</q-item-section>
                      </q-item>
                      <!-- <q-item @click="edit(cardTask)" clickable>
                        <q-item-section>Edit task</q-item-section>
                      </q-item> -->
                      <q-item
                        clickable
                        v-if="isVisible([1, 2, 3])  && ((event.owner.id === user.id && event.is_lock) || !event.is_lock)"
                        @click="moveTaskData(event)"
                      >
                        <q-item-section>Move task</q-item-section>
                      </q-item>
                      <q-item
                        v-if="isVisible([1, 2, 3])"
                        clickable
                        @click="duplicateTask(event)"
                      >
                        <q-item-section>Duplicate task</q-item-section>
                      </q-item>
                      <q-separator
                        class="q-my-sm"
                        style="background: #e3e8ed"
                        v-if="isVisible([1, 2, 3])"
                      />
                      <!-- <q-item
                        @click="changeCompleteStatus"
                        v-if="place === 'board'"
                        clickable
                      >
                        <q-item-section v-if="!cardTask.completed"
                          >Complete task</q-item-section
                        >
                        <q-item-section v-else
                          >Mark as incomplete</q-item-section
                        >
                      </q-item> -->
                      <q-item
                        v-if="isVisible([1, 2, 3])"
                        @click="addTimeLogs(event)"
                        clickable
                      >
                        <q-item-section
                          v-if="
                            event && event.timelogs && event.timelogs.length
                          "
                          >View time report</q-item-section
                        >
                        <q-item-section v-else>Add time report</q-item-section>
                      </q-item>
                      <q-item
                        @click="changeTaskLock(event, true)"
                        clickable

                        v-if="event.owner.id === user.id && !event.is_lock"
                      >
                        <q-item-section>Lock task</q-item-section>
                      </q-item>
                      <q-item
                        @click="changeTaskLock(event, false)"
                        clickable
                        v-if="event.owner.id === user.id && event.is_lock"
                      >
                        <q-item-section>Unlock task</q-item-section>
                      </q-item>
                      <q-item
                        @click="changeTaskVisible(event, true)"
                        clickable
                        v-if="event.owner.id === user.id && !event.is_invisible"
                      >
                        <q-item-section>Make task invisible</q-item-section>
                      </q-item>
                      <q-item
                        @click="changeTaskVisible(event, false)"
                        clickable
                        v-if="event.owner.id === user.id && event.is_invisible"
                      >
                        <q-item-section>Make task visible</q-item-section>
                      </q-item>
                      <q-item
                        class="text-red"
                        @click="$emit('deleteTask', event)"
                        clickable
                        v-if="isVisible([1, 2, 3]) && ((event.owner.id === user.id && event.is_lock) || !event.is_lock)"
                      >
                        <q-item-section>Delete task</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-btn>
              </div>
            </div>
          </q-badge>
        </template>
      </template>

      <template
        #day-body="{ timestamp, timeStartPos, timeDurationHeight }"
        v-if="calendarView === 'week'"
      >
        <template v-for="(event, index) in getWeekEvents(timestamp.date)">
          <q-badge
            v-if="event.start_date"
            :key="index"
            class="
              my-event
              justify-center
              group-hover
              badge-content
              bg-white
              week-event
            "
            :style="
              badgeStylesWeek(event, 'body', timeStartPos, timeDurationHeight)
            "
            :class="
              badgeClassesWeek(event, 'body', timeStartPos, timeDurationHeight)
            "
            text-color="black"
          >
            <div
              class="flex week-badge-content"
              :style="getContentColorStyle(event)"
              @click="openTask(event)"
            >
              <div
                class="badge"
                :style="getBadgeColorStyle(event, null, 'week')"
              ></div>
              <div class="content flex">
                <div style="width: 100%">
                  <p style="margin: 0; font-size: 10px; font-weight: 500">
                    {{ getEventTimeRange(event.start_date, event.end_date) }}
                  </p>
                  <p
                    style="margin: 0; padding-top: 3px; font-weight: bold"
                    class="ellipsis"
                  >
                    {{ event.title }}
                  </p>
                </div>
              </div>
              <div
                class="thread-icons q-px-xs group-hover-item"
                @click.stop
                style="position: absolute; right: 0"
              >
                <q-icon
                  v-if="!event.isbookmarked"
                  @click="addTaskBookmark(event)"
                  size="xs"
                  class="cursor-pointer"
                  color="black"
                  :name="$icons.matBookmarkBorder"
                  style="margin-top: 1px"
                />
                <q-icon
                  v-if="event.isbookmarked"
                  @click="removeTaskBookmark(event)"
                  size="xs"
                  class="cursor-pointer"
                  color="pink"
                  :name="$icons.matBookmark"
                />
                <q-btn
                  flat
                  size="sm"
                  round
                  color="black"
                  dense
                  padding="0"
                  style="margin-top: -1px"
                  :icon="$icons.matMoreHoriz"
                >
                  <q-menu
                    auto-close
                    content-class="menu-hover overflow-auto q-pa-sm"
                  >
                    <q-list>
                      <q-item
                        @click="
                          $copyTextToClipboard(copyTaskLink);
                          isTaskCopy = true;
                        "
                        clickable
                      >
                        <q-item-section>Copy task link</q-item-section>
                      </q-item>
                      <q-item
                        clickable
                        v-if="isVisible([1, 2, 3]) && ((event.owner.id === user.id && event.is_lock) || !event.is_lock)"
                        @click="moveTaskData(event)"
                      >
                        <q-item-section>Move task</q-item-section>
                      </q-item>
                      <q-item
                        v-if="isVisible([1, 2, 3])"
                        clickable
                        @click="duplicateTask(event)"
                      >
                        <q-item-section>Duplicate task</q-item-section>
                      </q-item>
                      <!-- <q-item @click="edit(cardTask)" clickable>
                        <q-item-section>Edit task</q-item-section>
                      </q-item> -->
                      <q-separator
                        class="q-my-sm"
                        style="background: #e3e8ed"
                        v-if="isVisible([1, 2, 3])"
                      />
                      <!-- <q-item
                        @click="changeCompleteStatus"
                        v-if="place === 'board'"
                        clickable
                      >
                        <q-item-section v-if="!cardTask.completed"
                          >Complete task</q-item-section
                        >
                        <q-item-section v-else
                          >Mark as incomplete</q-item-section
                        >
                      </q-item> -->
                      <q-item
                        v-if="isVisible([1, 2, 3])"
                        @click="addTimeLogs(event)"
                        clickable
                      >
                        <q-item-section
                          v-if="
                            event && event.timelogs && event.timelogs.length
                          "
                          >View time report</q-item-section
                        >
                        <q-item-section v-else>Add time report</q-item-section>
                      </q-item>
                      <q-item
                        @click="changeTaskLock(event, true)"
                        clickable

                        v-if="event.owner.id === user.id && !event.is_lock"
                      >
                        <q-item-section>Lock task</q-item-section>
                      </q-item>
                      <q-item
                        @click="changeTaskLock(event, false)"
                        clickable
                        v-if="event.owner.id === user.id && event.is_lock"
                      >
                        <q-item-section>Unlock task</q-item-section>
                      </q-item>
                      <q-item
                        @click="changeTaskVisible(event, true)"
                        clickable
                        v-if="event.owner.id === user.id && !event.is_invisible"
                      >
                        <q-item-section>Make task invisible</q-item-section>
                      </q-item>
                      <q-item
                        @click="changeTaskVisible(event, false)"
                        clickable
                        v-if="event.owner.id === user.id && event.is_invisible"
                      >
                        <q-item-section>Make task visible</q-item-section>
                      </q-item>
                      <q-item
                        class="text-red"
                        @click="$emit('deleteTask', event)"
                        clickable
                        v-if="isVisible([1, 2, 3]) && ((event.owner.id === user.id && event.is_lock) || !event.is_lock)"
                      >
                        <q-item-section>Delete task</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-btn>
              </div>
            </div>
          </q-badge>
        </template>
      </template>
    </q-calendar>
    <duplicate-task-dialog
      v-if="cloneTask.flag"
      v-model="cloneTask.flag"
      :task="cloneTask.task"
      :closeDialog="closeCloneTaskDialog"
    />
    <move-task-dialog
      v-if="moveTask.flag"
      v-model="moveTask.flag"
      :task="moveTask.task"
      :board="board"
      :closeDialog="closeMoveTaskDialog"
    />
  </div>
</template>

<script>
import Vue from "vue";
import Plugin from "@quasar/quasar-ui-qcalendar";
import "@quasar/quasar-ui-qcalendar/dist/index.css";
import QCalendar from "@quasar/quasar-ui-qcalendar";
import { RemoveTaskBookmarkMutation, AddTaskBookmarkMutation, UpdateTaskMutation } from "@/gql";
import mixpanel from "@/mixpanel";
import DuplicateTaskDialog from "@/components/Dialogs/DuplicateTaskDialog";
import MoveTaskDialog from "@/components/Dialogs/MoveTaskDialog.vue";
import sortBy from "lodash/sortBy";
import UserScope from "@/mixins/UserScope";

const moment = require("moment");

Vue.use(Plugin);

import { mapGetters } from "vuex";
import get from "lodash/get";

export default {
  name: "CalendarView",
  mixins: [UserScope],
  components: { DuplicateTaskDialog, MoveTaskDialog },
  props: ["tasksList", "editTask", "workspace", "board"],
  data() {
    return {
      date: "",
      datePicker: false,
      months: moment.monthsShort(),
      currentYear: moment().format("YYYY"),
      selectedMonth: {
        short: moment().format("MMM"),
        long: moment().format("MMMM"),
      },
      year: moment().format("YYYY"),
      selectedYear: moment().format("YYYY"),
      cloneTask: {
        flag: false,
        task: null,
      },
      view: "month",
      singleDayEvents: [],
      multipleDayEvents: [],
      moveTask: {
        flag: false,
        task: null,
      },
    };
  },
  created() {
    if (this.workspace) {
      const data = this.$store.getters.currentCalendarView(
        this.workspace.boards[0].id
      );
      if (!data) {
        this.$store.dispatch("setCalendarView", {
          boardId: this.workspace.boards[0].id,
          view: "month",
        });
      }
    }
  },
  methods: {
    async changeTaskVisible(task, flag) {
      const variables = {
        id: task.id,
        is_invisible: flag,
      };
      const boardQuery = this.$api.getQuery(`BoardQuery:${this.workspace.boards[0].id}`);
      boardQuery.data.board.tasks = boardQuery.data.board.tasks.map((taskDatail) => {
        if (taskDatail.id === task.id) {
          taskDatail.is_invisible = flag;
        }
        return taskDatail;
      });
      const taskData = this.$api.getEntity("task", task.id);
      if(taskData) {
        taskData.is_invisible = flag;
      }
      try {
        await this.$api.mutate({
          mutation: UpdateTaskMutation,
          variables,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async changeTaskLock(task, flag) {
      const variables = {
        id: task.id,
        is_lock: flag,
      };
      const boardQuery = this.$api.getQuery(`BoardQuery:${this.workspace.boards[0].id}`);
      boardQuery.data.board.tasks = boardQuery.data.board.tasks.map((taskDetails) => {
        if (taskDetails.id === task.id) {
          taskDetails.is_lock = flag;
        }
        return taskDetails;
      });
      const taskData = this.$api.getEntity("task", task.id);
      if(taskData) {
        taskData.is_lock = flag;
      }
      try {
        await this.$api.mutate({
          mutation: UpdateTaskMutation,
          variables,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    copyTaskLink(id) {
      const taskLink = `${process.env.VUE_APP_APPBASE_URL}/companies/${this.currentCompany.companyUri}/workspaces/${this.workspace.id}/board?task=${id}&workspace=${this.workspace.id}&workspaceName=${this.workspace.title}`;
      return taskLink;
    },
    closeMoveTaskDialog() {
      this.moveTask.flag = false;
    },
    changeView(view) {
      this.$store.dispatch("setCalendarView", {
        boardId: this.workspace.boards[0].id,
        view: view,
      });
    },
    addTimeLogs(task) {
      this.$eventBus.$emit("openTimelogs", true, task.timelogs, true, task);
    },
    duplicateTask(task) {
      this.cloneTask.task = task;
      this.cloneTask.flag = true;
    },
    moveTaskData(task) {
      this.moveTask.task = task;
      this.moveTask.flag = true;
    },
    closeCloneTaskDialog() {
      this.cloneTask.flag = false;
      this.cloneTask.task = null;
    },
    async removeTaskBookmark(task) {
      try {
        const variables = {
          task_id: task.id,
        };

        task.isbookmarked = !task.isbookmarked;
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.workspace.id
        );
        workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
        await this.$api.mutate({
          mutation: RemoveTaskBookmarkMutation,
          variables,
          skipUpdates: true,
        });
        mixpanel.track("Task Bookmark Remove");
      } catch (error) {
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.workspace.id
        );
        workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
        throw new Error(error);
      }
    },
    async addTaskBookmark(task) {
      try {
        const variables = {
          task_id: task.id,
        };

        task.isbookmarked = !task.isbookmarked;
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.workspace.id
        );
        workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
        await this.$api.mutate({
          mutation: AddTaskBookmarkMutation,
          variables,
          skipUpdates: true,
        });
        this.$mixpanelEvent("save-task", {});
        mixpanel.track("Task Bookmark Add");
      } catch (error) {
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.workspace.id
        );
        workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
        throw new Error(error);
      }
    },
    addTask() {
      let task = {
        card_id: null,
        details: "",
        html: null,
        labels: [],
        title: "",
      };
      this.$emit("createTask", task);
    },
    isTaskStartingInCurrentWeek(event, week) {
      if (event) {
        let startDate = event.start_date.split(" ")[0];
        let weekDates = week.map((day) => day.date);
        return weekDates.includes(startDate);
      }
    },
    openTask(event) {
      let obj = {
        task: event,
        isOpen: "taskComment",
      };
      this.editTask(obj);
    },
    calendarNext() {
      this.$refs.calendar.next();
      this.setDate();
    },
    calendarPrevious() {
      this.$refs.calendar.prev();
      this.setDate();
    },
    moveToToday() {
      this.$refs.calendar.moveToToday();
      this.setDate();
    },
    setDate() {
      this.selectedYear = moment(this.$refs.calendar.emittedValue).format(
        "YYYY"
      );
      this.selectedMonth.short = moment(
        this.$refs.calendar.emittedValue
      ).format("MMM");
      this.selectedMonth.long = moment(this.$refs.calendar.emittedValue).format(
        "MMMM"
      );
    },
    selectMonth(month) {
      this.selectedMonth.short = month;
      this.selectedMonth.long = moment()
        .month(month)
        .format("MMMM");
      this.selectedYear = this.year;
      this.date = moment()
        .month(month)
        .year(this.selectedYear)
        .format("YYYY-MM-DD");
    },
    isCssColor(color) {
      return !!color && !!color.match(/^(#|(rgb|hsl)a?\()/);
    },
    badgeClasses(infoEvent, type) {
      const color =
        infoEvent.event !== undefined ? infoEvent.event.color : "transparent";
      const cssColor = this.isCssColor(color);
      const isHeader = type === "header";
      return {
        [`text-white bg-${color}`]: !cssColor,
        "full-width":
          !isHeader && (!infoEvent.side || infoEvent.side === "full"),
        "left-side": !isHeader && infoEvent.side === "left",
        "right-side": !isHeader && infoEvent.side === "right",
        "cursor-pointer": infoEvent.event !== undefined,
        "q-day-event-void": infoEvent.event === undefined, // height: 0, padding: 0
      };
    },
    insertEvent(events, weekLength, infoWeek, index, availableDays, level) {
      const iEvent = infoWeek[index];
      if (iEvent !== undefined && iEvent.left >= availableDays) {
        // If you have space available, more events are placed
        if (iEvent.left - availableDays) {
          // It is filled with empty events
          events.push({ size: iEvent.left - availableDays });
        }
        // The event is built
        events.push({
          size: iEvent.size,
          event: iEvent.event,
          left: iEvent.left,
          right: iEvent.right,
        });

        if (level !== 0) {
          // If it goes into recursion, then the item is deleted
          infoWeek.splice(index, 1);
        }

        const currentAvailableDays = iEvent.left + iEvent.size;

        if (currentAvailableDays < weekLength) {
          const indexNextEvent = QCalendar.indexOf(
            infoWeek,
            (e) => e.id !== iEvent.id && e.left >= currentAvailableDays
          );

          this.insertEvent(
            events,
            weekLength,
            infoWeek,
            indexNextEvent !== -1 ? indexNextEvent : index,
            currentAvailableDays,
            level + 1
          );
        } // else: There are no more days available, end of iteration
      } else {
        events.push({ size: weekLength - availableDays });
        // end of iteration
      }
    },

    isBetweenDates(date, start, end) {
      return date >= start && date <= end;
    },

    isBetweenDatesWeek(dateStart, dateEnd, weekStart, weekEnd) {
      return (
        (dateEnd < weekEnd && dateEnd >= weekStart) ||
        dateEnd === weekEnd ||
        (dateEnd > weekEnd && dateStart <= weekEnd)
      );
    },
    badgeStyles(infoEvent, type, weekLength, timeStartPos, timeDurationHeight) {
      let color = "transparent";
      const s = {};
      if (timeStartPos) {
        s.top = timeStartPos(infoEvent.start_date.split(" ")[1]) + "px";
      }
      if (timeDurationHeight) {
        s.height = "25px";
      }
      if (infoEvent.size !== undefined) {
        s.width = (100 / weekLength) * infoEvent.size + "% !important";
      }
      if (infoEvent.event) {
        let last_label =
          infoEvent.event.labels[infoEvent.event.labels.length - 1];
        color = last_label ? last_label.colorcode : "#8A75AE";
      }
      if (color !== "transparent") {
        let rgb = this.hexToRgb(color);
        s["background-color"] = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)`;
      } else {
        s["background-color"] = "transparent";
      }
      // s['align-items'] = 'flex-start'
      return s;
    },
    badgeStylesWeek(event, type, timeStartPos, timeDurationHeight) {
      const s = {};
      if (timeStartPos) {
        s.top = timeStartPos(event.start_date.split(" ")[1]) + "px";
      }
      if (timeDurationHeight) {
        s.height = timeDurationHeight(this.calculateDuration(event)) + "px";
      }
      if (event.overlapped) {
        s.left = event.overlapped_count * 20 + "px";
        s.width = `calc(100% - ${event.overlapped_count * 20}px) !important`;
        s.border = "1px solid white";
      }
      s.padding = "0";
      s["align-items"] = "flex-start";
      return s;
    },
    badgeClassesWeek(event, type) {
      const isHeader = type === "header";
      const styles = {
        "text-white": "text-white",
        "full-width": !isHeader && (!event.side || event.side === "full"),
        "left-side": !isHeader && event.side === "left",
        "right-side": !isHeader && event.side === "right",
      };
      return styles;
    },
    getContentColorStyle(event) {
      let s = {};
      let last_label = event.labels[event.labels.length - 1];
      let color = last_label ? last_label.colorcode : "#8A75AE";
      let rgb = this.hexToRgb(color);
      s["background-color"] = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)`;
      return s;
    },
    getBadgeColorStyle(event, week, view) {
      let s = {};
      let color = "transparent";
      if (event) {
        let last_label = event.labels[event.labels.length - 1];
        color = last_label ? last_label.colorcode : "#8A75AE";
      }
      if (view === "month") {
        s["background-color"] = this.isTaskStartingInCurrentWeek(event, week)
          ? color
          : "transparent";
      } else {
        s["background-color"] = color;
      }
      return s;
    },
    getDayHeaderBadgeStyle(event) {
      let s = {};
      let last_label = event.labels[event.labels.length - 1];
      let color = last_label ? last_label.colorcode : "#8A75AE";
      let rgb = this.hexToRgb(color);
      s["background-color"] = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)`;
      s["padding"] = "0";
      return s;
    },
    getMonthEvents(week) {
      const tsFirstDay = QCalendar.parsed(week[0].date + " 00:00");
      const tsLastDay = QCalendar.parsed(week[week.length - 1].date + " 23:59");
      const firstDay = QCalendar.getDayIdentifier(tsFirstDay);
      const lastDay = QCalendar.getDayIdentifier(tsLastDay);

      const eventsWeek = [];
      this.getTasksList.forEach((event, id) => {
        if (event.start_date) {
          const tsStartDate = QCalendar.parsed(event.start_date);
          const tsEndDate = QCalendar.parsed(
            event.end_date ? event.end_date : event.start_date
          );
          const startDate = QCalendar.getDayIdentifier(tsStartDate);
          const endDate = QCalendar.getDayIdentifier(tsEndDate);

          if (this.isBetweenDatesWeek(startDate, endDate, firstDay, lastDay)) {
            const left = QCalendar.daysBetween(tsFirstDay, tsStartDate, true);
            const right = QCalendar.daysBetween(tsEndDate, tsLastDay, true);

            eventsWeek.push({
              id, // index event
              left, // Position initial day [0-6]
              right, // Number days available
              size: week.length - (left + right), // Size current event (in days)
              event, // Info
            });
          }
        }
      });

      const events = [];
      if (eventsWeek.length > 0) {
        const infoWeek = eventsWeek.sort((a, b) => a.left - b.left);
        infoWeek.forEach((event, i) => {
          this.insertEvent(events, week.length, infoWeek, i, 0, 0);
        });
      }
      return events;
    },
    getWeekEvents(dt) {
      let singleDayEvents = [];
      let sortedEvents = sortBy(this.getTasksList, (n) => n.start_date);
      sortedEvents.forEach((event) => {
        if (event.start_date && event.end_date) {
          if (event.start_date.split(" ")[0] === event.end_date.split(" ")[0]) {
            singleDayEvents.push(event);
          }
        }
      });
      const currentDate = QCalendar.parsed(dt);
      const events = [];
      for (let i = 0; i < singleDayEvents.length; ++i) {
        let added = false;
        if (
          singleDayEvents[i].start_date &&
          singleDayEvents[i].start_date.split(" ")[0] === dt
        ) {
          if (singleDayEvents[i].start_date) {
            if (events.length > 0) {
              // check for overlapping times
              const startTime = QCalendar.parsed(singleDayEvents[i].start_date);
              const endTime = QCalendar.addToDate(startTime, {
                minute: this.calculateDuration(singleDayEvents[i]),
              });
              for (let j = 0; j < events.length; ++j) {
                if (events[j].start_date) {
                  const startTime2 = QCalendar.parsed(events[j].start_date);
                  const endTime2 = QCalendar.addToDate(startTime2, {
                    minute: this.calculateDuration(events[j]),
                  });
                  if (
                    QCalendar.isBetweenDates(startTime, startTime2, endTime2) ||
                    QCalendar.isBetweenDates(endTime, startTime2, endTime2)
                  ) {
                    singleDayEvents[i].side = "full";
                    if (events.length) {
                      let count = 0;
                      events.map((event) => {
                        if (
                          Date.parse(singleDayEvents[i].start_date) >=
                            Date.parse(event.start_date) &&
                          Date.parse(singleDayEvents[i].start_date) <=
                            Date.parse(event.end_date)
                        ) {
                          count++;
                          singleDayEvents[i].overlapped_event = event;
                        }
                      });
                      singleDayEvents[i].overlapped = count ? true : false;
                      singleDayEvents[i].overlapped_count = count;
                    }
                    events.push(singleDayEvents[i]);
                    added = true;
                    break;
                  }
                }
              }
            }
          }
          if (!added) {
            singleDayEvents[i].side = undefined;
            events.push(singleDayEvents[i]);
          }
        } else if (singleDayEvents[i].days) {
          // check for overlapping dates
          const startDate = QCalendar.parsed(
            singleDayEvents[i].start_date.split(" ")[0]
          );
          const endDate = QCalendar.addToDate(startDate, {
            day: singleDayEvents[i].days,
          });
          if (QCalendar.isBetweenDates(currentDate, startDate, endDate)) {
            events.push(singleDayEvents[i]);
            added = true;
          }
        }
      }
      return events;
    },
    hexToRgb(hex) {
      const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
      return { r, g, b };
    },
    calculateDuration(event) {
      var now = moment(event.start_date); //todays date
      var end = moment(event.end_date); // another date
      var duration = moment.duration(end.diff(now));
      var minutes = duration.asMinutes();
      return minutes ? minutes : 60;
    },
    getRange(startDate, endDate) {
      startDate = moment(startDate);
      endDate = moment(endDate);
      var now = startDate.clone(),
        dates = [];
      while (now.format("M/D/YYYY") !== endDate.format("M/D/YYYY")) {
        dates.push(now.format("YYYY-MM-DD"));
        now.add(1, "days");
      }
      dates.push(endDate.format("YYYY-MM-DD"));
      return dates;
    },
    getEventTimeRange(start_date, end_date) {
      let start_time, end_time;
      if (moment(start_date).format("mm") === "00") {
        start_time = moment(start_date).format("h a");
      } else {
        start_time = moment(start_date).format("h:mm a");
      }

      if (moment(end_date).format("mm") === "00") {
        end_time = moment(end_date).format("h a");
      } else {
        end_time = moment(end_date).format("h:mm a");
      }
      return start_time + " - " + end_time;
    },
  },
  computed: {
    ...mapGetters({
			activeCompany: "activeCompany",
			companies: "auth",
		}),
		currentCompany() {
			if (this.companies && this.activeCompany) {
				return get(this.companies, this.activeCompany);
			} else {
				return null;
			}
		},
    isWorkspaceOwner() {
      if (this.workspace && this.user) {
        return this.user.id === this.workspace.owner.id;
      }
      return false;
    },
    getTasksList() {
      this.tasksList.forEach((event) => {
        if (event.start_date && event.end_date) {
          if (event.start_date.split(" ")[0] === event.end_date.split(" ")[0]) {
            this.singleDayEvents.push(event);
          } else {
            this.multipleDayEvents.push(event);
          }
        }
      });
      return this.tasksList;
    },
    eventsMap() {
      let multipleDaysEvent = [];
      this.getTasksList.forEach((event) => {
        if (event.start_date && event.end_date) {
          if (event.start_date.split(" ")[0] !== event.end_date.split(" ")[0]) {
            this.getRange(event.start_date, event.end_date).forEach((date) => {
              let new_event = { ...event };
              new_event.split_date =
                date + " " + event.start_date.split(" ")[1];
              multipleDaysEvent.push(new_event);
            });
          }
        }
      });
      const map = {};
      multipleDaysEvent.forEach((event) =>
        (map[event.split_date ? event.split_date.split(" ")[0] : null] =
          map[event.split_date ? event.split_date.split(" ")[0] : null] ||
          []).push(event)
      );
      return map;
    },
    calendarView() {
      return this.workspace &&
        this.$store.getters.currentCalendarView(this.workspace.boards[0].id)
        ? this.$store.getters.currentCalendarView(this.workspace.boards[0].id)
            .view
        : "board";
    },
  },
  watch: {
    tasksList() {
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped lang="scss">
.toolbar {
  background-color: white;
  justify-content: space-between;
}
.toolbar .month-year {
  display: flex;
  font-family: "Avenir Next";
  font-size: 22px;
  cursor: pointer;
}
.toolbar .month-year .month {
  font-weight: bold;
  margin-right: 7px;
}
.toolbar .month-year .year {
  font-weight: 500;
}
.add-task-btn {
  color: #172b4d;
  background: white;
  border-radius: 6px;
  width: 85px;
  border: solid 1px #bfcfe0;
  font-family: "Avenir Next Demi";
}
.calendar-navigation .arrow:hover {
  .q-icon {
    color: #007aff !important;
  }
}
.calendar-navigation .q-icon {
  color: #87929f;
}
.calendar-navigation .q-btn {
  border-radius: 3px;
  border: solid 1px #bfcfe0;
  font-family: "Avenir Next";
  font-size: 12px;
  color: #596b7f;
}
.calendar {
  padding: 15px;
  font-family: "Avenir Next";
}
.calendar ::v-deep .q-calendar-weekly__day {
  width: 10px;
  min-width: 13.5% !important;
  border: solid 1.2px #dae5f0;
  margin: 3px;
  border-radius: 5px;
}
.calendar ::v-deep .q-calendar-weekly__week--wrapper {
  min-height: 16vh !important;
  border: none;
}
.calendar ::v-deep .q-calendar-weekly__head-weekday {
  font-size: 18px;
  font-weight: 500;
  justify-content: flex-end;
  border: none;
  color: #000000;
  padding-right: 10px;
}
.calendar ::v-deep .q-calendar-weekly__day:last-child {
  border-right: solid 1.2px #dae5f0 !important;
}
.calendar ::v-deep .disabled {
  background-color: #f8f9fb;
}
.calendar ::v-deep .q-calendar-weekly__day-label {
  left: unset;
  right: 5px;
  top: 5px;
  height: 25px;
  width: 25px;
}
.calendar
  ::v-deep
  .q-calendar-weekly__day
  .q-btn
  .q-btn__wrapper
  .q-btn__content {
  font-size: 15px;
  color: #000000;
}
.calendar ::v-deep .q-current-day .q-btn .q-btn__wrapper:before {
  background-color: #1f89fc !important;
  border: none;
}
.calendar ::v-deep .q-current-day .q-btn .q-btn__wrapper .q-btn__content {
  color: #ffffff !important;
}
.calendar ::v-deep .q-calendar-weekly__week-events {
  margin-top: 36px;
}
.calendar
  ::v-deep
  .q-calendar-weekly__day.q-active-date
  .q-btn__wrapper:before {
  background: #e0e0e0;
}
.calendar ::v-deep .q-calendar-daily__head-day {
  border: none !important;
  text-align: center;
}
.calendar ::v-deep .q-calendar-daily__head-weekday {
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  border: none;
  color: #000000;
}
.calendar ::v-deep .q-calendar-daily__day {
  border-radius: 5px;
  border: solid 1.2px #dae5f0;
  margin: 0 1px;
}
.calendar ::v-deep .q-calendar-daily__body {
  border: none;
}
.calendar ::v-deep .q-calendar-daily__intervals-body {
  border: none;
}
.calendar ::v-deep .q-calendar-daily__day:last-child,
.q-calendar-agenda__day:last-child {
  border-right: solid 1.2px #dae5f0 !important;
}
.calendar ::v-deep .q-calendar-daily__day-interval,
.q-calendar-agenda__day-interval {
  border-top: solid 1.2px #dae5f0 !important;
}
.calendar ::v-deep .q-calendar-daily__day-interval:first-child,
.q-calendar-agenda__day-interval:first-child {
  border-top: none !important;
}
.calendar ::v-deep .q-calendar-daily__intervals-head {
  border: none;
}
.badge-content {
  padding: 0;
}
.badge-content .ellipsis {
  font-family: "Avenir Next";
  font-size: 12px;
  font-weight: 500;
  color: #1d2b3b;
}
.badge-label {
  width: 8px;
  height: 25px;
  border-radius: 3.5px 0 0 3.5px;
  margin-right: 5px;
}
.thread-icons {
  margin-right: 3px;
}
.calendar-container {
  position: relative;
}
.my-event {
  width: 100%;
  position: absolute;
  font-size: 12px;
}
.full-width {
  left: 0;
  width: 100%;
}
.left-side {
  left: 0;
  width: 49.75%;
}
.right-side {
  left: 50.25%;
  width: 49.75%;
}
.calendar-view-tabs button {
  max-width: 122px;
  height: 26px;
  border: solid 1.5px #007aff;
  font-size: 12px;
  color: #007aff;
  font-family: "Avenir Next";
  font-weight: 500;
}
.calendar-view-tabs button.selected-tab {
  color: #ffffff;
  background-color: #007aff;
}
.calendar-view-tabs button:first-child {
  border-radius: 4px 0 0 4px;
}
.calendar-view-tabs button:last-child {
  border-radius: 0 4px 4px 0;
}
.calendar-view-tabs button .q-btn__wrapper {
  min-height: 0 !important;
}
.week-badge-content {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}
.week-badge-content .content {
  width: 90%;
  padding: 5px;
}
.week-badge-content .badge {
  width: 3px;
  border-radius: 3.5px;
  margin: 3px 0px;
}
.calendar
  ::v-deep
  .q-calendar-daily__head-day.q-active-date
  .q-btn__wrapper:before,
.q-calendar-agenda__head-day.q-active-date .q-btn__wrapper:before {
  background: #e0e0e0;
}
.calendar ::v-deep .q-calendar-daily__head-day .q-btn,
.q-calendar-agenda__head-day .q-btn {
  color: black !important;
  font-size: 15px;
}
.calendar ::v-deep .thread-icons .q-btn .q-btn__wrapper:before {
  background: none !important;
}
.calendar ::v-deep .thread-icons .q-btn .q-btn__wrapper .q-btn__content {
  color: black !important;
}
.week-event:hover {
  z-index: 9;
  border: 1px solid white;
}
</style>
