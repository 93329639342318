<template>
  <div class=" q-mt-sm">
    <div>
      <div>
        <div class="editor-container relative-position	">
          <textarea
            v-if="enableEditor"
            rows="1"
            placeholder="Type task name"
            v-model="task.title"
            ref="contentTextArea"
            class="vb-text-editor-boardinline block-ele"
            style="padding:8px"
            @keydown.enter.exact="
              addTask();
              $event.preventDefault();
            "
            v-auto-grow
            autofocus
            @blur="checkCloseAddingTask"
          ></textarea>
          <div
            @click.stop="addTask"
            :class="[
              'absolute create-task-btn text-center',
              task.title && task.title.trim().length > 1
                ? 'active-bg cursor-pointer'
                : 'disable-bg',
            ]"
          >
            <q-icon
              size="sm"
              class="text-white"
              style="margin-top:3px"
              :name="$icons.mdiCheckBold"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewTaskSingleline",
  props: {
    card: {
      type: Object,
    },
    sequencePosition: {
      default: "bottom",
      type: String,
    },
    closeAddingTask: Function,
    addTaskEventHandler: Function,
  },
  data() {
    return {
      // IMP: See form outter object
      task: {
        title: null,
        html: null,
        details: null,
        labels: [],
        card_id: this.card.id,
      },
      enableEditor: false,
    };
  },
  mounted() {
    this.enableEditor = true;

    this.$nextTick(() => {
      this.$refs.contentTextArea.focus();
    });
  },
  beforeDestroy() {
    this.enableEditor = false;
  },
  methods: {
    addTask() {
      if (this.task && this.task.title && this.task.title.trim()) {
        this.task.labels = [];
        this.createTask(this.task);
        this.resetTask();
        this.$emit("success", this.task);
      }
    },
    createTask(task) {
      const data = {
        ...task,
        card_id: this.card.id,
        sequencePosition: this.sequencePosition,
      };
      this.addTaskEventHandler(data);
      this.$nextTick(() => {
        this.$refs.contentTextArea.focus();
      });
    },
    resetTask() {
      this.task = {
        title: null,
        details: null,
        labels: [],
      };
      this.$refs.contentTextArea.style.height = "33px";
      this.$emit("resizeheight", 0);
    },
    checkCloseAddingTask() {
      if (!this.task.title) {
        this.closeAddingTask();
      }
    },
  },
};
</script>

<style lang="stylus">
.inlineTaskTextField {
  overflow: hidden;
  font-size: 14px;
  display: block;
}
</style>
