<template>
  <div style="height: 100%">
    <div v-if="isTimelineBannerVisible" class="empty-timeline">
      <div class="demo">
        <div class="tune-icon">
          <q-icon :name="$icons.matTune" size="15px" />
        </div>
        <div class="video">
          <div class="thumbnail" @click="videoPlayer">
            <q-icon :name="$icons.matPlayArrow" color="primary" size="xl" />
          </div>
        </div>
        <div class="text-contents">
          <span class="text-bold" style="margin-left:31px;"
            >Add dates to your tasks to see them in timeline view</span
          >
          <p>
            To see your tasks in the time-line view and plan your work-load,
            simply add dates and date-ranges to your tasks via the Board or List
            View.
          </p>
          <div
            style="width:50px; margin-left:31px; margin-top:10px"
            class="d-flex"
          >
            <q-btn
              dense
              no-caps
              class=" primary-button"
              color="#15d89a"
              size="md"
              label="Learn more"
              @click="$router.push({ name: 'LearnView' })"
            />
            <q-btn
              dense
              no-caps
              style="margin-left:10px; height: 32px; border: solid 1px #677484;"
              size="md"
              label="Dismiss"
              @click="removeBanner"
            />
          </div>
        </div>
        <div class="close-icon" @click="removeBanner">
          <q-icon :name="$icons.matClose" size="22px" />
        </div>
      </div>
      <video-dialog
        v-if="showVideoModel"
        v-model="showVideoModel"
        :resetVideoDialog="resetVideoDialog"
        :videoMediaData="videoMediaData"
        :displayPlayer="displayPlayer"
      ></video-dialog>
    </div>
    <svg ref="gantt" style="pointer-events: all"></svg>
  </div>
</template>

<script>
import Gantt from "frappe-gantt";
import moment from "moment";
import { UpdateTaskMutation } from "@/gql";
import mixpanel from "@/mixpanel";
import VideoDialog from "@/components/VuePlayer/VideoDialog.vue";
import { mapGetters } from "vuex";

export default {
  name: "GanttChartView",
  components: { VideoDialog },
  props: ["tasksList", "editTask"],
  data() {
    return {
      showDemo: true,
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
      gantt: null,
      showTaskDialog: true,
      scrollPosition: 0,
    };
  },
  mounted() {
    this.setupGanttChart();
  },
  methods: {
    setupGanttChart() {
      let that = this;
      if (this.getTasksList.length) {
        this.gantt = new Gantt(this.$refs.gantt, this.getTasksList, {
          bar_height: 45,
          on_date_change: function(task, start, end) {
            that.updateTask({
              id: task.id.split(" ")[1],
              start_date: moment(start).format("YYYY-MM-DD hh:mm"),
              end_date: moment(end).format("YYYY-MM-DD hh:mm"),
            });
            that.showTaskDialog = false;
          },
          on_click: (task) => {
            that.openTaskDialog(task.data);
          },
        });
        document.getElementsByClassName("popup-wrapper")[0].remove();
        let container = document.getElementsByClassName("gantt-container")[0];
        container.addEventListener("scroll", this.handleScroll);
        if (this.isTimelineBannerVisible) {
          this.gantt.$container.style.height = "calc(100vh - 238px)";
        }
      } else {
        let ganttdatabundle = [];
        //  let e = document.querySelectorAll('.bar-wrapper').forEach(el => el.remove());
        ///  Fill Data with sample if there is not enough data to make chart
        for (var count = ganttdatabundle.length; count <= 2; count++) {
          var sample = {
            start: new Date().toISOString().substr(0, 10),
            end: new Date().toISOString().substr(0, 10),
            name: " ",
            id: "Task 0", // to delete chart bar
            custom_class: "sample-task",
            progress: 100,
          };
          ganttdatabundle.push(sample);
        }
        this.gantt = new Gantt(this.$refs.gantt, ganttdatabundle, {
          bar_height: 45,
        });
        let elements = document.getElementsByClassName("bar-wrapper");
        while (elements.length > 0) elements[0].remove();
      }
    },
    async updateTask(args) {
      const variables = {
        ...args,
      };

      this.$api.updateEntity("task", variables.id, variables);

      await this.$api.mutate({
        mutation: UpdateTaskMutation,
        variables,
      });
      mixpanel.track("Task Update");
    },
    openTaskDialog(data) {
      this.showTaskDialog = true;
      if (this.showTaskDialog) {
        let obj = {
          task: data,
          isOpen: "taskComment",
        };
        this.editTask(obj);
      }
    },
    handleScroll(event) {
      this.scrollPosition = event.target.scrollLeft;
    },
    scrollToPosition() {
      let container = document.getElementsByClassName("gantt-container")[0];
      container.scrollTo(this.scrollPosition, 0);
    },
    removeBanner() {
      this.$store.dispatch("toggleTimelineBanner", false);
    },
    videoPlayer() {
      let media = {
        isVimeo: true,
        file: "https://player.vimeo.com/video/578599424",
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
  },
  computed: {
    ...mapGetters({
      isTimelineBannerVisible: "isTimelineBannerVisible",
    }),
    getTasksList() {
      let filteredTasks = this.tasksList.filter(
        (task) => task.completed == false && task.start_date
      );
      var tasks = [];
      filteredTasks.forEach((task) => {
        let color = "#8A75AE";
        if (task.labels.length) {
          color = task.labels[task.labels.length - 1].colorcode;
        }
        let css_class = color.split("#")[1];
        let new_obj = {
          id: "Task " + task.id,
          name: task.title,
          start: task.start_date,
          end: task.end_date,
          progress: 100,
          custom_class: "gantt_task_" + css_class,
          data: task,
        };
        tasks.push(new_obj);
      });
      filteredTasks.forEach((task) => {
        let color = "#8A75AE";
        if (task.labels.length) {
          color = task.labels[task.labels.length - 1].colorcode;
        }
        let css_class = color.split("#")[1];
        if (!document.getElementById("task_style_" + css_class)) {
          var style = document.createElement("style");
          style.setAttribute("id", "task_style_" + css_class);
          style.setAttribute("type", "text/css");
          style.innerHTML = `
            .gantt_task_${css_class} .bar-progress { fill: ${color}; } 
            .gantt_task_${css_class}:hover .bar-progress { fill: ${color} !important; }
            .gantt_task_${css_class}.active .bar-progress { fill: ${color} !important; }`;
          document.getElementsByTagName("head")[0].appendChild(style);
        }
      });
      if (!tasks.length) {
        this.$store.dispatch("toggleTimelineBanner", true);
      } else {
        this.$store.dispatch("toggleTimelineBanner", false);
      }
      return tasks;
    },
  },
  watch: {
    isTimelineBannerVisible(to) {
      if (to) {
        this.gantt.$container.style.height = "calc(100vh - 238px)";
      } else {
        this.gantt.$container.style.height = "100%";
      }
    },
    tasksList() {
      this.setupGanttChart();
    },
  },
};
</script>
<style lang="scss">
@import "~frappe-gantt/dist/frappe-gantt.css";
</style>
