var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"q-mt-sm"},[_c('div',[_c('div',[_c('div',{staticClass:"editor-container relative-position"},[(_vm.enableEditor)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.task.title),expression:"task.title"},{name:"auto-grow",rawName:"v-auto-grow"}],ref:"contentTextArea",staticClass:"vb-text-editor-boardinline block-ele",staticStyle:{"padding":"8px"},attrs:{"rows":"1","placeholder":"Type task name","autofocus":""},domProps:{"value":(_vm.task.title)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;_vm.addTask();
            $event.preventDefault();},"blur":_vm.checkCloseAddingTask,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.task, "title", $event.target.value)}}}):_vm._e(),_c('div',{class:[
            'absolute create-task-btn text-center',
            _vm.task.title && _vm.task.title.trim().length > 1
              ? 'active-bg cursor-pointer'
              : 'disable-bg',
          ],on:{"click":function($event){$event.stopPropagation();return _vm.addTask.apply(null, arguments)}}},[_c('q-icon',{staticClass:"text-white",staticStyle:{"margin-top":"3px"},attrs:{"size":"sm","name":_vm.$icons.mdiCheckBold}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }