<template>
	<q-btn
		dense
		flat
		padding="0"
		size="12px"
		round
		style="color:#B1BCC8"
		:icon="$icons.matLocalOffer"
		class="card-options"
	>
		<q-tooltip>
		Set Task Label
		</q-tooltip>
		<q-menu
			v-model="labelmenu"
			anchor="bottom right"
			self="bottom left"
			max-height="480px"
			content-class="overflow-auto task-label-menu"
			@before-hide="addTaskLabel = false"
		>
			<add-task-label-menu
				v-if="addTaskLabel"
				:labels="labels"
				:closeAddingLabel="() => onCloseAddingLabel()"
				:addTaskLabelEventHandler="(data) => addTaskLabelEventMyHandler(data)"
			>
			</add-task-label-menu>
			<template v-else>
				<div class="text-bold text-subtitle1 q-ml-md q-mt-xs">Task labels</div>
				<q-btn
					flat
					size="sm"
					round
					dense
					padding="0"
					color="grey-7"
					class="absolute-top-right q-mr-xs q-mt-xs"
					:icon="$icons.matClose"
					@click="labelmenu = false"
				/>
				<q-list>
					<template v-for="(label, index) in generalTaskLabel">
						<q-item
							@click="toggleTaskLabel(label)"
							:key="`edittask-labels-${index}`"
							clickable
						>
							<q-item-section avatar>
								<q-icon
									:name="$icons.matCheckCircle"
									size="21px"
									:style="{ color: label.colorcode }"
									v-if="selectedLabels.includes(label.id)"
								/>
								<q-avatar
									size="21px"
									:style="{ 'background-color': label.colorcode }"
									class="rounded-borders"
									v-else
								/>
							</q-item-section>
							<q-item-section>{{ label.name }}</q-item-section>
							<q-item-section avatar class="row flex-no-wrap">
								<div @click.stop="deleteTaskLabel(label.id)">
									<q-icon
										v-if="user.id === label.user_id"
										size="21px"
										:name="$icons.matDelete"
									>
									</q-icon>
								</div>
							</q-item-section>
						</q-item>
					</template>
					<template>
						<div
							v-if="myTaskLabel.length > 0"
							class="text-bold text-subtitle1 q-ml-md q-mt-xs"
						>
							Your labels
						</div>
					</template>
				</q-list>
				<q-list>
					<template v-for="(label, index) in myTaskLabel">
						<q-item
							@click="toggleTaskLabel(label)"
							:key="`edittask-labels-${index}`"
							clickable
						>
							<q-item-section avatar>
								<q-icon
									:name="$icons.matCheckCircle"
									size="21px"
									:style="{ color: label.colorcode }"
									v-if="selectedLabels.includes(label.id)"
								/>
								<q-avatar
									size="21px"
									:style="{ 'background-color': label.colorcode }"
									class="rounded-borders"
									v-else
								/>
							</q-item-section>
							<q-item-section>{{ label.name }}</q-item-section>
							<q-item-section avatar class="row flex-no-wrap">
								<div @click.stop="deleteTaskLabel(label.id)">
									<q-icon
										v-if="user.id === label.user_id"
										size="21px"
										:name="$icons.matDelete"
									>
									</q-icon>
								</div>
							</q-item-section>
						</q-item>
					</template>
					<template>
						<q-item @click="checkLableAccess" clickable>
							<q-item-section avatar>
								<q-icon :name="$icons.matAdd" size="24px"> </q-icon>
							</q-item-section>
							<q-item-section>{{ "Create label" }}</q-item-section>
						</q-item>
					</template>
				</q-list>
			</template>
		</q-menu>
	</q-btn>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash/get";
import {
	AttachTaskLabelMutation,
	DetachTaskLabelMutation,
	CreateTaskLabelMutation,
	DeleteTaskLabelMutation,
} from "@/gql";
import mixpanel from "@/mixpanel";
import AddTaskLabelMenu from "@/components/AddTaskLabelMenu";

export default {
	name: "TaskLabelMenu",
	props: ["task", "labels"],
	components: {
		AddTaskLabelMenu,
	},
	api: {
		user: {
			cacheKey: "UserQuery",
			defaultValue: null,
		},
	},
	data() {
		return {
			labelmenu: false,
			addTaskLabel: false,
			showTaskLabel: false,
		};
	},
	computed: {
		selectedLabels: {
			get() {
				return this.task.labels.map((l) => l.id);
			},
		},
		myTaskLabel() {
			return this.labels.filter((c) => c.user_id === this.user.id);
		},
		generalTaskLabel() {
			return this.labels.filter((c) => c.user_id === null);
		},
		isMenuOpen() {
			return this.labelmenu;
		},
		...mapGetters({
			activeCompany: "activeCompany",
			companies: "auth",
		}),
		currentCompany() {
			if (this.companies && this.activeCompany) {
				return get(this.companies, this.activeCompany);
			} else {
				return null;
			}
		},
	},
	methods: {
		checkLableAccess() {
			let eligible = this.$checkEligiblity("custom_labels");
			if (eligible) {
				this.addTaskLabel = true;
			} else {
				this.labelmenu = false;
			}
		},
		closePlanExpiredDialog() {
			this.showTaskLabel = false;
		},
		openAddTaskLabel() {
			if (
				this.currentCompany.status &&
				this.currentCompany.status.subscriptionOver
			) {
				this.showTaskLabel = true;
			} else {
				this.addTaskLabel = true;
			}
		},
		async toggleTaskLabel(label) {
			this.labelmenu = false;
			const variables = {
				task_id: this.task.id,
				label_id: label.id,
			};
			const newLabels = this.task.labels.filter((item) => item.id !== label.id);
			if (this.selectedLabels.includes(label.id)) {
				this.task.labels = newLabels;
				await this.$api.mutate({
					mutation: DetachTaskLabelMutation,
					variables,
					skipUpdates: true,
				});
				mixpanel.track("Task Label Remove");
			} else {
				if (this.task.labels.length < 4) {
					if (newLabels.length === this.task.labels.length) {
						newLabels.push(label);
					}
					this.task.labels = newLabels;
					await this.$api.mutate({
						mutation: AttachTaskLabelMutation,
						variables,
						skipUpdates: true,
					});
					mixpanel.track("Task Label Add");
				} else {
					this.$q.notify({
						position: "top-right",
						message: "You can set up to 4 labels",
						timeout: 2500,
						icon: this.$icons.matAnnouncement,
						classes: "max-label-notification",
						actions: [{ icon: this.$icons.matClose, color: "white" }],
					});
				}
			}
		},
		onCloseAddingLabel() {
			setTimeout(() => {
				var vm = this;
				vm.addTaskLabel = false;
			}, 200);
		},
		async addTaskLabelEventMyHandler(data) {
			const variables = {
				name: data.name,
				colorcode: data.colorcode,
			};
			let newTask = await this.$api.mutate({
				mutation: CreateTaskLabelMutation,
				variables,
			});

			const taskLabelQuery = this.$api.getQuery(`TaskLabelsQuery`);
			taskLabelQuery.data.taskLabels.push(newTask.data.createTaskLabel);
			mixpanel.track("Task Label Add");
		},
		async deleteTaskLabel(id) {
			const variables = {
				id: id,
			};
			await this.$api.mutate({
				mutation: DeleteTaskLabelMutation,
				variables,
			});

			const taskLabelQuery = this.$api.getQuery(`TaskLabelsQuery`);

			taskLabelQuery.data.taskLabels = taskLabelQuery.data.taskLabels.filter(
				(c) => c.id !== id
			);
			mixpanel.track("Task Label Add");
		},
	},
	watch: {
		// labelmenu(to) {
		//   this.$emit('labelMenuChanged', to)
		// }
	},
};
</script>
