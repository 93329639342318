<template>
  <q-card
    flat
    :class="[
      'column q-pt-sm',
      card.user_id !== user.id && card.is_lock && 'notDraggable',
    ]"
    style="flex-wrap:nowrap;background: transparent;padding-right:6px;padding-left:5px;"
    :style="[
      !filteredTodos && { width: '295px' },
      { 'max-width': '295px' },
      {
        'max-height': isBoardBannerVisible
          ? 'calc(100vh - 364px)'
          : 'calc(100vh - 184px)',
      },
    ]"
    :id="'board-card-task-' + index"
  >
    <q-popup-proxy
      :value="true"
      v-if="index === 0 && user.onboarding_step === 2"
      persistent
      target="#board-card-task-0"
      anchor="top right"
      :content-class="'popover-proxy'"
      content-style="top: 100px"
    >
      <q-card
        class="popover-walkthrough"
        style="background-color:white; height:320px"
      >
        <div class="icon">
          <q-btn :icon="$icons.mdiHelp" round size="30px" class="help-button">
          </q-btn>
        </div>
        <div class="content">
          <div class="header">
            <div class="titles">
              Tasks
            </div>
            <div class="navigation-buttons">
              <div
                style="margin-right: 15px; cursor: pointer; color:#007aff; text-decoration:underline"
                @click="updateOnboardingWalkthrough(4)"
              >
                Skip
              </div>
              <div class="not-selected-popop"></div>
              <div class="selected-popop"></div>
              <div class="not-selected-popop"></div>
              <q-btn
                class="arrow-item"
                :icon="$icons.matArrowForward"
                size="xs"
                round
                @click="updateOnboardingWalkthrough(3)"
              />
            </div>
          </div>
          <p style="color:#4E5463">
            Here, you can manage projects, create and assign tasks, track your
            time, and much more.
          </p>
          <div class="video" @click="videoPlayer">
            <q-icon
              class="play"
              :name="$icons.mdiPlay"
              color="primary"
              size="70px"
            />
          </div>
        </div>
        <video-dialog
          v-if="showVideoModel"
          v-model="showVideoModel"
          :resetVideoDialog="resetVideoDialog"
          :videoMediaData="videoMediaData"
          :displayPlayer="displayPlayer"
        />
      </q-card>
    </q-popup-proxy>
    <!-- Top -->
    <div class="q-mb-sm" style="background-color: rgba(242, 248, 255, 0.5);">
      <div
        class="row group-hover"
        :style="[
          { width: dragStartData ? '270px' : '100%' },
          { 'max-width': '270px', 'height': '45px' },
        ]"
      >
        <div style="width: 100%;padding-right: 10px;border-radius: 3px;align-items: center;display: flex;">
          <div class="row flex-1 cursor-pointer flex-no-wrap">
            <q-menu
              v-if="isVisible([1, 2, 3]) && ((card.user_id === user.id && card.is_lock) || !card.is_lock)"
              context-menu
              content-class="overflow-auto"
            >
              <q-list>
                <q-item clickable>
                  <q-item-section>
                    Assign column to
                  </q-item-section>
                  <q-item-section side>
                    <q-icon :name="$icons.matKeyboardArrowRight" />
                  </q-item-section>
                  <q-menu
                    anchor="top end"
                    self="top start"
                    content-class="overflow-auto assign-member-menu"
                  >
                    <div class="sticky-header">
                      <!-- <div
                      class="close-btn cursor-pointer"
                      @click="
                        search = null;
                      "
                      clickable
                      v-close-popup
                      >
                      <q-icon :name="$icons.matClose" class="close-icon" />
                      </div> -->
                      <div class="assign-to q-py-sm">
                        Assign to
                      </div>
                      <div class="search-member">
                        <q-input
                          autofocus
                          ref="search"
                          v-model="search"
                          outlined
                          placeholder="Search Member"
                          clearable
                          dense
                        >
                          <template v-slot:prepend>
                            <q-icon :name="$icons.matSearch" />
                          </template>
                        </q-input>
                      </div>
                    </div>
                    <q-list class="q-py-sm member-list">
                      <template v-for="user in workspaceAvailableUsers">
                        <q-item
                          class="q-px-none q-py-xs member-item items-center"
                          :key="user.id"
                          @click.stop="addMemberHandler(user)"
                          clickable
                          v-close-popup
                        >
                          <q-item-section class="member-avatar" avatar>
                            <q-avatar size="32px" v-if="user.pic">
                              <img :src="user.pic" :alt="user.first" />
                            </q-avatar>
                            <!-- <q-avatar
                              size="32px"
                              color="grey-4"
                              text-color="grey-10"
                              v-else
                            >
                              {{ user.first | firstChar
                              }}{{ user.last | firstChar }}
                            </q-avatar> -->
                            <avatar v-else :size="32" :customStyle="{'font-size':'14px', 'font-weight':'600'}" :username="user.first+' '+user.last"></avatar>
                          </q-item-section>
                          <q-item-section class="member-name" v-tooltip>
                            {{ user.first | capitalize }}
                            {{ user.last | capitalize }}
                            <q-tooltip v-if="isTruncated">
                              {{ user.first | capitalize }}
                              {{ user.last | capitalize }}
                            </q-tooltip>
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-list>
                  </q-menu>
                </q-item>
                <q-item
                  v-close-popup
                  clickable
                  @click="addNewColumnFromColumn(card)"
                >
                  <q-item-section>
                    Add new Column
                  </q-item-section>
                </q-item>
                <q-item
                  v-close-popup
                  clickable
                  v-if="isVisible([1, 2]) || isWorkspaceOwner"
                  @click="moveCard.flag = true"
                >
                  <q-item-section>Move column</q-item-section>
                </q-item>
                <q-item
                  v-close-popup
                  clickable
                  v-if="isVisible([1, 2]) || isWorkspaceOwner"
                  @click="duplicateCard.flag = true"
                >
                  <q-item-section>Duplicate column</q-item-section>
                </q-item>
                <q-separator />
                <q-item
                  v-close-popup
                  v-if="isVisible([1, 2]) || isWorkspaceOwner"
                  clickable
                  @click="clearActiveTaskConfirmBoxHandler"
                >
                  <q-item-section>
                    Clear Active Tasks
                  </q-item-section>
                </q-item>
                <q-item
                  v-close-popup
                  v-if="isVisible([1, 2]) || isWorkspaceOwner"
                  clickable
                  @click="clearCompletedTaskConfirmBoxHandler"
                >
                  <q-item-section>
                    Clear Completed Tasks
                  </q-item-section>
                </q-item>
                <q-item
                  v-close-popup
                  @click="changeBoardLock(true)"
                  clickable
                  v-if="card.user_id === user.id && !card.is_lock"
                >
                  <q-item-section>Lock column</q-item-section>
                </q-item>
                <q-item
                  v-close-popup
                  @click="changeBoardLock(false)"
                  clickable
                  v-if="card.user_id === user.id && card.is_lock"
                >
                  <q-item-section>Unlock column</q-item-section>
                </q-item>
                <q-item
                  v-close-popup
                  @click="changeBoardVisibility(true)"
                  clickable
                  v-if="card.user_id === user.id && !card.is_invisible"
                >
                  <q-item-section>Make column invisible</q-item-section>
                </q-item>
                <q-item
                  v-close-popup
                  @click="changeBoardVisibility(false)"
                  clickable
                  v-if="card.user_id === user.id && card.is_invisible"
                >
                  <q-item-section>Make column visible</q-item-section>
                </q-item>
                <q-item
                  v-close-popup
                  clickable
                  @click="deleteCardDeleteConfirmBoxHandler"
                  class="text-red"
                  v-if="isVisible([1, 2]) || isWorkspaceOwner"
                >
                  <q-item-section>
                    Delete Column
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
            <q-btn
              flat
              :icon="$icons.matAdd"
              size="sm"
              round
              style="color:#000000"
              class="self-baseline"
              @click.stop="addBoardTask('top')"
              v-if="canPostMessageFromWorkspace"
            >
              <q-tooltip>
                Add Task
              </q-tooltip>
            </q-btn>
            <div
              :class="[
                editorFocused && 'flex-1',
                'row items-center flex-no-wrap text-h6 q-ml-xs column-title',
              ]"
              style="width: 100%;"
            >
              <div
                v-if="!editorFocused"
                @click="editorFocused = true"
                class="board-cardtitle-ce"
              >
                {{ card.title }}
              </div>
              <q-input
                v-else
                dense
                padding="0"
                outlined
                :value="card.title"
                @change="updateCardTitle"
                @keydown.enter.exact="
                  (e) => {
                    editorFocused = false;
                    updateCardTitle(e);
                  }
                "
                class="board-cardtitle-ce"
                input-class="text-h6"
                bg-color="white"
                @blur="editorFocused = false"
                autogrow
                autofocus
              />
              <q-btn
                  flat
                  dense
                  size="8px"
                  round
                  padding="0"
                  style="color:#66AFFF;padding: 2px;background-color: #F2F8FF;"
                  :icon="$icons.matLock"
                  class="q-ml-xs"
                  v-if="card.is_lock"
                >
                  <q-tooltip>
                    Task locked by {{ card.owner.first | capitalize }} {{ card.owner.last | capitalize }}
                  </q-tooltip>
                </q-btn>
                <q-btn
                  flat
                  dense
                  size="8px"
                  round
                  padding="0"
                  style="color:#66AFFF;padding: 2px;background-color: #F2F8FF;"
                  :icon="$icons.matVisibilityOff"
                  v-if="card.is_invisible"
                  class="q-ml-xs"
                >
                  <q-tooltip>
                    Task invisible by {{ card.owner.first | capitalize }} {{ card.owner.last | capitalize }}
                  </q-tooltip>
                </q-btn>
              <div
                class="q-ml-sm active-tasks-count q-px-xs row items-center justify-center"
              >
                  
                {{ activeCount }}
                <q-tooltip>
                  {{ activeCount + " Active tasks" }}
                </q-tooltip>
              </div>
            </div>
          </div>
          <div
            class="tasks-status row flex-no-wrap items-center group-hover-item absolute"
            v-if="!editorFocused"
          >
            <div
              :class="[visibility === 'active' ? 'text-primary' : 'text-black']"
              @click="visibility = 'active'"
              class="q-px-xs cursor-pointer active-task"
            >
              {{ activeCount }} Active
            </div>
            <div
              v-if="completedCount"
              :class="[
                visibility === 'completed' ? 'text-primary' : 'text-black',
              ]"
              @click="visibility = 'completed'"
              class="q-px-xs cursor-pointer completed-task"
            >
              {{ completedCount }} Done
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Middle -->
    <div :ref="'columnScroll' + card.id" class="overflow-auto">
      <div style="margin:0 auto">
        <div
          v-sortable="{
            group: 'task',
            onStart: onDragStart,
            onEnd: onDragEnd,
            onAdd: onDragAddOrUpdate,
            onUpdate: onDragAddOrUpdate,
            emptyInsertThreshold: 30,
            filter: '.notDraggable',
          }"
        >
          <div
            v-for="(task, i) in filteredTodos"
            :key="task.id"
            :data-id="task.id"
            :class="[
              'row bg-white task-card',
              (task.owner.id !== user.id && task.is_lock) && 'notDraggable',
            ]"
          >
            <div
              class="show-on-drag medium-text an-16"
              style="padding: 12px 33px"
            >
              {{ task.title }}
            </div>
            <card-task
              :taskdata="task"
              :labels="labels"
              @delete="deleteTask"
              :board="board"
              :boardId="board.id"
              :workspace="workspace"
              place="board"
              :workspaceMembers="workspaceMembers"
              class="hide-on-drag flex-1 task-box-shadow"
              :editTaskDialogHandler="editTaskDialogHandler"
              :onUpdateTaskMembersHandler="onUpdateTaskMembersHandler"
              :cardIndex="i"
              :boardIndex="index"
            ></card-task>
          </div>
        </div>
      </div>
    </div>
    <!-- Bottom -->
    <div>
      <div class="text-center q-mt-sm">
        <div
          class="text-center  row justify-start"
          style="margin-bottom:14px"
          v-if="filteredTodos && visibility !== 'completed'"
        >
          <!-- <div
						class="add-task-btn-circle cursor-pointer row justify-center items-center"
						
					>
						<q-icon
							style="font-weight:500"
							size="15px"
							:name="$icons.fasPlus"
						/>
						<q-tooltip>
							Add Task
						</q-tooltip>
					</div> -->
          <!-- <div
            class="cursor-pointer row justify-center items-center"
            @click.stop="addBoardTask('bottom')"
            v-if="canPostMessageFromWorkspace"
          >
            <q-btn
              :icon="$icons.matAdd"
              text-color="#A4AEB8"
              label="New task"
              dense
              no-caps
              size="12px"
              class="add-task-btn q-px-xs"
            />
          </div> -->
        </div>
        <div
          @click.stop="addBoardTask('bottom')"
          class="add-task-area text-center row justify-center items-center cursor-pointer"
          style="height: 79px;"
          v-else-if="canPostMessageFromWorkspace && !filteredTodos"
        >
          <q-icon :name="$icons.matAdd" style="color:42526E" />
          <div class="q-ml-sm">Add task</div>
        </div>
      </div>
    </div>
    <!-- Dialog -->
    <confirm-dialog
      v-if="cardRemoveConfirm.flag"
      v-model="cardRemoveConfirm.flag"
      title="Delete Column?"
      question="Deleting this column will also delete all the tasks in it."
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="resetCardDeleteConfirmBox"
      :successMethod="deleteCard"
      :loading="false"
    />

    <!--Clear Complete Task Dialog -->
    <confirm-dialog
      v-if="clearCompletedTaskConfirm.flag"
      v-model="clearCompletedTaskConfirm.flag"
      title="Delete Completed Tasks?"
      question="Are you sure you want to delete all completed tasks?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="resetClearCompletedTaskConfirmBox"
      :successMethod="clearCompletedTaskFromColumn"
      :loading="false"
    />

    <!--Clear Active Task Dialog -->
    <confirm-dialog
      v-if="clearActiveTaskConfirm.flag"
      v-model="clearActiveTaskConfirm.flag"
      title="Delete Active Tasks?"
      question="Are you sure you want to delete all active tasks?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="resetClearActiveTaskConfirmBox"
      :successMethod="clearActiveTaskFromColumn"
      :loading="false"
    />
    <move-card-dialog
      v-if="moveCard.flag"
      v-model="moveCard.flag"
      :tasks="tasks"
      :card="card"
      :board="board"
      title="column"
      :closeDialog="closeMoveCardDialog"
    />
    <duplicate-card-dialog
      v-if="duplicateCard.flag"
      v-model="duplicateCard.flag"
      :card="card"
      :board="board"
      title="column"
      :closeDialog="closeDuplicateCardDialog"
    />
  </q-card>
</template>

<script>
import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";
import CardTask from "./CardTask";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { setTimeout } from "timers";
import RandomPosition from "random-position";
import Avatar from 'vue-avatar'

import {
  UpdateTaskMutation,
  UpdateOnboardingStepMutation,
  AddMemberToCardMutation,
} from "@/gql";
import mixpanel from "@/mixpanel";
import VideoDialog from "../../components/VuePlayer/VideoDialog.vue";
import { mapGetters } from "vuex";
import UserScope from "@/mixins/UserScope";
import MoveCardDialog from "@/components/Dialogs/MoveCardDialog.vue";
import DuplicateCardDialog from "@/components/Dialogs/DuplicateCardDialog.vue";

export default {
  name: "BoardCard",
  mixins: [UserScope],
  props: [
    "index",
    "card",
    "board",
    "labels",
    "workspaceMembers",
    "tasks",
    "workspace",
    "editTaskDialogHandler",
    "addTaskEventHandler",
    "onUpdateTaskMembersHandler",
    "addNewColumnFromColumn",
    "dueDateFilterMode",
  ],
  components: {
    CardTask,
    ConfirmDialog,
    VideoDialog,
    MoveCardDialog,
    DuplicateCardDialog,
    Avatar,
  },
  data() {
    return {
      addingTask: false,
      visibility: "active",
      cardRemoveConfirm: {
        flag: false,
        id: null,
        loader: false,
      },
      clearCompletedTaskConfirm: {
        flag: false,
        id: null,
        loader: false,
      },
      clearActiveTaskConfirm: {
        flag: false,
        id: null,
        loader: false,
      },
      taskDialog: false,
      editorFocused: false,
      dragStartData: false,
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
      height: "",
      moveCard: {
        flag: false,
      },
      duplicateCard: {
        flag: false,
      },
      menu: false,
      search: null,
      isTruncated: false,
    };
  },
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
  },
  methods: {
    changeBoardVisibility(flag) {
      this.$emit("updateCard", {
        id: this.card.id,
        is_invisible: flag,
        is_lock: this.card.is_lock,
      });
    },
    changeBoardLock(flag) {
      this.$emit("updateCard", {
        id: this.card.id,
        is_lock: flag,
        is_invisible: this.card.is_invisible,
      });
    },
    async addMemberHandler(member) {
      this.search = null;
      if (this.$refs.search) this.$refs.search.focus();

      const variables = {
        workspace_id: this.workspace.id,
        card_id: this.card.id,
        user_id: member.id,
      };
      try {
        const query = this.$api.getQuery(`BoardQuery:${this.board.id}`);

        if (query.data.board.tasks) {
          query.data.board.tasks.forEach((element, index) => {
            if (!element.completed && element.card_id == this.card.id) {
              let taskMember = element.members.filter(
                (tm) => tm.id == member.id
              );
              if (!taskMember.length) {
                query.data.board.tasks[index].members.push(member);
              }
            }
          });
        }

        const response = await this.$api.mutate({
          mutation: AddMemberToCardMutation,
          variables,
        });

        if (response) {
          this.$emit("setConvertedTask", this.task);
        }
      } catch (error) {
        if (error) {
          // Start from this
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: this.$t("message.coudlNotAssignTaskToMember"),
            icon: this.$icons.matReportProblem,
          });
        }
      }
    },
    closeMoveCardDialog() {
      this.moveCard.flag = false;
    },
    closeDuplicateCardDialog() {
      this.duplicateCard.flag = false;
    },
    onDragStart() {
      this.dragStartData = this.filteredTodos.length == 1 ? true : false;
      document.body.classList.add("dragging");
    },
    onDragEnd() {
      this.dragStartData = false;
      document.body.classList.remove("dragging");
    },
    onDragAddOrUpdate(event) {
      const { item, oldIndex, newIndex } = event;
      const task = this.$api.getEntity("task", item.dataset.id);
      const tmp = this.filteredTodos ? [...this.filteredTodos] : [];

      if (event.from === event.to) tmp.splice(oldIndex, 1);
      tmp.splice(newIndex, 0, task);
      task.card_id = this.card.id;
      task.sequence = RandomPosition.between(
        tmp[newIndex - 1] ? tmp[newIndex - 1].sequence : RandomPosition.first(),
        tmp[newIndex + 1] ? tmp[newIndex + 1].sequence : RandomPosition.last()
      );
      task.completed = false;

      const query = this.$api.getQuery(`BoardQuery:${this.board.id}`);
      query.data.board.tasks = query.data.board.tasks.map((t) => {
        if (t.id === task.id) {
          t.card_id = this.card.id;
        }
        return t;
      });

      this.$api.mutate({
        mutation: UpdateTaskMutation,
        skipUpdates: true,
        variables: {
          id: task.id,
          sequence: task.sequence,
          card_id: this.card.id,
        },
      });

      mixpanel.track("Task Move");
    },
    scrolltoBottom() {
      let element = this.$refs[`columnScroll${this.card.id}`];
      element.scrollTop = element.scrollHeight - element.clientHeight;
    },
    scrolltoTop() {
      let element = this.$refs[`columnScroll${this.card.id}`];
      element.scrollTop = 0;
    },
    addTaskEventMyHandler(data, position) {
      this.addingTask = true;
      this.addTaskEventHandler(data, () => {
        if (position === "bottom") {
          this.scrolltoBottom();
        } else if (position === "top") {
          this.scrolltoTop();
        }
      });
      setTimeout(() => {
        this.addingTask = false;
      }, 300);
    },
    updateCardTitle(event) {
      const title = event.target.value.trim();
      this.$emit("updateCard", {
        id: this.card.id,
        title: title,
        sequence: this.card.sequence,
        is_lock: this.card.is_lock,
        is_invisible: this.card.is_invisible,
      });
    },
    deleteTask(task) {
      this.$emit("deleteTask", task);
    },
    active(todos) {
      if (todos) {
        return todos.filter((todo) => !todo.completed);
      } else {
        return [];
      }
    },
    completed(todos) {
      if (todos) {
        let complated = todos.filter((todo) => todo.completed);
        if (!complated.length) {
          this.visibility = "active";
        }
        return complated;
      } else {
        return [];
      }
    },
    resetCardDeleteConfirmBox() {
      this.cardRemoveConfirm = {
        flag: false,
        id: null,
        loader: false,
      };
    },
    deleteCardDeleteConfirmBoxHandler() {
      this.cardRemoveConfirm = {
        flag: true,
        id: this.card.id,
      };
    },
    async deleteCard() {
      await this.$emit("deletCard", this.card);
      this.resetCardDeleteConfirmBox();
    },
    resetClearCompletedTaskConfirmBox() {
      this.clearCompletedTaskConfirm = {
        flag: false,
        id: null,
        loader: false,
      };
    },
    clearCompletedTaskConfirmBoxHandler() {
      this.clearCompletedTaskConfirm = {
        flag: true,
        id: this.card.id,
      };
    },
    clearCompletedTaskFromColumn() {
      this.$emit(
        "clearCompletedTaskFromColumn",
        this.clearCompletedTaskConfirm.id
      );
      this.resetClearCompletedTaskConfirmBox();
    },
    resetClearActiveTaskConfirmBox() {
      this.clearActiveTaskConfirm = {
        flag: false,
        id: null,
        loader: false,
      };
    },
    clearActiveTaskConfirmBoxHandler() {
      this.clearActiveTaskConfirm = {
        flag: true,
        id: this.card.id,
      };
    },
    clearActiveTaskFromColumn() {
      this.$emit("clearActiveTaskFromColumn", this.clearActiveTaskConfirm.id);
      this.resetClearActiveTaskConfirmBox();
    },
    addBoardTask(position) {
      let task = {
        card_id: this.card.id,
        details: "",
        html: null,
        labels: [],
        title: "",
        card: this.card,
        sequencePosition: position,
      };
      //this.addTaskEventMyHandler(task, position)
      this.$emit("createTask", task);
    },
    videoPlayer() {
      let media = {
        isVimeo: true,
        file: "https://player.vimeo.com/video/568790527",
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    async updateOnboardingWalkthrough(stepValue) {
      let variables = {
        onboarding_step: stepValue,
      };
      this.user.onboarding_step = stepValue;
      await this.$api.mutate({
        mutation: UpdateOnboardingStepMutation,
        variables,
      });
    },
  },
  computed: {
    taskMemberIds() {
      return this.task.members.map((m) => m.id);
    },
    ...mapGetters({
      isBoardBannerVisible: "isBoardBannerVisible",
    }),
    isWorkspaceOwner() {
      if (this.workspace && this.user) {
        return this.user.id === this.workspace.owner.id;
      }
      return false;
    },
    completedCount() {
      return this.completed(this.tasks).length;
    },
    activeCount() {
      return this.active(this.tasks).length;
    },
    // New
    filteredTodos: {
      get() {
        const taskGroup = groupBy(this.tasks, "completed");
        let key;

        switch (this.visibility) {
          case "active":
            key = false;
            break;
          default:
            key = true;
            break;
        }

        if (this.dueDateFilterMode && taskGroup[key]) {
          const enddateAvailabel = taskGroup[key].filter(
            (a) => a.end_date !== null
          );
          const enddateNotAvailabel = taskGroup[key].filter(
            (a) => a.end_date === null
          );
          return [
            ...orderBy(enddateAvailabel, ["end_date"], ["desc", "asc"]),
            ...enddateNotAvailabel,
          ];
        }
        return taskGroup[key];
      },
    },
    workspaceAvailableUsersFuse() {
      if (this.workspaceMembers) {
        const members = [this.user, ...this.workspaceMembers];

        const orderedMembers = orderBy(
          members,
          ["first", "last"],
          ["asc", "asc"]
        );

        return orderedMembers;
      }
      return [this.user];
    },
    workspaceAvailableUsers() {
      return this.search
        ? this.workspaceAvailableUsersFuse.filter(
            (u) =>
              u.first.toLowerCase().includes(this.search.toLowerCase()) ||
              u.last.toLowerCase().includes(this.search.toLowerCase())
          )
        : this.workspaceAvailableUsersFuse.hasOwnProperty("list")
        ? this.workspaceAvailableUsersFuse.list
        : this.workspaceAvailableUsersFuse;
    },
  },
};
</script>
<style lang="scss" scoped>
.active-tasks-count {
  background-color: #fff;
  border-radius: 3px;
  // color: #007aff;
  font-weight: 600;
  font-size: 15px;
  line-height: 13px;
  height: 25px;
  min-width: 30px;
  color: #232C3B;
  border: 1px solid #42526E
}
</style>
